import Alert from "./alert.js";
import Button from "./button.js";
import ButtonFilter from "./button-filter.js";
import Carousel from "./carousel.js";
import Collapse from "./collapse.js";
import ContactDrawer from "./contact-drawer.js";
import Dropdown from "./dropdown.js";
import FilmstripCarousel from "./filmstrip-carousel.js";
import Form from "./form.js";
import Footer from "./footer.js";
import InputMask from "./helpers/inputMask.js";
import LinkPicker from "./linkpicker.js";
import Masthead from "./masthead.js";
import Modal from "./modal.js";
import Overflow from "./overflow.js";
import Pagination from "./pagination.js";
import Popover from "./popover.js";
import Progress from "./progress.js";
import ScrollSpy from "./scrollspy.js";
import SkipNav from "./skip-nav.js";
import Slider from "./slider.js";
import Spinbox from "./spinbox.js";
import Tab from "./tab.js";
import Table from "./table.js";
import Tooltip from "./tooltip.js";
import { DOC, prefix, handleFirstTab, loadURLSVGs } from "./utilities.js";

// globals
var body = "body", // allow the library to be used in <head>
    supports = [];

var initializeDataAPI = function (constructor, collection) {
        for (var i = 0, l = collection["length"];i < l;i++) {
            new constructor(collection[i]);
        }
    },
    initCallback = function (lookUp) {
        lookUp = lookUp || DOC;
        for (var i = 0, l = supports["length"];i < l;i++) {
            initializeDataAPI(
                supports[i][0],
                lookUp["querySelectorAll"](supports[i][1])
            );
        }
};

// ALERT DATA API
supports["push"]([Alert, "[data-dismiss=\"" + prefix + "alert\"]"]);

// BUTTON DATA API
supports["push"]([Button, "[data-toggle=\"" + prefix + "button\"]"]);

// BUTTON FILTER DATA API
supports["push"]([ButtonFilter, "[data-toggle=\"" + prefix + "button-filter\"]"]);

// CAROUSEL DATA API
supports["push"]([Carousel, "[data-ride=\"" + prefix + "carousel\"]"]);

// COLLAPSE DATA API
supports["push"]([Collapse, "[data-toggle=\"" + prefix + "collapse\"]"]);

// CONTACT DRAWER DATA API
supports["push"]([ContactDrawer, "[data-toggle=\"" + prefix + "contact-drawer\"]"]);

// DROPDOWN DATA API
supports["push"]([Dropdown, "[data-toggle=\"" + prefix + "dropdown\"]"]);

// FILMSTRIP CAROUSEL DATA API
supports["push"]([FilmstripCarousel, "[data-toggle=\"" + prefix + "filmstrip-carousel\"]"]);

// FOOTER DATA API
supports["push"]([Footer, "[data-toggle=\"" + prefix + "footer\"]"]);

// FORM CAROUSEL DATA API
supports["push"]([Form, "[data-toggle=\"" + prefix + "form-validation\"]"]);

// INPUT MASK DATA API
supports["push"]([InputMask, "[data-toggle=\"" + prefix + "input-mask\"]"]);

// LINKPICKER DATA API
supports["push"]([LinkPicker, "[data-toggle=\"" + prefix + "linkpicker\"]"]);

// MASTHEAD DATA API
supports["push"]([Masthead, "[data-toggle=\"" + prefix + "msthd\"]"]);

// MODAL DATA API
supports["push"]([Modal, "[data-toggle=\"" + prefix + "modal\"]"]);

// Overflow DATA API
supports["push"]([Overflow, "[data-toggle=\"" + prefix + "overflow\"]"]);

// PAGINATION DATA API
supports["push"]([Pagination, "[data-toggle=\"" + prefix + "pagination\"]"]);

// POPOVER DATA API
supports["push"]([Popover, "[data-toggle=\"" + prefix + "popover\"]"]);

// PROGRESS DATA API
supports["push"]([Progress, "[data-toggle=\"" + prefix + "progress\"]"]);

// SCROLLSPY DATA API
supports["push"]([ScrollSpy, "[data-spy=\"" + prefix +"scroll\"]"]);

// SKIPNAV DATA API
supports["push"]([SkipNav, "[data-spy=\"" + prefix +"skip-nav\"]"]);

// Slider DATA API
supports["push"]([Slider, "[data-toggle=\"" + prefix + "slider\"]"]);

// Spinbox DATA API
supports["push"]([Spinbox, "[data-toggle=\"" + prefix + "spinbox\"]"]);

// TABLE DATA API
supports["push"]([Table, "[data-table=\"" + prefix + "table\"]"]);

// TAB DATA API
supports["push"]([Tab, "[data-toggle=\"" + prefix + "tab\"]"]);

// TOOLTIP DATA API
supports["push"]([Tooltip, "[data-toggle=\"" + prefix + "tooltip\"]"]);

// bulk initialize all components
DOC[body] ? initCallback() : DOC.addEventListener("DOMContentLoaded", function () {
    initCallback();
});

window.addEventListener("keydown", handleFirstTab);

export {
    Alert, Button, ButtonFilter, Carousel, Collapse, ContactDrawer, Dropdown, FilmstripCarousel, Footer, Form, InputMask, Masthead, Modal, Overflow, Pagination, Popover, Progress, ScrollSpy, SkipNav, Slider, Spinbox, Tab, Table, Tooltip, LinkPicker,
    handleFirstTab, loadURLSVGs
};

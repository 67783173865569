import {getElementsByClassName,globalObject,supportTransitions,HTML,DOC,getTransitionDurationFromElement,prefix,uicoreCustomEvent, isIE, ieMosaicPolyfill} from "./utilities.js";

export default function Carousel(element, options) {
    // initialization element
    element = element instanceof HTMLElement ? element : (function() {
        return false;
    })();

    // set options
    options = options || {};
    options.mosaic = options.mosaic ? options.mosaic : false;

    // DATA API
    var intervalAttribute = element["getAttribute"]("data-interval"),
        stringCarousel = "Carousel",
        intervalOption = options["interval"],
        intervalData = intervalAttribute === "false" ? 0 : parseInt(intervalAttribute),
        pauseData = element["getAttribute"]("data-pause") === "hover" || false,
        keyboardData = element["getAttribute"]("data-keyboard") === "true" || false,
        visibilityState = "visible",
        smallMediaSize = window.matchMedia("(max-width: 767.98px)"),
        // strings
        paused = "paused",
        dataSlideTo = "data-slide-to",
        mouseHover = "onmouseleave" in DOC ? ["mouseenter", "mouseleave"] : ["mouseover", "mouseout"];
    this["keyboard"] = options["keyboard"] === true || keyboardData;
    this["pause"] =
        options["pause"] === "hover" || pauseData ? "hover" : false; // false / hover

    this["interval"] =
        typeof intervalOption === "number"
            ? intervalOption
            : intervalOption === false ||
                intervalData === 0 ||
                intervalData === false
                ? 0
                : isNaN(intervalData)
                    ? 5000 // bootstrap carousel default interval
                    : intervalData;

    // bind, event targets
    var self = this,
        stopAutoRotate = false,
        index = (element.index = 0),
        timer = (element.timer = 0),
        isSliding = false, // isSliding prevents click event handlers when animation is running
        slides = getElementsByClassName(element, prefix+"carousel-item"),
        total = slides["length"],
        slideDirection = (this["direction"] = "left"),
        // Arrow capability
        leftArrow = getElementsByClassName(
            element,
            prefix+"carousel-control-prev"
        )[0],
        rightArrow = getElementsByClassName(
            element,
            prefix+"carousel-control-next"
        )[0],
        // SVG indicators
        svgIndicator = element.querySelector("." + prefix+"carousel-indicators"),
        svgIndicators = (svgIndicator && svgIndicator["getElementsByTagName"]("LI")) || [],
        // Button indicatos
        btnIndicator = element.querySelector("." + prefix+"carousel-indicators-btn"),
        btnIndicators = (btnIndicator && btnIndicator["getElementsByTagName"]("LI")) || [],
        indicator = svgIndicator ? svgIndicator : btnIndicator,
        indicators = svgIndicators["length"] > 0 ? svgIndicators : btnIndicators;

    // invalidate when not enough items
    if (total < 2) {
        return;
    }

    // handlers
    var pauseHandler = function() {
            if (self["interval"] !== false && !element.classList["contains"](paused)) {
                element.classList.add(paused);
                !isSliding && clearTimer(timer);
            }
        },
        resumeHandler = function() {
            if (self["interval"] !== false && element.classList["contains"](paused)) {
                element.classList.remove(paused);
                !isSliding && clearTimer(timer);
                !isSliding && self.cycle();
            }
        },
        indicatorHandler = function(e) {
            e["preventDefault"]();
            if (isSliding) return;

            var eventTarget = e["target"]; // event target | the current active item

            if (eventTarget && !eventTarget.classList["contains"](prefix+"active") && eventTarget["getAttribute"](dataSlideTo)) {
                index = parseInt(eventTarget["getAttribute"](dataSlideTo), 10);
                stopAutoRotate = true;
            } else {
                return false;
            }

            self.slideTo(index); //Do the slide
        },
        controlsHandler = function(e) {
            e["preventDefault"]();
            if (isSliding) return;

            var eventTarget = e.currentTarget || e.srcElement;

            if (eventTarget === rightArrow) {
                index++;
            } else if (eventTarget === leftArrow) {
                index--;
            }

            self.slideTo(index); //Do the slide
        },
        keyHandler = function(e) {
            if (isSliding) return;
            switch (e.which) {
            case 39: index++; break; 
            case 37: index--; break;
            default: return;
            }
            self.slideTo(index); //Do the slide
        },
        // private methods
        isElementInScrollRange = function() {
            var rect = element["getBoundingClientRect"](),
                viewportHeight = globalObject[innerHeight] || HTML["clientHeight"];
            return rect["top"] <= viewportHeight && rect["bottom"] >= 0; // bottom && top
        },
        setActivePage = function(pageIndex) {
            //indicators
            for (var i = 0, icl = indicators["length"]; i < icl; i++) {
                indicators[i].classList.remove(prefix+"active");
            }
            if (indicators[pageIndex]) indicators[pageIndex].classList.add(prefix+"active");
        },
        clearTimer = function(timer) {
            clearInterval(timer);
            timer = null;
        },
        toggleMosaic = function(e) {
            pauseHandler();
            if (e.matches) {
                stopAutoRotate = false;
                element.addEventListener(mouseHover[0], pauseHandler, false);
                element.addEventListener(mouseHover[1], resumeHandler, false);
                element.addEventListener("touchstart", pauseHandler, false);
                element.addEventListener("touchend", resumeHandler, false);
                resumeHandler();
            } else {
                stopAutoRotate = true;
                element.removeEventListener(mouseHover[0], pauseHandler, false);
                element.removeEventListener(mouseHover[1], resumeHandler, false);
                element.removeEventListener("touchstart", pauseHandler, false);
                element.removeEventListener("touchend", resumeHandler, false);
            }
        };

    // public methods
    this.cycle = function() {
        if(!stopAutoRotate) {
            if (timer) {
                clearTimer(timer);
            }
            timer = setInterval(function() {
                isElementInScrollRange() && (index++, self.slideTo(index));
            }, this["interval"]);
        } else {
            if (timer) {
                clearTimer(timer);
            }
        }
    };
    this.slideTo = function(next) {
        if (isSliding) return; // when controled via methods, make sure to check again

        var activeItem = this.getActiveIndex(); // the current active
        // orientation;
        // chaeck to see if we need to reset carousel
        
        // first return if we're on the same item #227
        if (activeItem === next) {
            return;
        // or determine slideDirection
        // } else if (activeItem < next || (activeItem === 0 && next === total - 1)) {
        //     slideDirection = self[direction] = "left"; // next
        // } else if (activeItem > next || (activeItem === total - 1 && next === 0)) {
        //     slideDirection = self[direction] = "right"; // prev
        }

        // find the right next index
        if (next < 0) {
            next = total - 1;
        } else if (next >= total) {
            next = 0;
        }

        // update index
        index = next;

        slideDirection === "left" ? "next" : "prev"; //determine type
        uicoreCustomEvent("Carousel", "SlideEvent", element, { "direction": slideDirection, "slide-to" : next });

        isSliding = true;
        clearTimer(timer);
        setActivePage(next);

        if (supportTransitions && element.classList["contains"]("slide")) {
            slides[next].classList.add(prefix+"carousel-item-next");
            slides[next]["offsetWidth"];
            slides[next].classList.add(prefix+"carousel-item-left");
            slides[activeItem].classList.add(prefix+"carousel-item-left");

            var timeout = getTransitionDurationFromElement(slides[next]);

            isSliding &&
            setTimeout(function() {
                isSliding = false;

                slides[next].classList.add(prefix+"active");
                slides[activeItem].classList.remove(prefix+"active");

                slides[next].classList.remove(prefix+"carousel-item-next");
                slides[next].classList.remove(prefix+"carousel-item-left");
                slides[activeItem].classList.remove(prefix+"carousel-item-left");
                uicoreCustomEvent("Carousel", "SlideEvent", element, { "direction": slideDirection, "slide-to" : next });
                
                if (!(visibilityState === "hidden") && self["interval"] && !element.classList["contains"](paused)) {
                    self.cycle();
                } else {
                    if(visibilityState === "hidden") {
                        pauseHandler();
                    }
                }
            }, timeout);

        } else {
            slides[next].classList.add(prefix+"active");
            slides[next]["offsetWidth"];
            slides[activeItem].classList.remove(prefix+"active");
            setTimeout(function() {
                isSliding = false;
                if (self["interval"] && !element.classList["contains"](paused)) {
                    self.cycle();
                }
                uicoreCustomEvent("Carousel", "SlideEvent", element, { "direction": slideDirection, "slide-to" : next });
            }, 100);
        }
    };
    this.getActiveIndex = function() {
        return (
            slides["indexOf"](
                getElementsByClassName(element, prefix+"carousel-item" + " " + prefix + "active")[0]
            ) || 0
        );
    };

    // init
    if (!(stringCarousel in element)) {
      
        DOC.addEventListener("visibilitychange", function(){
            if (visibilityState === "hidden" && document.visibilityState === "visible") {
                resumeHandler();
            }
            visibilityState = document.visibilityState;
        });

        if (self["pause"] && self["interval"]) {
            if (!options.mosaic || (options.mosaic && smallMediaSize.matches)) {
                element.addEventListener(mouseHover[0], pauseHandler, false);
                element.addEventListener(mouseHover[1], resumeHandler, false);
                element.addEventListener("touchstart", pauseHandler, false);
                element.addEventListener("touchend", resumeHandler, false);
            } 
            if (options.mosaic) {
                if (isIE) {
                    ieMosaicPolyfill(slides);
                }
                if (!smallMediaSize.matches) {
                    stopAutoRotate = true;
                }
                smallMediaSize.addListener(toggleMosaic);
            }
        }

        rightArrow && rightArrow.addEventListener("click", controlsHandler, false);
        leftArrow && leftArrow.addEventListener("click", controlsHandler, false);

        indicator && indicator.addEventListener("click", indicatorHandler, false);

        if (!svgIndicator) {

            for (var i = 0; i < indicators["length"]; i++) {
                var childIndicators = indicators[i].children;
                for (var x = 0; x < childIndicators["length"]; x++) {
                    childIndicators[x].addEventListener("click", indicatorHandler);
                }
                indicators[i].addEventListener("click", indicatorHandler);
            }
        }

        self["keyboard"] === true && globalObject.addEventListener("keydown", keyHandler, false);
    }
    if (self.getActiveIndex() < 0) {
        slides[length] && slides[0].classList.add(prefix+"active");
        indicators[length] && setActivePage(0);
    }

    if (self["interval"]) {
        self.cycle();
    }
    element[stringCarousel] = self;
}
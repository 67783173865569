import { uicoreCustomEvent, prefix } from "./utilities";

export default function ButtonFilter(element) {
    // initialization element
    element = element instanceof HTMLElement ? element : (function() {
        return false;
    })();

    var stringButtonFilter = "ButtonFilter",
        lastActiveItem = null,
        inactiveFilterItem,
        activeFilterItem,
        // Event Handlers
        focusInEventHandler = function(e) {
            var filterItem = e["currentTarget"];
            // Clicking or Tabbing on the active filter item
            if(filterItem == lastActiveItem){
                return;
            }

            activeFilterItem = filterItem;
            inactiveFilterItem = lastActiveItem;

            inactiveFilterItem.label.classList.remove(prefix + "active");
            inactiveFilterItem.input.checked = false;

            activeFilterItem.label.classList.add(prefix + "active");
            activeFilterItem.input.checked = true;
            lastActiveItem = activeFilterItem;

            e.preventDefault();
            // Emit filter event typeArg: string, canBubbleArg: boolean, cancelableArg: boolean, detailArg: T
            uicoreCustomEvent("ButtonFilter", "Click", inactiveFilterItem.label, {"target": inactiveFilterItem.label.dataset["filterValue"]});
        };

    if (!(stringButtonFilter in element)) {
        var filterItems = element.querySelectorAll("."+ prefix + "filter-item");
        lastActiveItem = element.querySelector("." + prefix + "active").parentNode;
        for (var f = 0; f < filterItems.length; f++) {
            filterItems[f].addEventListener("focusin", focusInEventHandler, false);
            filterItems[f].label = filterItems[f].querySelector("."+ prefix + "btn-filter");
            filterItems[f].input = filterItems[f].querySelector("."+ prefix + "btn-input");
        }
    }

    element[stringButtonFilter] = self;
}

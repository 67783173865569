import { prefix, uicoreCustomEvent } from "./utilities.js";
import Glide, { Controls, Breakpoints } from "@glidejs/glide/dist/glide.modular.esm";

export default function FilmstripCarousel(element, options) {
    element = element instanceof HTMLElement ? element : (function() {
        return false;
    })();

    // set options
    options = options || {};
    options.perView = options.perView || 9;
    options.bound = options.bound === false ? false : true;
    options.breakpoints = options.breakpoints || {
        1200: {
            perView: 8
        },
        992: {
            perView: 6
        },
        768: {
            perView: 4
        },
        576: {
            perView: 2
        }
    };
    options.peek = options.peek || {before: 50, after: 50};

    var stringFilmstrip = "Filmstrip",
        gl = null,
        isDesktop = true,
        chevronLeft = element.querySelector("."+prefix+"chevron-left"),
        chevronRight = element.querySelector("."+prefix+"chevron-right"),
        carouselElement = element.querySelector("ul"),
        itemsView = element.querySelector("."+prefix+"items-view"),
        mediaSize = window.matchMedia("(max-width: 1179.98px)"),
        // init
        init = function() {
            gl = new Glide(element, options)
                .on("mount.after", function () {
                    chevronRight.style.cssText = "display: block";
                })
                .mount({Controls, Breakpoints});
            
            gl.numberOfChildren = element.querySelector("." + prefix + "glide__slides").childElementCount;
            
            if (gl.numberOfChildren <= gl.settings.perView + 1) {
                gl.destroy();
                chevronLeft.style.cssText = "display: none";
                chevronRight.style.cssText = "display: none";
                if(itemsView){
                    itemsView.innerHTML = "<span>Viewing " + "1-" + gl.numberOfChildren + " of "+ gl.numberOfChildren +" items </span>";
                }
            }
            else {
                gl.on("move.after", function () {
                    if (gl.index === 0) {
                        chevronLeft.style.cssText = "display: none";
                    }
                    else if (gl.index === gl.numberOfChildren - gl.settings.perView) {
                        chevronRight.style.cssText = "display: none";
                    }
                    if (gl.index === 1) {
                        chevronLeft.style.cssText = "display: block";
                    }
                    if (gl.index === gl.numberOfChildren - gl.settings.perView - 1) {
                        chevronRight.style.cssText = "display: block";
                    }
                    if(itemsView){
                        itemsView.innerHTML = "<span>Viewing " + (gl.index + 1) + "-" + (gl.index + gl.settings.perView) + " of "+ gl.numberOfChildren +" items </span>";
                    }
                });
            }
        },
        // hanlders
        clickEventHandler = function(e) {
            e.preventDefault();
            var target = e["target"];
            //if click in between the buttons, don't do anything
            if (target &&  target.tagName === "UL") {
                return;
            } // if enter on input with keyboard, change target to the input
            else if (target && target.parentElement.tagName === "BUTTON") {
                target = target.parentElement;
            }
            if (!(target.classList["contains"](prefix + "active"))) {
                var allSelections = carouselElement.children;
                for (var i = 0; i < allSelections.length; i++) {
                    if (allSelections[i].classList["contains"](prefix + "active")){
                        allSelections[i].classList.remove(prefix + "active");
                        allSelections[i].setAttribute("aria-pressed","false");
                        break;
                    }
                }
                target.classList.add(prefix + "active");
                target.setAttribute("aria-pressed","true");
                // Emit filter event
                uicoreCustomEvent("FilmstripCarousel", "ItemClick", element, {"target": target.dataset["filterValue"]});
            }
        };

    window.addEventListener("resize", function(){
        if (mediaSize.matches) {
            if (isDesktop) {
                setTimeout(function(){
                    init();
                }, 300);
            }
            // isDesktop is a flag for not mobile
            isDesktop = false;
        }
        else {
            if (!isDesktop) {
                setTimeout(function(){
                    if (gl.numberOfChildren <= gl.settings.perView + 1) {
                        gl.destroy();
                        chevronLeft.style.cssText = "display: none";
                        chevronRight.style.cssText = "display: none";
                        if(itemsView){
                            itemsView.innerHTML = "<span>Viewing " + "1-" + gl.numberOfChildren + " of "+ gl.numberOfChildren +" items </span>";
                        }
                    }
                }, 1000);
            }
            isDesktop = true;
        }
    });

    // prevent adding event handlers twice
    if (!(stringFilmstrip in element)) {

        init();

        carouselElement.addEventListener("click", clickEventHandler, false);
    }

    element[stringFilmstrip] = self;

}

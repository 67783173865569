import { DOC, each, prefix, debounce, createElement, classAdd, isArray, isIE, uicoreCustomEvent, emulateTransitionEnd } from "./utilities";

export default function Overflow(element, options) {

    element = element instanceof HTMLElement ? element : (function () {
        return false;
    })();

    // set options
    options = options || {};
    options.type = options.type ? options.type : "single";
    options.responsive = options.responsive ?  options.responsive : false;
    options.lazyload = options.lazyload ? options.lazyload : false;

    var self = this,
        stringOverflow = "Overflow",
        overflowElement, // The UL container div
        overflowContainer, // The UL element
        overflowChildren,
        overflowChildRight,
        overflowChildLeft,
        holdLeft,
        leftCtrl,
        rightCtrl,
        overflowMediaSize = window.matchMedia("(max-width: 767.98px)"),
        // handlers
        handleResize = debounce(function() {
            each( DOC.querySelectorAll("." + prefix + "container-overflow"), function(overflow) {
                if (element === overflow) {
                    setLeftAndRightIndex();
                    getChildrenWidth();
                    addHeightStyle([leftCtrl,rightCtrl]);
                    if ( ( (Math.abs(overflowContainer.offsetLeft)) + overflowContainer.offsetWidth ) > overflowChildren.width ) {
                        rightCtrl.removeAttribute("active");
                    } else {
                        rightCtrl.setAttribute("active", "");
                    }
                }
            });
        }, 10),
        handleClickEvent = function(e) {
            var isRight = e.target.classList.contains(prefix+"overflow-control-right") ? true : false;
            var rightEdgeTab, leftEdgeTab, atRightEnd, atLeftEnd, desired, spand;
            //setLeftAndRightIndex();
            if (options.type == "single") {
                if (isRight) {
                    rightEdgeTab = overflowChildren.children[overflowChildRight].offsetLeft + overflowChildren.children[overflowChildRight].offsetWidth;
                    var leftEdgeCtrl = (overflowContainer.offsetLeft + overflowContainer.offsetWidth);
                    atRightEnd = false;
                    if (overflowChildRight < (overflowChildren.children.length - 1) ) {
                        leftEdgeCtrl -= rightCtrl.offsetWidth;
                    } else {
                        atRightEnd = true;
                    }
                    var currentOffset = (rightEdgeTab + overflowContainer.offsetLeft)-leftEdgeCtrl;
                    // fix control visibility
                    overflowContainer.setAttribute("style","position: relative; flex-wrap: nowrap; white-space: nowrap; left: "+ (-1*currentOffset) +"px;");
                    if (!leftCtrl.hasAttribute("active") )  {
                        leftCtrl.setAttribute("active","");
                    }
                    if (atRightEnd) {
                        rightCtrl.removeAttribute("active");
                    }
                } else {
                    leftEdgeTab = overflowChildren.children[overflowChildLeft].offsetLeft;
                    atLeftEnd = false;
                    if (overflowChildLeft > 0) {
                        leftEdgeTab -= leftCtrl.offsetWidth;
                    } else {
                        atLeftEnd = true;
                    }
                    overflowContainer.setAttribute("style","position: relative; flex-wrap: nowrap; white-space: nowrap; left: "+ (-1*leftEdgeTab) +"px;");
                    // fix control visibility
                    if ( !rightCtrl.hasAttribute("active") 
                    && (overflowChildren.width > ((Math.abs(overflowContainer.offsetLeft)) + overflowContainer.offsetWidth ))) {
                        rightCtrl.setAttribute("active","");
                    }
                    if (atLeftEnd) {
                        leftCtrl.removeAttribute("active");
                    }
                }
                emulateTransitionEnd(overflowContainer, setLeftAndRightIndex);
            } else {
                if (isRight) {
                    var nextRight = null;
                    desired = overflowContainer.offsetWidth;
                    desired -= rightCtrl ? rightCtrl.offsetWidth : 0;
                    desired -= leftCtrl ? leftCtrl.offsetWidth : 0;
                    spand = 0;
                    for (var x = overflowChildRight; x < overflowChildren.children.length; x++) {
                        spand += overflowChildren.children[x].offsetWidth;
                        if (spand > desired) {
                            nextRight = x-1;
                            break;
                        }
                    }
                    atRightEnd = false;
                    if (!nextRight) {
                        nextRight = overflowChildren.children.length-1;
                        atRightEnd = true;
                    }
                    rightEdgeTab = overflowChildren.children[nextRight].offsetLeft + overflowChildren.children[nextRight].offsetWidth;
                    rightEdgeTab -= overflowContainer.offsetWidth;
                    if (!atRightEnd) {
                        rightEdgeTab += rightCtrl ? rightCtrl.offsetWidth : 0;
                    }

                    overflowContainer.setAttribute("style","position: relative; flex-wrap: nowrap; white-space: nowrap; left: "+ (-1*rightEdgeTab) +"px;");
                    // fix control visibility
                    if (!leftCtrl.hasAttribute("active") )  {
                        leftCtrl.setAttribute("active","");
                    }
                    if (atRightEnd) {
                        rightCtrl.removeAttribute("active");
                    }
                } else {
                    var nextLeft = null;
                    desired = overflowContainer.offsetWidth;
                    desired -= rightCtrl ? rightCtrl.offsetWidth : 0;
                    desired -= leftCtrl ? leftCtrl.offsetWidth : 0;
                    spand = 0;
                    for(var y=overflowChildLeft; y>0; y--) {
                        spand += overflowChildren.children[y].offsetWidth;
                        if (spand > desired) {
                            nextLeft = y+1;
                            break;
                        }
                    }
                    atLeftEnd = false;
                    if (!nextLeft) {
                        nextLeft = 0;
                        atLeftEnd = true;
                    }
                    leftEdgeTab = overflowChildren.children[nextLeft].offsetLeft;
                    if (!atLeftEnd) {
                        leftEdgeTab -= leftCtrl ? leftCtrl.offsetWidth : 0;
                    }
                    overflowContainer.setAttribute("style","position: relative; flex-wrap: nowrap; white-space: nowrap; left: "+ (-1*leftEdgeTab) +"px;");
                    // fix control visibility
                    if (!rightCtrl.hasAttribute("active") ) {
                        rightCtrl.setAttribute("active","");
                    }
                    if (atLeftEnd) {
                        leftCtrl.removeAttribute("active");
                    }
                }
                emulateTransitionEnd(overflowContainer, setLeftAndRightIndex);
            }
        },
        createArrowHtml = function() {
            leftCtrl = createElement("button", {
                class: prefix+"overflow-control "+prefix+"overflow-control-left",
                tabIndex: "-1"
            });
            leftCtrl.addEventListener("click", handleClickEvent, false);
            rightCtrl = createElement("button", {
                class: prefix+"overflow-control "+prefix+"overflow-control-right",
                tabIndex: "-1"
            });
            rightCtrl.addEventListener("click", handleClickEvent, false);
            var controls = [leftCtrl,rightCtrl];
            if (!options.responsive) {
                each(controls, function(control) {
                    control.classList.add(prefix + "overflow-unresponsive");
                });
            }
            addHeightStyle(controls);
            
            var leftSvg = renderSvg([{name:"chevron-left", show:true}]);
            classAdd(leftSvg, prefix+"overflow-control-left");
            var rightSvg = renderSvg([{name:"chevron-right", show:true}]);
            classAdd(rightSvg, prefix+"overflow-control-right");
            leftCtrl.appendChild(leftSvg);
            rightCtrl.appendChild(rightSvg);
            
            if (overflowContainer.offsetWidth < overflowChildren.width) {
                rightCtrl.setAttribute("active","");
            }
            
            element.insertBefore(leftCtrl, element.children[0]);
            element.insertBefore(rightCtrl, element.children[1]);
        },
        renderSvg = function(svg) {
            var svgWrapper = DOC.createElementNS("http://www.w3.org/2000/svg", "svg");
            classAdd(svgWrapper, prefix + "icon-svg");
            svgWrapper.setAttribute("focusable","false");
            svgWrapper.setAttribute("aria-hidden","true");
            if (!isArray(svg)) {
                svg = [svg];
            }
            each(svg, function(elm) {
                var svgElem = DOC.createElementNS("http://www.w3.org/2000/svg", "use");
                svgElem.setAttributeNS("http://www.w3.org/1999/xlink", "xlink:href", "#" + prefix + elm.name);
                classAdd(svgElem, [prefix + "icon-svg-item"]);
                if (elm.show) {
                    classAdd(svgElem, [ prefix + "show"]);
                }
                svgWrapper.appendChild(svgElem);
            });
            return svgWrapper;
        },
        getChildrenWidth = function() {
            overflowChildren.width = 0;
            overflowChildren.children = [];
            each(overflowContainer.querySelectorAll("LI"), function(child) {
                var style = child.currentStyle || window.getComputedStyle(child),
                    margin;
                if (isIE) {
                    margin = convertRemToPixels(style.getPropertyValue("margin-left")) + convertRemToPixels(style.getPropertyValue("margin-right"));
                } else {
                    margin = parseInt(style.marginLeft) + parseInt(style.marginRight);
                }
                overflowChildren.width += child.offsetWidth+margin;
                overflowChildren.children.push(child);
            });
        },
        convertRemToPixels = function(rem) {  
            if (rem.indexOf("rem") > 0) { 
                rem = rem.split("rem")[0];
                var computed = rem * parseFloat(getComputedStyle(document.documentElement).fontSize);
                if (computed < 0) {
                    return Math.floor(computed);
                } else {
                    return Math.ceil(computed);
                }
            } else {
                return 0;
            }
        },
        setLeftAndRightIndex = function() {
            overflowChildLeft = 0;
            overflowChildRight = null;
            each(overflowChildren.children, function(child, idx) {
                if (child.offsetLeft <= (overflowContainer.offsetLeft*-1)) {
                    overflowChildLeft = idx;
                } else if (!overflowChildRight && (child.offsetLeft+child.offsetWidth) >= ((overflowContainer.offsetLeft*-1)+overflowContainer.offsetWidth)) {
                    overflowChildRight = idx;
                }
            });
            if (!overflowChildRight) {
                overflowChildRight = overflowChildren.children.length-1;
            }
            uicoreCustomEvent("Overflow", "ChangeEvent", element, {
                "left": overflowChildLeft, 
                "right": overflowChildRight, 
                "totalItems": overflowChildren.children.length,
                "hasRightControl": rightCtrl.hasAttribute("active"),
                "hasLeftControl": leftCtrl.hasAttribute("active")
            });
        },
        addHeightStyle = function(els) {
            if (!isArray(els)) {
                els = [els];
            }
            each(els, function(el) {
                el.setAttribute("style", "height: "+ overflowChildren.children[0].offsetHeight +"px;");
            });
        },
        resetContainerStyle = function() {
            if(overflowMediaSize.matches) {
                holdLeft = overflowContainer.style.left;
                overflowContainer.style.left = 0;
            } else {
                if(holdLeft) {
                    overflowContainer.style.left = holdLeft;
                }
            }
        };

    this.lazyLoad = function() {
        if (stringOverflow in element) {
            getChildrenWidth();
            createArrowHtml(); 
            setLeftAndRightIndex();
        }
    };

    //init
    if (!(stringOverflow in element)) {
        overflowElement = element.querySelector("." + element.dataset.target);
        overflowElement.style.overflow = "hidden";
        overflowContainer = overflowElement.querySelector("UL");
        overflowContainer.classList.add(prefix + "overflow-transition");
        overflowContainer.setAttribute("style","position: relative; flex-wrap: nowrap; white-space: nowrap; left: 0px");
        overflowChildren = {};
        // Some child elements contain images so wait for page load to initialize.
        if (!options.lazyload) {
            getChildrenWidth();
            createArrowHtml(); 
            setLeftAndRightIndex();
        }
        window.addEventListener("resize", handleResize, false);
        overflowMediaSize.addListener(resetContainerStyle);
    }

    element[stringOverflow] = self;
}

import { prefix, DOC } from "./utilities.js";
import InputMask from "./helpers/inputMask.js";

export default function Forms(element) {
    element = element instanceof HTMLElement ? element : (function() {
        return false;
    })();

    var stringForm = "Form", 
        togglePassword = function() {
            var passwordState = DOC.getElementById("password");
            if (passwordState.type === "password") {
                passwordState.type = "text";
            } else {
                passwordState.type = "password";
            }
        },
        submitHandler = function(event) {
            var formValidationErrorSuffix = "Feedback";
            if (element.checkValidity() === false) {
                for (var i = 0; i < element.length; i++) {
                    if (!element[i].validity.valid) {
                        element[i].setAttribute("aria-invalid", "true");
                        element[i].setAttribute(
                            "aria-describedby",
                            element[i].getAttribute("id") + formValidationErrorSuffix
                        );
                    } else {
                        element[i].setAttribute("aria-invalid", "false");
                        element[i].setAttribute("aria-describedby", "");
                    }
                }
                event.preventDefault();
                event.stopPropagation();
            }

            element.classList.add("dds__was-validated");
        };
    
    //init
    if (!(stringForm in element)) {
        if (element.querySelectorAll("input")) {
            var children = element.querySelectorAll("input");
            for (var c = 0; c < children.length; c++) {
                if (children[c].classList.contains(prefix + "input-masked")) {
                    new InputMask(children[c]);
                }
            }
        } 
        element.addEventListener("submit", submitHandler);
        if (DOC.getElementById("checkboxPassword")) {
            DOC.getElementById("checkboxPassword").addEventListener("click", togglePassword);
        }
    }
    element[stringForm] = self;
}

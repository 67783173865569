export default function LinkPicker(element) {
    // initialization element
    element = element instanceof HTMLElement ? element : (function() {
        return false;
    })();

    // constants, event targets, strings
    var self = this,
        stringLinkPicker = "LinkPicker",
        interestName = "Interest",
        touseName = "ToUse",
        interest = element.querySelector("select[name=\""+stringLinkPicker+"_"+interestName+"\"]"),
        touse = element.querySelector("select[name=\""+stringLinkPicker+"_"+touseName+"\"]"),
        interestData = JSON.parse(interest.getAttribute("data-select")),
        touseData = JSON.parse(touse.getAttribute("data-select")),
        findName = "Find",
        find = element.querySelector("button[name=\""+stringLinkPicker+"_"+findName+"\"]"),
        // handlers
        onChangeHandler = function(e) {
            var eventTarget = e["target"];
            if(eventTarget === interest) {
                if(interest.selectedIndex === 0) {
                    touse.disabled = true;
                    touse.classList.add("dds__disabled"); //disabled attribute doesn't work on FF
                } else {
                    touse.disabled = false;
                    touse.classList.remove("dds__disabled");
                }
                var obj = touseData[ touseName ][ interest.value ];
                removeAllOptions(touse, true);
                appendDataToSelect(touse, obj);
                find.classList.add("dds__disabled");
            } else {
                if(touse.selectedIndex === 0)
                {
                    find.disabled = true;
                    find.classList.add("dds__disabled");
                }
                else{
                    find.disabled = false;
                    find.classList.remove("dds__disabled");
                }
            }
        },
        clickHandler = function() {
            var link = touse.options[touse.selectedIndex].value;
            find["setAttribute"]("href",link);
            find.click();
        },
        removeAllOptions = function(sel, removeGrp) {
            var len, groups, par;
            if (removeGrp) {
                groups = sel.getElementsByTagName("optgroup");
                len = groups.length;
                for (var i=len; i; i--) {
                    sel.removeChild( groups[i-1] );
                }
            }

            len = sel.options.length;
            for (i=len; i; i--) {
                par = sel.options[i-1].parentNode;
                par.removeChild( sel.options[i-1] );
            }
        },
        appendDataToSelect = function(sel, obj) {
            var f = document.createDocumentFragment();
            var labels = [], group, opts;

            function addOptions(obj) {
                var f = document.createDocumentFragment();
                var o;

                for (var i=0, len=obj.text.length; i<len; i++) {
                    o = document.createElement("option");
                    o.appendChild( document.createTextNode( obj.text[i] ) );

                    if ( obj.value ) {
                        o.value = obj.value[i];
                    }

                    f.appendChild(o);
                }
                return f;
            }

            if ( obj.text ) {
                opts = addOptions(obj);
                f.appendChild(opts);
            } else {
                for ( var prop in obj ) {
                    if ( Object.prototype.hasOwnProperty.call(obj, prop) ) {
                        labels.push(prop);
                    }
                }

                for (var i=0, len=labels.length; i<len; i++) {
                    group = document.createElement("optgroup");
                    group.label = labels[i];
                    f.appendChild(group);
                    opts = addOptions(obj[ labels[i] ] );
                    group.appendChild(opts);
                }
            }
            sel.appendChild(f);
        };


    // init
    if (!(stringLinkPicker in element)) {
        appendDataToSelect(interest, interestData);
        interest.selectedIndex = 0;
        interest.addEventListener("change", onChangeHandler, false);
        touse.addEventListener("change", onChangeHandler, false);
        find.addEventListener("click", clickHandler, false);
        var data = touseData[ touseName ][ interest.value ];
        appendDataToSelect(touse, data);
    }

    element[stringLinkPicker] = self;
}

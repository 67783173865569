import {prefix,uicoreCustomEvent,DOC,setFocus} from "./utilities.js";

export default function Dropdown(element, options) {
    // initialization element
    element = element instanceof HTMLElement ? element : (function() {
        return false;
    })();

    // set option
    options = options || {};
    options.persist = options.persist ? options.persist : element.dataset["persist"] ? element.getAttribute("data-persist") : false;

    // constants, event targets, strings
    var self = this,
        stringDropdown = "Dropdown",
        parent = element.parentNode,
        relatedTarget = null,
        menu = parent.querySelector("."+prefix+"button-dropdown-container"),
        menuItems,
        // preventDefault on empty anchor links
        preventEmptyAnchor = function (anchor) {
            if ((anchor.tagName === "A" && anchor.href && anchor.href.slice(-1) === "#") ||
                (anchor.parentNode && anchor.parentNode.tagName === "A" && anchor.parentNode.href && anchor.parentNode.href.slice(-1) === "#")) {
                this.preventDefault();
            }
        },
        // toggle dismissible events
        toggleDismiss = function () {
            if (element.open) {
                element.addEventListener("click", dismissHandler, false);
                element.addEventListener("keydown", preventScroll, false);
                menu.addEventListener("keydown", preventScroll, false);
                menu.addEventListener("click", dismissHandler, false);
                window.addEventListener("click", windowClickHandler, true);
            } else {
                element.removeEventListener("click", dismissHandler, false);
                element.removeEventListener("keydown", preventScroll, false);
                menu.removeEventListener("keydown", preventScroll, false);
                menu.removeEventListener("click", dismissHandler, false);
                window.removeEventListener("click", windowClickHandler, true);
            }
        },
        // handlers
        dismissHandler = function (e) {
            var eventTarget = e.target, 
                hasData = eventTarget && (stringDropdown in eventTarget || stringDropdown in eventTarget.parentNode);
            if ((eventTarget === menu || menu.contains(eventTarget))
                && (options.persist || hasData)) {
                return;
            } else {
                relatedTarget =
                    eventTarget === element || element.contains(eventTarget)
                        ? element
                        : null;
                
                if (!eventTarget.classList.contains(prefix + "disabled")) {
                    hide();
                }
            }
            preventEmptyAnchor.call(e, eventTarget);
        },
        clickHandler = function (e) {
            relatedTarget = element;
            self.toggle();
            preventEmptyAnchor.call(e, e.target);
        },
        preventScroll = function (e) {
            var key = e.which || e.keyCode;
            if (key === 38 || key === 40) {
                e.preventDefault();
            }
        },
        keyHandler = function (e) {

            var key = e.which || e.keyCode,
                activeItem = DOC.activeElement,
                isMenuOpen = menu.classList.contains(prefix + "show"),
                isSameElement = (activeItem === element) || (activeItem.parentNode === menu),
                isInsideMenu = menu.contains(activeItem),
                isMenuItem = activeItem.parentNode === menu || activeItem.parentNode.parentNode === menu;

            if ((isMenuItem) || (isMenuOpen && isSameElement)) {
                // navigate up | down
                var idx = menuItems.indexOf(activeItem);
                if (isInsideMenu && key === 13) {
                    activeItem.firstElementChild.click();
                }
                idx = 
                    key === 38
                        ? idx >= 1 
                            ? idx - 1 
                            : 0 
                        : key === 40 
                            ? idx < menuItems.length - 1 
                                ? idx + 1 
                                : menuItems.length - 1
                            : idx;
                menuItems[idx] && setFocus(menuItems[idx]);
            }
            if (
                ((menuItems.length && isMenuItem) || // menu has items
                    (!menuItems.length && (isInsideMenu || isSameElement)) || // menu might be a form
                    !isInsideMenu) && // or the focused element is not in the menu at all
                element.open &&
                key === 27 // menu must be open
            ) {
                self.toggle();
                relatedTarget = null;
                element.focus();
            }
            if (!isMenuOpen && !isMenuItem && key === 40) { //if menu is closed and hit enter on arrow btn, open menu and set focus to first item
                self.toggle();
                menuItems[0] && setFocus(menuItems[0]);
                relatedTarget = null;
            }
        },
        // private methods
        show = function () {
            if (element.classList.contains(prefix + "btn-split-arrow")) {
                menu.style.minWidth = parent.offsetWidth+"px";
            } else if (element.classList.contains(prefix + "btn")) {
                menu.style.minWidth = element.offsetWidth +"px";
            }
            uicoreCustomEvent("DropDown", "ShowEvent", element);
            menu.classList.add(prefix + "show");
            parent.classList.add(prefix + "show");
            element.setAttribute("aria-expanded", true);
            uicoreCustomEvent("DropDown", "Shown", element);
            element.open = true;

            setTimeout(function () { 
                toggleDismiss();
            }, 1);
        },
        hide = function () {
            uicoreCustomEvent("DropDown", "HideEvent", element);
            menu.classList.remove(prefix + "show");
            parent.classList.remove(prefix + "show");
            element.setAttribute("aria-expanded", false);
            uicoreCustomEvent("DropDown", "Hidden", element);
            element.open = false;

            toggleDismiss();
            setTimeout(function () {
                element.addEventListener("click", clickHandler, false);
            }, 1);
        },
        blurOutHandler = function(e) {
            var target = e.relatedTarget ? e.relatedTarget : DOC.activeElement;
            if(!(e.currentTarget.contains(target))) {
                if(e.currentTarget.classList.contains(prefix + "btn-dropdown")) {
                    relatedTarget = parent = e.currentTarget;
                } else {
                    relatedTarget = parent = e.currentTarget.parentNode;
                }
            } else {
                return;
            }
            hide();
            preventEmptyAnchor.call(e, relatedTarget);
        },
        windowClickHandler = function(e) {            
            if (!parent.contains(e.target) || parent === e.target){
                hide();
                preventEmptyAnchor.call(e, relatedTarget);
            }
        };

    // public methods
    this.toggle = function () {
        if (parent.classList.contains(prefix + "show") && element.open) {
            hide();
        } else {
            show();
        }
    };

    // init
    if (!(stringDropdown in element)) {
        // set initial state to closed
        element.open = false;
        // prevent adding event handlers twice
        !("tabIndex" in menu) && menu.setAttribute("tabindex", "0"); // Fix onblur on Chrome | Safari
        element.addEventListener("click", clickHandler, false);
        parent.addEventListener("blur", blurOutHandler, true);
        element.addEventListener("keyup", keyHandler, false);
        menu.addEventListener("keyup", keyHandler, false);
        
        var set = menu.children;
        menuItems = [];
        for (var i = 0; i < set.length; i++) {
            if (set[i].children.length && set[i].children[0].tagName === "LI" && !set[i].children[0].firstElementChild.classList.contains(prefix + "disabled")) {
                menuItems.push(set[i].children[0]);
            } 
            else if (set[i].tagName === "LI" && !set[i].firstElementChild.classList.contains(prefix + "disabled")) {
                menuItems.push(set[i]);
            }
        }
    }

    element[stringDropdown] = self;
}

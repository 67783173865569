import { DOC, emulateTransitionEnd, globalObject, getClosest, prefix, styleTip, uicoreCustomEvent, getFullScreenOverlay } from "./utilities.js";

export default function Popover(element, optionsInput) {
    // initialization element
    element = element instanceof HTMLElement ? element : (function () {
        return false;
    })();

    var options = optionsInput || {};
    options.delay = optionsInput && optionsInput.delay && !isNaN(optionsInput.delay) 
        ? parseInt(optionsInput.delay) : element.dataset["delay"] && !isNaN(element.dataset["delay"]) 
            ? parseInt(element.dataset["delay"]) : 10;
    
    options.title = optionsInput && optionsInput.data_title ? optionsInput.data_title : element.dataset["title"] ? element.dataset["title"] : null;
    options.content = optionsInput && optionsInput.data_content ? optionsInput.data_content : element.dataset["content"] ? element.dataset["content"] : null;
    options.placement = optionsInput && optionsInput.data_placement ? optionsInput.data_placement : element.dataset["placement"] ? element.dataset["placement"] : null;
    options.container = optionsInput && optionsInput.container ? optionsInput.container : element.dataset["container"] ? element.dataset["container"] : DOC.body;

    // validate options
    (function () {
        if (options.title == null || options.title === "") {
            throw new Error("There was a problem found with title value, please correct and try again");
        }
        if (options.content == null || options.content === "") {
            throw new Error("There was a problem found with content value, please correct and try again");
        }
        if (options.placement == null) {
            throw new Error("There was a problem found with placement value, please correct and try again");
        }
        var inOffCanvas = getClosest(element, "." + prefix + "modal-offcanvas");
        if(inOffCanvas) {
            options.container = inOffCanvas;
        } else {
            var inFixedTop = getClosest(element, ".fixed-top");
            if(inFixedTop) {
                options.container = inFixedTop;
            } else {
                var inFixedBottom = getClosest(element, ".fixed-bottom");
                if(inFixedBottom) {
                    options.container = inFixedBottom;
                }
            }
        }
    })();

    // DATA API
    var self = this,
        popoverString = "Popover",
        container = DOC.body,
        overlay = getFullScreenOverlay(),
        popoverArrow = null,
        popoverCloseBtn = null,
        positionCalc = true,
        smallMedia = window.matchMedia("(max-width: 767.98px)"),
        popover = null,
        popoverModal = null,
        // handlers
        triggerHandler = function(e) {
            setTimeout( function() {
                self.toggle();
                e.preventDefault();
            }, options.delay);
        },
        blurHandler = function(e) {
            setTimeout( function() {
                if(popover && !popover.contains(DOC.activeElement)) {
                    self.hide();
                    element.focus();
                    DOC.activeElement.removeEventListener("focusout", blurHandler, false);
                    e.preventDefault();
                } else {
                    DOC.activeElement.addEventListener("focusout", blurHandler, false);
                }
            }, options.delay);
        },
        clickHandler = function(e) {
            if (popover && popover.contains(e.target)) {
                if(e.target === popoverCloseBtn || popoverCloseBtn.contains(e.target)) {
                    self.hide();
                    element.focus();
                    e.preventDefault();
                }
            } 
        },
        keyHandler = function (e) {
            if (e.keyCode === 27 && e.type === "keydown") { //esc
                element.focus();
                self.hide();
                e.preventDefault();
            } else if (e.keyCode === 13 && e.type === "keydown") { //enter
                if (e.currentTarget.classList.contains(prefix + "close")) {
                    self.hide();
                    element.focus();
                    e.preventDefault();
                }
            } else if (e.keyCode === 9 & e.type === "keydown") { //tab out
                if (e.shiftKey && e.srcElement.classList.contains(prefix + "popover")) {
                    self.hide();
                    element.focus();
                    e.preventDefault();
                }
            }
        },
        createPopover = function () {
            //create popover divs
            var popoverDialog = null,
                popoverTitleDiv = null,
                popoverTitle = null,
                popoverContent = null,
                popoverParagraph = null;

            popover = DOC.createElement("div");
            popover.setAttribute("tabindex", "0");
            popover.setAttribute("class", prefix + "modal-dialog");
            popover.setAttribute("role", "dialog");

            popoverDialog = DOC.createElement("div");
            popoverDialog.setAttribute("class", prefix + "popover-dialog");
            popover.appendChild(popoverDialog);

            //create popover arrow
            popoverArrow = DOC.createElement("div");
            popoverArrow.setAttribute("class", prefix + "arrow");
            popover.appendChild(popoverArrow);

            //set popover title container
            popoverTitleDiv = DOC.createElement("div");
            popoverTitleDiv.setAttribute("class", prefix + "popover-title-container");
            popoverDialog.appendChild(popoverTitleDiv);

            popoverTitle = DOC.createElement("h3");
            popoverTitle.setAttribute("class", prefix + "popover-header " + prefix + "font-weight-bold");
            popoverTitle.innerHTML = options.title;
            popoverTitleDiv.appendChild(popoverTitle);

            //set popover body content
            popoverContent = DOC.createElement("div");
            popoverContent.setAttribute("class", prefix + "popover-body");

            popoverParagraph = DOC.createElement("p");
            popoverParagraph.innerHTML = options.content;
            popoverDialog.appendChild(popoverContent);
            popoverContent.appendChild(popoverParagraph);
            
            //create close button
           
            popoverCloseBtn = DOC.createElement("button");
            popoverCloseBtn.setAttribute("class", prefix + "close" + " " +  prefix + "icons " + prefix + "close-x");
            popoverCloseBtn.setAttribute("tabindex", "0");
            popoverCloseBtn.setAttribute("aria-label", "Close Popover");
            popoverCloseBtn.setAttribute("data-dismiss", prefix + "popover");

            popoverTitleDiv.appendChild(popoverCloseBtn);
        
            //append to the container
            if (smallMedia.matches) {
                popoverModal = DOC.createElement("div");
                popoverModal.setAttribute("class", prefix + "modal");
                popoverModal.style.display = "block";
            
                DOC.body.classList.add(prefix + "modal-open");
                popoverModal.appendChild(popover);
                element.parentNode.insertBefore(popoverModal, element);
            } else {
                container.appendChild(popover);
            }
            popover.style.display = "block";
            popover.setAttribute("class", prefix + "popover" + " " + prefix + "bs-popover-" + options.placement + " " + prefix + "fade"+ " " + prefix + "show");
        },
        createOverlay = function () {
            if (overlay) {
                overlay.classList.add(prefix + "show");
                overlay.style.visibility = "hidden";
            } else {
                console.warn("POPOVER: Overlay requested. Corresponding HTML missing. Please apply 'dds__overlay' to a div");
            }

        },
        removeOverlay = function () {
            if (overlay) {
                overlay.classList.remove(prefix + "show");
            }
        },
        removePopover = function () {
            if (smallMedia.matches && popoverModal) {
                element.parentNode.removeChild(popoverModal);
            } else {
                container.removeChild(popover);
            }
            popover = null;
            DOC.body.classList.remove(prefix + "modal-open");
        },
        showPopover = function () {
            !(popover.classList.contains(prefix + "show")) && popover.classList.add(prefix + "show");
            popover.focus();
        },
        updatePopover = function () {
            if (positionCalc) {
                styleTip(element, popover, options.placement, container);
            }
            else {
                popover.style.cssText = "";
            }
        },
        // triggers
        showTrigger = function() {
            popover.addEventListener("blur", blurHandler, false);
            uicoreCustomEvent("Popver", "Shown", element);
        },
        hideTrigger = function() {
            popover.removeEventListener("blur", blurHandler, false);
            removePopover();
            uicoreCustomEvent("Popover", "Hidden", element);
        },
        positionCalculation = function(e) {
            if (e.matches) {
                positionCalc = false;
            }
            else {
                positionCalc = true;
            }
        };

    // public methods / handlers
    this.toggle = function() {
        if (popover === null) {
            self.show();
        } else {
            self.hide();
        }
    };
    this.show = function() {
        setTimeout(function () {
            if (popover === null) {
                createPopover();
                createOverlay();
                updatePopover();
                showPopover();

                element.removeEventListener("click", triggerHandler, false);
                window.addEventListener("click", clickHandler, false);
                popover.addEventListener("keydown", keyHandler, false);
                globalObject.addEventListener("resize", self.toggle, false);
                uicoreCustomEvent("Popover", "ShowEvent", element);

                emulateTransitionEnd(popover, showTrigger);
            }
        }, options.delay);
    };
    this.hide = function() {
        removeOverlay();
        setTimeout(function () {
            if (popover && popover !== null && popover.classList.contains(prefix + "show")) {

                uicoreCustomEvent("Popover", "HideEvent", element);
                window.removeEventListener("click", clickHandler, false);
                popover.removeEventListener("keydown", keyHandler, false);
                globalObject.removeEventListener("resize", self.toggle, false);
                popover.classList.remove(prefix + "show");

                emulateTransitionEnd(popover, hideTrigger);
                element.addEventListener("click", triggerHandler, false);
            }
        }, options.delay);
    };
    this.update = function () {
        updatePopover();
    };

    // init
    if (!(popoverString in element)) {
        // prevent adding event handlers twice
        element.addEventListener("click", triggerHandler, false);
        positionCalculation(smallMedia);
        smallMedia.addListener(positionCalculation);
    }

    element[popoverString] = self;
}

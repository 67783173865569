import { prefix } from "./utilities.js";

export default function Spinbox(element, options) {

    // initialization element
    element = element instanceof HTMLElement ? element : (function() {
        return false;
    })();

    options = options || {};
    options.spinmin = options.spinmin ? parseInt(options.spinmin) : element.dataset["spinmin"] ? parseInt(element.dataset["spinmin"]) : 0;
    options.spindefault = options.spindefault ? parseInt(options.spindefault) : element.dataset["spindefault"] ? parseInt(element.dataset["spindefault"]) : 0;
    options.spinmax = options.spinmax ? parseInt(options.spinmax) : element.dataset["spinmax"] ? parseInt(element.dataset["spinmax"]) : 100;
    options.spinstep = options.spinstep ? parseInt(options.spinstep) : element.dataset["spinstep"] ? parseInt(element.dataset["spinstep"]) : 1;

    var msg= "";
    if (isNaN(options.spinmin)) {
        msg += "Min value is not a number.\n";
    }
    if (isNaN(options.spinmax)) {
        msg += "Max value is not a number.\n";
    }
    if (isNaN(options.spindefault)) {
        msg += "Default value is not a number.\n";
    } else if (options.spindefault < options.spinmin || options.spindefault > options.spinmax) {
        msg += "Default value falls outside of the min max values.\n";
    }
    if (isNaN(options.spinstep)) {
        msg += "Step value is not a number.\n";
    }
    if (msg.length) {
        throw new Error(msg);
    }
    
    var self = this,
        stringSpinbox = "Spinbox",
        input,
        plusCtrl,
        minusCtrl,
        horizontal,
        // handlers
        handleKeypressEvent = function(e) {
            var charCode = (e.which) ? e.which : e.keyCode;
            // if not a number
            if ( input.value.length > (horizontal ? 3 : 2) || (charCode > 31 && (charCode < 48 || charCode > 57))) {
                if (charCode == 13){
                    input.blur();
                }
                else if (input.value.length == 0 && (options.spinmin < 0 && charCode == 45)) { // allow minus when input empty and spinmin less than zero
                    return;
                } else {
                    e.preventDefault();
                }
            } else {
                var newInput = input.value.substring(0, e.target.selectionStart) + e.key + input.value.substring(e.target.selectionEnd, input.value.length);
                if (parseInt(newInput) > options.spinmax) {
                    input.value = options.spinmax;
                    e.preventDefault();
                } else if (parseInt(newInput) < options.spinmin) {
                    input.value = options.spinmin;
                    e.preventDefault();
                } else {
                    if (input.value == "-" && e.charCode == 48) { // dont allow -0
                        e.preventDefault();
                    } else {
                        if (charCode == 13) { // Enter key
                            if (Math.abs(parseInt(input.value)) % options.spinstep > 0) {
                                input.value = parseInt(input.value) - (Math.abs(parseInt(input.value)) % options.spinstep);
                            }
                            input.blur();
                        } else {
                            input.setAttribute("aria-valuenow", input.value+e.key);
                        }
                    }
                }
            }
        },
        handleCtrlClickEvent = function(e, control) {
            var temp;
            if (control === minusCtrl) {
                temp = parseInt(input.value) - options.spinstep;
            } else {
                temp = parseInt(input.value) + options.spinstep;
            }
            if (!(temp < options.spinmin) && !(temp > options.spinmax)) {
                input.value = temp;
                input.setAttribute("aria-valuenow", temp);
                handleDisabling();
            }
        },
        handleDisabling = function() {
            if (input.value == options.spinmin || (parseInt(input.value) - options.spinstep) < options.spinmin) {
                minusCtrl.setAttribute("disabled", "");
            } else if (minusCtrl.hasAttribute("disabled")) {
                minusCtrl.removeAttribute("disabled");
            }
            if (input.value == options.spinmax || (parseInt(input.value) + options.spinstep) > options.spinmax) {
                plusCtrl.setAttribute("disabled", "");
            } else if (plusCtrl.hasAttribute("disabled")) {
                plusCtrl.removeAttribute("disabled");
            }
        },
        handleFocusOut = function() {
            // if input is empty, negative, or NaN
            if (input.value.length == 0 || input.value == "-" || !(/^\d+$/.test(input.value))) {
                input.value = options.spindefault;
            } else if (parseInt(input.value) < options.spinmin) {
                input.value = options.spinmin;
            } else if (parseInt(input.value) > options.spinmax) {
                input.value = options.spinmax;
            } else if (Math.abs(parseInt(input.value)) % options.spinstep > 0) {
                input.value = parseInt(input.value) - (Math.abs(parseInt(input.value)) % options.spinstep);
            } else if(input.value.match(/^0+/g)) {
                var match = input.value.match(/^0+/g);
                var temp = input.value.replace(match,"");
                if (temp.length == 0) {
                    input.value = "0";
                } else {
                    input.value = temp;
                }
            }
            handleDisabling();
        },
        handleArrowEvent = function(e) {
            switch (e.keyCode) {
            case 35: // end
                e.preventDefault();
                input.value = options.spinmax;
                break;
            case 36: // home
                e.preventDefault();
                input.value = options.spinmin;
                break;
            case 38: // up
                e.preventDefault();
                if (input.value.length) {
                    plusCtrl.click();
                } else {
                    input.value = options.spinmin + 1;
                    input.setAttribute("aria-valuenow", input.value);
                }
                break;
            case 40: // down
                e.preventDefault();
                minusCtrl.click();
                break;
            case 37: // left
            case 39: // right 
                e.preventDefault(); 
                break;
            default:
                break;
            }
        };

    // init
    if (!(stringSpinbox in element)) {
        input = element.querySelector("." + prefix + "spinbox-input");
        input.addEventListener("keypress", handleKeypressEvent);
        input.addEventListener("keyup", handleDisabling);
        input.addEventListener("focusout", handleFocusOut);
        element.addEventListener("keydown", handleArrowEvent);

        input.value = options.spindefault;
        input.setAttribute("aria-valuenow", options.spindefault);
        input.setAttribute("aria-valuemin", options.spinmin);
        input.setAttribute("aria-valuemax", options.spinmax);

        var controls = element.querySelectorAll("button." + prefix + "spinbox-btn");
        if (element.classList.contains(prefix + "spinbox-horizontal")) {// horizontal spinbox
            horizontal = true;
            minusCtrl = controls[0];
            plusCtrl = controls[1];
        } else {
            horizontal = false;
            minusCtrl = controls[1];
            plusCtrl = controls[0];
        }

        minusCtrl.addEventListener("click", function(e) {
            handleCtrlClickEvent(e, minusCtrl);
        });
        plusCtrl.addEventListener("click", function(e) {
            handleCtrlClickEvent(e, plusCtrl);
        });
        handleDisabling();
    }


    element[stringSpinbox] = self;
}
import {getClosest,globalObject,DOC,prefix,styleTip,emulateTransitionEnd,uicoreCustomEvent} from "./utilities.js";

export default function Tooltip(element, optionsInput) {
    // initialization element
    element = element instanceof HTMLElement ? element : (function() {
        return false;
    })();

    // set options
    var options = optionsInput || {};

    // init options
    options.title = optionsInput && optionsInput.data_title ? optionsInput.data_title : element.dataset["title"] ? element.dataset["title"] : null;
    options.animation = optionsInput && typeof optionsInput.animation === "boolean" ? optionsInput.animation : optionsInput && optionsInput.animation === prefix + "fade" ? prefix + "fade" : false;
    options.placement = optionsInput && optionsInput.data_placement ? optionsInput.data_placement : element.dataset["placement"] ? element.dataset["placement"] : null;
    options.delay = optionsInput && optionsInput.delay ? parseInt(optionsInput.delay) : element.dataset["delay"] ? parseInt(element.dataset["delay"]) : 60;
    options.container = optionsInput && optionsInput.container ? optionsInput.container : element.dataset["container"] ? element.dataset["container"] : DOC.body;

    // validate options
    (function () {
        if (options.title == null) {
            throw new Error("There was a problem found with title value, please correct and try again");
        }
        if (options.placement == null) {
            throw new Error("There was a problem found with placement value, please correct and try again");
        }
        var inOffCanvas = getClosest(element, "." + prefix + "modal-offcanvas");
        if(inOffCanvas) {
            options.container = inOffCanvas;
        } else {
            var inFixedTop = getClosest(element, ".fixed-top");
            if(inFixedTop) {
                options.container = inFixedTop;
            } else {
                var inFixedBottom = getClosest(element, ".fixed-bottom");
                if(inFixedBottom) {
                    options.container = inFixedBottom;
                }
            }
        }

    })();

    // bind, event targets, title and constants
    var self = this,
        stringTooltip = "Tooltip",
        mouseHover = "onmouseleave" in DOC ? ["mouseenter", "mouseleave"] : ["mouseover", "mouseout"],
        tooltip,
        tooltipArrow,
        createToolTip = function() {

            var tooltipId = options.title.replace(/[\s\W]/g, "").toLowerCase().substring(0, 15);
            if(!DOC.getElementById(tooltipId)) {
                tooltip = DOC.createElement("div");
                tooltip.style["top"] = "0px"; //move tooltip to top to prevent scrollbar during calculation
                tooltip.setAttribute("role", "tooltip");
                element.setAttribute("aria-describedby", tooltipId);

                // tooltip arrow
                tooltipArrow = DOC.createElement("div");
                tooltipArrow.setAttribute("class", prefix+"arrow");
                tooltip.appendChild(tooltipArrow);
                // tooltip inner
                var tooltipInner = DOC.createElement("div");
                tooltipInner.setAttribute("class", prefix+"tooltip-inner");
                tooltipInner.innerHTML = options.title;
                tooltipInner.setAttribute("id", tooltipId);
                tooltip.appendChild(tooltipInner);

                options.container.appendChild(tooltip);
                tooltip.setAttribute("class", prefix+"tooltip" + " " + prefix+"bs-tooltip"+"-"+options.placement + " " + options.animation);
            }
        },
        updateTooltip = function() {
            tooltip.setAttribute("style", "");
            tooltipArrow.setAttribute("style", "");
            styleTip(element, tooltip, options.placement, options.container, true);
        },
        showTooltip = function() {
            !(tooltip.classList.contains(prefix + "show")) && tooltip.classList.add(prefix + "show");
        },
        // triggers
        showTrigger = function() {
            globalObject.addEventListener("resize", self.hide, false);
            uicoreCustomEvent("Tooltip", "Shown", element);
        },
        hideTrigger = function() {
            globalObject.removeEventListener("resize", self.hide);
            uicoreCustomEvent("Tooltip", "Hidden", element);
        },
        keyHandler = function(e) {
            var key = e.which || e.keyCode;
            if (key === 27) {
                self.hide(e);
            } else if (key === 13) {
                self.show();
            }
        };

    // public methods
    this.show = function() {
        setTimeout(function() {
            updateTooltip();
            showTooltip();
            uicoreCustomEvent("Tooltip", "ShowEvent", element);
            options.animation
                ? emulateTransitionEnd(tooltip, showTrigger)
                : showTrigger();
        }, options.delay);
    };
    this.hide = function(e) {
        var target = DOC.activeElement;
        if (e) {
            target = e.relatedTarget;
        }
        if (!target === tooltip || !tooltip.contains(target)) {
            setTimeout(function() {
                if (tooltip && tooltip.classList.contains(prefix + "show")) {
                    uicoreCustomEvent("Tooltip", "HideEvent", element);
                    tooltip.classList.remove(prefix + "show");
                    options.animation
                        ? emulateTransitionEnd(tooltip, hideTrigger)
                        : hideTrigger();
                }
            }, options.delay);
        }
    };
    this.toggle = function() {
        if (!tooltip) {
            self.show();
        } else {
            self.hide();
        }
    };

    // init
    if (!(stringTooltip in element)) {
        // prevent adding event handlers twice
        element.setAttribute("data-original-title", options.title);
        element.removeAttribute("title");
        element.addEventListener(mouseHover[0], self.show, false);
        element.addEventListener("focusin", self.show, false);
        element.addEventListener(mouseHover[1], self.hide, false);   
        element.addEventListener("focusout", self.hide, false);
        element.addEventListener("keydown", keyHandler, false);
        if (!tooltip) {
            createToolTip();
            tooltip.addEventListener("mouseleave", self.hide, false);
        }
    }

    element[stringTooltip] = self;
}
// parse the package.json file to get needed variables
import { ddsSpace, ddsToken } from "../../package.json";
import Dayjs from "dayjs";

export const DOC = document;
export const HTML = DOC.documentElement;
export const globalObject = typeof global !== "undefined" ? global : window;
export const prefix = ddsSpace + ddsToken;
export const supportTransitions =
    "Webkit" + "Transition" in HTML["style"] ||
    "Transition"["toLowerCase"]() in HTML["style"];
export const transitionEndEvent =
    "Webkit" + "Transition" in HTML["style"] ?
        "Webkit"["toLowerCase"]() + "Transition" + "End" :
        "Transition"["toLowerCase"]() + "end";
export const transitionDuration =
    "Webkit" + "Duration" in HTML["style"] ?
        "Webkit"["toLowerCase"]() + "Transition" + "Duration" :
        "Transition"["toLowerCase"]() + "Duration";
export const tipPositions = /\b(top|bottom|left|right)+/;
export const isIPhone = (/(IPhone)/i.test(navigator.platform));
export const isIE = !!/(msie|trident|Windows Phone)/i.test(navigator.userAgent);
export const isSafari = navigator.userAgent.toLowerCase().indexOf("safari/") > -1;
export const isEdge = (/Edge\/\d./i.test(navigator.userAgent));
export const isChrome = !!window.chrome && (!!window.chrome.webstore || !!window.chrome.runtime);

// selection methods
export function getFullScreenOverlay() {
    var overlay = DOC.getElementById(prefix + "full-screen-overlay");
    if (!overlay) {
        console.warn("OFF-CANVAS: Overlay requested. Corresponding HTML missing. Please apply id 'dds__full-screen-overlay' and class 'dds__overlay' to an empty div");
        return false;
    }
    return overlay;
}

export function whichTransitionEndEvent(element){
    var transition;

    var transitions = {
        "transition"      : "transitionend",
        "OTransition"     : "oTransitionEnd",
        "MozTransition"   : "transitionend",
        "WebkitTransition": "webkitTransitionEnd"
    };

    for (transition in transitions){
        if (element.style[transition] !== undefined){
            return transitions[transition];
        }
    }
}

export function getElementsByClassName(element, classNAME) {
    // returns Array
    return [].slice.call(element["getElementsByClassName"](classNAME));
}

export function getSibling(element, selector) {

    var firstChar = selector.charAt(0);
    var selectorSubstring = selector.substr(1);

    if (firstChar === ".") {
        if (element.nextElementSibling && element.nextElementSibling.classList) {
            if(element.nextElementSibling.classList.contains(selectorSubstring)) {
                return element.nextElementSibling;
            } else {
                return getSibling(element.nextElementSibling, selector);
            }
        }
    }  else if (firstChar === "#") {
        
        if (element.nextElementSibling && element.nextElementSibling.id) {
            if(element.nextElementSibling.id === selectorSubstring) {
                return element.nextElementSibling;
            } else {
                return getSibling(element.nextElementSibling, selector);
            }
        }
    } else {
        if (element.nextElementSibling && element.nextElementSibling.tagName) {
            if(element.nextElementSibling.tagName.toLowerCase() === selector.toLowerCase()) {
                return element.nextElementSibling;
            } else {
                return getSibling(element.nextElementSibling, selector);
            }
        }
    }
}

export function getClosest(element, selector) {
    // element is the element and selector is for the closest parent element to find
    // source http://gomakethings.com/climbing-up-and-down-the-dom-tree-with-vanilla-javascript/
    var firstChar = selector.charAt(0);

    var selectorSubstring = selector.substr(1);
    if (firstChar === ".") {
        // If selector is a class
        for (; element && element !== DOC; element = element.parentNode) {
            // Get closest match
            if (
                element["parentNode"].querySelector(selector) !== null &&
                // hasClass(element, selectorSubstring)) {
                element.classList["contains"](selectorSubstring)
            ) {
                return element;
            }
        }
    } else if (firstChar === "#") {
        // If selector is an ID
        for (; element && element !== DOC; element = element.parentNode) {
            // Get closest match
            if (element.id === selectorSubstring) {
                return element;
            }
        }
    } else {
        // If selector is a tagName
        for (; element && element !== DOC; element = element.parentNode) {
            // Get closest match
            if (element.tagName && element.tagName.toLowerCase() === selector.toLowerCase() ) {
                return element;
            } else if (element.previousElementSibling && element.previousElementSibling.tagName.toLowerCase() === selector.toLowerCase()) {
                return element.previousElementSibling;
            } else {
                if (element.querySelector(selector)) {
                    return element.querySelector(selector);
                }
            }
        }
    }
    return false;

}

export function one(element, event, handler) {
    element.addEventListener(
        event,
        function handlerWrapper(e) {
            handler(e);
            element.removeEventListener(event, handlerWrapper, false);
        },
        false
    );
}

export function getTransitionDurationFromElement(element) {
    var duration = supportTransitions ?
        globalObject["getComputedStyle"](element)[transitionDuration] :
        0;
    duration = parseFloat(duration);
    duration =
        typeof duration === "number" && !isNaN(duration) ? duration * 1000 : 0;
    return duration + 50; // we take a short offset to make sure we fire on the next frame after animation
}

export function emulateTransitionEnd(element, handler) {
    // emulateTransitionEnd since 2.0.4
    var called = 0;

    var duration = getTransitionDurationFromElement(element);
    supportTransitions &&
        one(element, transitionEndEvent, function (e) {
            handler(e);
            called = 1;
        });
    setTimeout(function () {
        !called && handler();
    }, duration);
}

export function uicoreCustomEvent(componentName, eventName, related, details) {
    var evt;
    if (isIE){
        evt = document.createEvent("CustomEvent");
        evt.initCustomEvent( "uic" + componentName + eventName, true, true, details );
    }
    else{
        evt = new CustomEvent("uic" + componentName + eventName, {
            bubbles: true,
            detail: details
        });
    }
    evt.relatedTarget = related;
    related.dispatchEvent(evt);
}


// tooltip / popover stuff
export function getScroll() {
    // also Affix and ScrollSpy uses it
    return {
        y: globalObject.pageYOffset || HTML["scrollTop"],
        x: globalObject.pageXOffset || HTML["scrollLeft"]
    };
}

// set new focus element since 2.0.3
export function setFocus(element) {
    element.focus ? element.focus() : element.setActive();
}

export function styleTip(link, element, position, parent, useArrow) {
    // both popovers and tooltips (target,tooltip,placement,elementToAppendTo)
    var elementDimensions = {
        w: element["offsetWidth"],
        h: element["offsetHeight"]
    };

    useArrow = typeof useArrow === "boolean" ? useArrow : true;

    var windowWidth = HTML["clientWidth"] || DOC["body"]["clientWidth"];

    var windowHeight = HTML["clientHeight"] || DOC["body"]["clientHeight"];

    var rect = link["getBoundingClientRect"]();

    var scroll =
        parent === DOC["body"] ?
            getScroll() :
            {
                y: parent["offsetTop"] + parent["scrollTop"],
                x: parent["offsetLeft"] + parent["scrollLeft"]
            };

    var linkDimensions = {
        w: link.offsetWidth,
        h: link.offsetHeight
    };


    var isPopover = element.classList["contains"]("dds__popover");

    var topPosition;

    var leftPosition;

    var arrow = element.querySelector(".dds__arrow");

    var arrowTop;

    var arrowLeft;

    var arrowWidth;

    var arrowHeight;

    var halfTopExceed =
        rect["top"] + linkDimensions.h / 2 - elementDimensions.h / 2 < 0;

    var halfLeftExceed =
        rect["left"] + linkDimensions.w / 2 - elementDimensions.w / 2 < 0;

    var halfRightExceed =
        rect["left"] + elementDimensions.w / 2 + linkDimensions.w / 2 >= windowWidth;

    var halfBottomExceed =
        rect["top"] + elementDimensions.h / 2 + linkDimensions.h / 2 >= windowHeight;

    var topExceed = rect["top"] - elementDimensions.h < 0;

    var leftExceed = rect["left"] - elementDimensions.w < 0;

    var bottomExceed =
        rect["top"] + elementDimensions.h + linkDimensions.h >= windowHeight;

    var rightExceed =
        rect["left"] + elementDimensions.w + linkDimensions.w >= windowWidth;

    // recompute position
    var posChanged;
    position =
        (position === "left" || position === "right") && leftExceed && rightExceed ?
            posChanged = true && "top" :
            position; // first, when both left and right limits are exceeded, we fall back to top|bottom
    position = position === "top" && topExceed ? "bottom" : position;
    position = position === "bottom" && bottomExceed ? "top" : position;
    position = position === "left" && leftExceed ? "right" : position;
    position = position === "right" && rightExceed ? "left" : position;

    // update tooltip/popover class
    element.className["indexOf"](position) === -1 &&
        (element.className = element.className.replace(tipPositions, position));

    // update tooltip/popover dimensions
    if (posChanged) {
        elementDimensions = {
            w: element["offsetWidth"],
            h: element["offsetHeight"]
        };
    }
    // we check the computed width & height and update here
    arrowWidth = arrow["offsetWidth"];
    arrowHeight = arrow["offsetHeight"];

    var rectLeft = rect["left"], rectTop = rect["top"];
    if (!useArrow) {
        if (position === "left") {
            rectLeft = rect["left"] + arrowWidth;
        } else if( position === "right") {
            rectLeft = rect["left"] - arrowWidth;
        } else if (position === "top") {
            rectTop = rect["top"] + arrowHeight;
        } else if (position === "bottom") {
            rectTop = rect["top"] - arrowHeight;
        }
    }

    // apply styling to tooltip or popover
    if (position === "left" || position === "right") {
        // secondary|side positions
        if (position === "left") {
            // LEFT
            leftPosition =
                rectLeft +
                scroll.x -
                elementDimensions.w -
                (isPopover ? arrowWidth : 3); // subtract to move more left
            window.addEventListener("resize", function () {
                arrow["style"]["left"] = "initial";
            });
        } else {
            // RIGHT
            leftPosition = rectLeft + linkDimensions.w + (isPopover ? 0 : 3); // add to move more right
        }

        // adjust top and arrow
        if (halfTopExceed) {
            topPosition = rect["top"] + scroll.y;
            arrowTop = linkDimensions.h / 2 - arrowWidth;
        } else if (halfBottomExceed) {
            topPosition =
                rect["top"] - elementDimensions.h + linkDimensions.h + scroll.y;
            arrowTop = elementDimensions.h - linkDimensions.h / 2 - (isPopover ? arrowWidth : arrowWidth /2);
        } else {
            topPosition =
                rect["top"] - elementDimensions.h / 2 + linkDimensions.h / 2 + scroll.y;
            arrowTop =
                elementDimensions.h / 2 - 
                (isPopover ? arrowHeight * 0.9 : arrowHeight / 2);
        }
    } else if (position === "top" || position === "bottom") {
        // primary|vertical positions
        if (position === "top") {
            // TOP
            topPosition =
                rectTop +
                scroll.y -
                elementDimensions.h - 
                (isPopover ? arrowHeight : 3); // subtract to move more up
        } else {
            // BOTTOM
            topPosition = rectTop + linkDimensions.h + scroll.y + (isPopover ? 0 : 3);
        }
        // adjust left | right and also the arrow
        if (halfLeftExceed) {
            leftPosition = 0;
            arrowLeft = rect["left"] + linkDimensions.w / 2 - (isPopover ? arrowWidth : arrowWidth /2);
        } else if (halfRightExceed) {
            leftPosition = windowWidth - elementDimensions.w * 1.01;
            arrowLeft =
                elementDimensions.w -
                (windowWidth - rect["left"]) +
                linkDimensions.w / 2 -
                arrowWidth / 2;
        } else {
            leftPosition =
                rect["left"] - elementDimensions.w / 2 + linkDimensions.w / 2 + scroll.x;
            arrowLeft = elementDimensions.w / 2 - arrowWidth / 2;
        }
    }
    
    // apply style to tooltip/popover and its arrow
    element["style"]["top"] = topPosition + "px";
    element["style"]["left"] = leftPosition + "px";

    arrowTop && (arrow["style"]["top"] = arrowTop + "px");
    arrowLeft && (arrow["style"]["left"] = arrowLeft + "px");

}

export function handleFirstTab(e) {
    if (e.keyCode === 9) {
        document.body.classList.add("user-is-tabbing");

        window.removeEventListener("keydown", handleFirstTab);
        window.addEventListener("mousedown", handleMouseDownOnce);
    }
}

export function handleMouseDownOnce(e) {
    //NVDA in browse mode swallows the enter key as mouse click: https://github.com/nvaccess/nvda/issues/4903
    //This breaks our visual focus toggle. Enter key event has x,y values of 0,0 whereas click has positive x,y values.
    //Capture enter key when treated as mousedown by NVDA browse mode.
    if (e.x === 0 && e.y === 0) {
        return;
    }
    document.body.classList.remove("user-is-tabbing");

    window.removeEventListener("mousedown", handleMouseDownOnce);
    window.addEventListener("keydown", handleFirstTab);
}


// Form--passwords validation
//================================================================
export function passwordVerification() {
    var myInput = document.getElementById("psw");
    var letter = document.getElementById("letter");
    var capital = document.getElementById("capital");
    var number = document.getElementById("number");
    var length = document.getElementById("length");

    // When the user clicks on the password field, show the message box
    myInput.onfocus = function () {
        document.getElementById("message").style.display = "block";
    };

    // When the user clicks outside of the password field, hide the message box
    myInput.onblur = function () {
        document.getElementById("message").style.display = "none";
    };

    // When the user starts to type something inside the password field
    myInput.onkeyup = function () {
        // Validate lowercase letters
        var lowerCaseLetters = /[a-z]/g;
        if (myInput.value.match(lowerCaseLetters)) {
            letter.classList.add("valid");
            letter.classList.remove("invalid");
        } else {
            letter.classList.remove("valid");
            letter.classList.add("invalid");
        }

        // Validate capital letters
        var upperCaseLetters = /[A-Z]/g;
        if (myInput.value.match(upperCaseLetters)) {
            capital.classList.remove("invalid");
            capital.classList.add("valid");
        } else {
            capital.classList.remove("valid");
            capital.classList.add("invalid");
        }

        // Validate numbers
        var numbers = /[0-9]/g;
        if (myInput.value.match(numbers)) {
            number.classList.remove("invalid");
            number.classList.add("valid");
        } else {
            number.classList.remove("valid");
            number.classList.add("invalid");
        }

        // Validate length
        if (myInput.value.length >= 8) {
            length.classList.remove("invalid");
            length.classList.add("valid");
        } else {
            length.classList.remove("valid");
            length.classList.add("invalid");
        }
    };
}

/**
 * Check is item is object
 * @return {Boolean}
 */
export function isObject(val) {
    return Object.prototype.toString.call(val) === "[object Object]";
}

/**
 * Check is item is array
 * @return {Boolean}
 */
export function isArray(val) {
    return Array.isArray(val);
}

/**
 * Check for valid JSON string
 * @param  {String}   str
 * @return {Boolean|Array|Object}
 */
export function isJson(str) {
    var t = !1;
    try {
        t = JSON.parse(str);
    } catch (e) {
        return !1;
    }
    return !(null === t || (!isArray(t) && !isObject(t))) && t;
}

/**
 * Iterator helper
 * @param  {(Array|Object)}   arr     Any object, array or array-like collection.
 * @param  {Function}         fn      Callback
 * @param  {Object}           scope   Change the value of this
 * @return {Void}
 */
export function each(arr, fn, scope) {
    var n;
    if (isObject(arr)) {
        for (n in arr) {
            if (Object.prototype.hasOwnProperty.call(arr, n)) {
                fn.call(scope, arr[n], n);
            }
        }
    } else {
        for (n = 0; n < arr.length; n++) {
            fn.call(scope, arr[n], n);
        }
    }
}

/**
 * Bubble sort algorithm
 */
export function sortItems(a, b) {
    var c, d;
    if (1 === b) {
        c = 0;
        d = a.length;
    } else {
        if (b === -1) {
            c = a.length - 1;
            d = -1;
        }
    }
    for (var e = !0; e;) {
        e = !1;
        for (var f = c; f != d; f += b) {
            if (a[f + b] && a[f].value > a[f + b].value) {
                var g = a[f],
                    h = a[f + b],
                    i = g;
                a[f] = h;
                a[f + b] = i;
                e = !0;
            }
        }
    }
    return a;
}

/**
 * Use moment.js to parse cell contents for sorting
 * @param  {String} content     The datetime string to parse
 * @param  {String} format      The format for moment to use
 * @return {String|Boolean}     Datatime string or false
 */
export function parseDate(content, format) {
    var date = false;

    // moment() throws a fit if the string isn't a valid datetime string
    // so we need to supply the format to the constructor (https://momentjs.com/docs/#/parsing/string-format/)

    // Converting to YYYYMMDD ensures we can accurately sort the column numerically

    if (format) {
        switch (format) {
        case "ISO_8601":
            // ISO8601 is already lexiographically sorted, so we can just sort it as a string.
            date = content;
            break;
        case "RFC_2822":
            date = new Dayjs(content, "ddd, MM MMM YYYY HH:mm:ss ZZ").format("YYYYMMDD");
            break;
        case "MYSQL":
            date = new Dayjs(content, "YYYY-MM-DD hh:mm:ss").format("YYYYMMDD");
            break;
        case "UNIX":
            date = new Dayjs(content).unix();
            break;
            // User defined format using the data-format attribute or columns[n].format option
        default:
            date = new Dayjs(content, format).format("YYYYMMDD");
            break;
        }
    }
    //console.log(date);
    return date;
}

/**
 * Create DOM element node
 * @param  {String}   a nodeName
 * @param  {Object}   b properties and attributes
 * @return {Object}
 */
export function createElement(a, b) {
    var d = DOC.createElement(a);
    if (b && "object" == typeof b) {
        var e;
        for (e in b) {
            if ("html" === e) {
                d.innerHTML = b[e];
            } else {
                d.setAttribute(e, b[e]);
            }
        }
    }
    return d;
}

export function loadURLSVGs(paths, lazyload) {
    var lazyloading = typeof(lazyload) === "boolean" ? lazyload : true,
        execute = function(paths) {
            if (paths.length > 0 && !Array.isArray(paths)) {
                paths = new Array(paths);
            }
            if (!paths || paths.length < 1) {
                console.error("The File path(s) supplied were either empty or null.");
                return false;
            }
            var defs,
                style,
                svg,
                handleSVGResponse = function(response) {
                    if (response.match(/\<script|javascript:/g)) {
                        throw Error("Possible malicous scripting code found!\n"+response);
                    } else {
                        var frag = DOC.createRange().createContextualFragment(response);
                        if (DOC["body"].firstChild.tagName != "svg") {
                            DOC["body"].insertBefore(frag, DOC["body"].firstChild);
                        } else {
                            svg = DOC["body"].firstChild;
                            defs = defs ? defs : svg.querySelector("defs");
                            style = style ? style : svg.querySelector("style");
                            var fragSymbol = frag.querySelector("symbol");
                            if (!defs.querySelector("#"+fragSymbol.id)) {
                                defs.appendChild(fragSymbol);
                                var fragStyle = frag.querySelector("style");
                                if (style && fragStyle) {
                                    style.innerHTML += fragStyle.innerHTML;
                                } else if (fragStyle) {
                                    svg.insertBefore(fragStyle, defs);
                                }
                            }
                        }
                    }
                };
            Array.prototype.forEach.call(paths, function(url) {
                var xhr = new XMLHttpRequest();
                xhr.addEventListener("error", function() {
                    console.error("The File path supplied [ "+paths+" ] was invalid.");
                });
                xhr.addEventListener("load", function() {
                    if (xhr.readyState == 4 && xhr.status == 200) {
                        handleSVGResponse(xhr.responseText);
                    }
                });
                xhr.open("GET", url, true);
                xhr.send();
            });
        };
    if (lazyloading) {
        DOC.addEventListener("DOMContentLoaded", function () {
            execute(paths);
        });
    } else {
        execute(paths);
    }
}

/**
 * Function created to support the ability to add classes
 * to an SVG Element / Node. The classList for Elements and
 * Nodes in IE using createElementNS does not support classList  
 * so the class attribute needs to be used
 * @param {Element/Node} elem 
 */
export function classAdd(elem, classes) {
    var classList;
    if (!isArray(classes)) {
        classList = [classes];
    } else {
        classList = classes;
    }
 
    each(classList, function(clazz) {
        if (!elem.classList) { // Element does not support classList
            var newClasses = elem.getAttribute("class");
            if (!newClasses) {
                elem.setAttribute("class", clazz);
            } else {
                if (newClasses.indexOf(clazz) == -1)
                    elem.setAttribute("class", newClasses + " " + clazz);
            }
        } else {
            elem.classList.add(clazz);
        }
    });
}

/**
 * Function created to support the ability to remove classes
 * to an SVG Element / Node. The classList for Elements and
 * Nodes in IE using createElementNS does not support classList 
 * so the class attribute needs to be used
 * @param {*} elem 
 */
export function classRemove(elem, classes) {
    var classList;
    if (!isArray(classes)) {
        classList = [classes];
    } else {
        classList = classes;
    }
    each(classList, function(clazz) {
        if (!elem.classList) { // Element does not support classList
            var newClasses = elem.getAttribute("class").replace(clazz, "");
            elem.setAttribute("class", newClasses);
        } else {
            elem.classList.remove(clazz);
        }
    }); 
}

export function debounce(func, wait, immediate) {
    var timeout;
    return function() {
        var context = this, args = arguments;
        var later = function() {
            timeout = null;
            if (!immediate) func.apply(context, args);
        };
        var callNow = immediate && !timeout;
        clearTimeout(timeout);
        timeout = setTimeout(later, wait);
        if (callNow) func.apply(context, args);
    };
}

export function ieMosaicPolyfill(slides) {
    var divTag;
    for (var i = 0; i < slides.length; i++) {
        var imgTag = slides[i].querySelector("img.dds__card-img-top");
        if (imgTag) {
            divTag = slides[i].querySelector("div.dds__card-img-top");
            divTag.style.backgroundImage = "url('" + imgTag.src + "')";
            imgTag.style.display = "none";
            slides[i].parentNode.classList.add("dds__background-img");
        }
    }
}
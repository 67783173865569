import {prefix, uicoreCustomEvent} from "./utilities.js";

export default function Alert(element) {
    // initialization element
    element = element instanceof HTMLElement ? element : (function() {
        return false;
    })();

    // bind, target alert, duration and stuff
    var self = this,
        stringAlert = "Alert",
        closeButton = element.querySelector("."+prefix+"close-x"),
        dismissMessage = element.querySelector("."+prefix+"dismiss"),
   
        // handlers
        clickHandler = function(e) {
            if ((closeButton === e.target || closeButton.contains(e.target)) || 
            (dismissMessage === e.target || dismissMessage.contains(e.target))){
                self.toggle();
            }
        };

    // public method
    this.toggle = function() {
        if (element) {
            if (element.getAttribute("aria-expanded") === "true") {
                element.setAttribute("aria-expanded", "false");
                dismissMessage.focus();
                uicoreCustomEvent("Alert", "DismissEvent", element);
            } else {
                element.setAttribute("aria-expanded", "true");
                closeButton.focus();
                uicoreCustomEvent("Alert", "OpenEvent", element);
            }
           
        }
    };

    // init
    if (!(stringAlert in element)) {
        // prevent adding event handlers twice
        closeButton.addEventListener("click", clickHandler, false);
        dismissMessage.addEventListener("click", clickHandler, false);
    }

    element[stringAlert] = self;
}
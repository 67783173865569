import {getElementsByClassName,uicoreCustomEvent,globalObject,prefix,getScroll,DOC} from "./utilities.js";

export default function ScrollSpy(element, options) {
    // initialization element, the element we spy on
    element = element instanceof HTMLElement ? element : (function() {
        return false;
    })();

    // DATA API
    var targetData = DOC.querySelectorAll(element["getAttribute"]("data-target")),
        offsetData = element["getAttribute"]("data-offset");

    // set options
    options = options || {};
    if (!options["target"] && !targetData) {
        return;
    } // invalidate

    // event targets, constants
    var self = this,
        stringScrollSpy = "ScrollSpy",
        spyTarget =
        (options["target"] && DOC.querySelectorAll(options["target"])) || targetData,
        links = spyTarget && spyTarget["getElementsByTagName"]("A"),
        offset = parseInt(offsetData || options["offset"]) || 10,
        items = [],
        targetItems = [],
        scrollOffset,
        scrollTarget =
        element["offsetHeight"] < element["scrollHeight"] ? element : globalObject, // determine which is the real scrollTarget
        isWindow = scrollTarget === globalObject;

    // populate items and targets
    for (var i = 0, il = links[length]; i < il; i++) {
        var href = links[i]["getAttribute"]("href"),
            targetItem =
            href &&
            href.charAt(0) === "#" &&
            href.slice(-1) !== "#" &&
            DOC.querySelectorAll(href);
        if (targetItem) {
            items["push"](links[i]);
            targetItems["push"](targetItem);
        }
    }

    // private methods
    var updateItem = function(index) {
            var item = items[index],
                targetItem = targetItems[index], // the menu item targets this element
                dropdown = item["parentNode"]["parentNode"],
                dropdownLink =
            dropdown.classList["contains"](prefix + "dropdown") &&
            dropdown["getElementsByTagName"]("A")[0],
                targetRect = isWindow && targetItem["getBoundingClientRect"](),
                isActive = item.classList["contains"](prefix + "active") || false,
                topEdge =
                (isWindow
                    ? targetRect[top] + scrollOffset
                    : targetItem["offsetTop"]) - offset,
                bottomEdge = isWindow
                    ? targetRect["bottom"] + scrollOffset - offset
                    : targetItems[index + 1]
                        ? targetItems[index + 1]["offsetTop"] - offset
                        : element["scrollHeight"],
                inside = scrollOffset >= topEdge && bottomEdge > scrollOffset;

            if (!isActive && inside) {
                if (!(item.classList["contains"](prefix + "active")) ) {
                    item.classList.add(prefix + "active");
                    if (dropdownLink && !(dropdownLink.classList["contains"](prefix + "active")) ) {
                        dropdownLink.classList.add(prefix + "active");
                    }
                    uicoreCustomEvent("Navigation", "ActivateEvent", element, { "menu-item": items[index] });
                }
            } else if (!inside) {
                if (item.classList["contains"](prefix + "active")) {
                    item.classList.remove(prefix + "active");
                    if (
                        dropdownLink &&
                dropdownLink.classList["contains"](prefix + "active") &&
                !getElementsByClassName(item["parentNode"], prefix + "active").length
                    ) {
                        dropdownLink.classList.remove(prefix + "active");
                    }
                }
            } else if ((!inside && !isActive) || (isActive && inside)) {
                return;
            }
        },
        updateItems = function() {
            scrollOffset = isWindow ? getScroll().y : element["scrollTop"];
            for (var index = 0, itl = items[length]; index < itl; index++) {
                updateItem(index);
            }
        };

    // public method
    this.refresh = function() {
        updateItems();
    };

    // init
    if (!(stringScrollSpy in element)) {
        // prevent adding event handlers twice
        scrollTarget.addEventListener("scroll", self.refresh, false);
        globalObject.addEventListener("resize", self.refresh, false);
    }
    self.refresh();
    element[stringScrollSpy] = self;
}
import { DOC,emulateTransitionEnd, getFullScreenOverlay, getTransitionDurationFromElement, prefix,supportTransitions,uicoreCustomEvent } from "./utilities.js";

export default function Modal(element, options) {
    // element can be the modal/triggering button

    // the modal (both JavaScript / DATA API init) / triggering button element (DATA API)
    element = element instanceof HTMLElement ? element : (function () {
        return false;
    })();

    // set options
    options = options || {};

    options.static = options.backdrop === "static" ? true : false;

    // determine modal, triggering element
    var self = this,
        stringModal = "Modal",
        focusableEls,
        origRightPadding,
        scrollbarWidth,
        modalElement = DOC.getElementById(element.dataset["target"].substr(1)),
        modalContent = modalElement && modalElement.querySelector("." + prefix + "modal-content"),
        overlayDelay,
        overlay = getFullScreenOverlay(),
        // private methods
        setFocusableElements = function() {
            if (!focusableEls) {
                focusableEls = modalElement.querySelectorAll("a[href], area[href], input:not([disabled]), select:not([disabled]), textarea:not([disabled]), button:not([disabled]), [tabindex='0']");
                modalElement.firstFocusableEl = focusableEls[0];
                modalElement.lastFocusableEl = focusableEls[ focusableEls.length - 1 ];
            }
        },
        focusFirstDescendant = function () {
            if (modalElement.loading) {
                //if loading indicator modal- trap focus so can't tab out
                modalElement.focus();
            } else {
                setFocusableElements(); 
                if (focusableEls.length > 0)  {
                    //if there is something to focus
                    focusableEls[0].focus();
                }
            }
        },
        handleKeyDown = function(e) {

            if (!modalElement.loading) {
                setFocusableElements();
                
                var KEY_TAB = 9;
                var KEY_ESC = 27;

                // For loading modal which has no focusable elements
                if(focusableEls.length == 0){
                    e.preventDefault();
                }

                switch(e.keyCode) {
                case KEY_TAB:
                    if ( focusableEls.length === 1 ) {
                        e.preventDefault();
                        break;
                    }
                    if ( e.shiftKey ) {
                        handleBackwardTab(e);
                    } else {
                        handleForwardTab(e);
                    }
                    break;
                case KEY_ESC: self.hide(); break;
                default:
                    break;
                }
            }
        },
        handleBackwardTab = function (e) {
            if ( document.activeElement === modalElement.firstFocusableEl ) {
                e.preventDefault();
                modalElement.lastFocusableEl.focus();
            }
        },
        handleForwardTab = function (e) {
            if ( document.activeElement === modalElement.lastFocusableEl ) {
                e.preventDefault();
                modalElement.firstFocusableEl.focus();
            }
        },
        createOverlay = function() {

            if (overlay && !(overlay.classList.contains(prefix + "show"))) {
                overlayDelay = getTransitionDurationFromElement(overlay);
                overlay.classList.add(prefix + "show");
                overlay.removeAttribute("hidden");
            } else {
                console.warn("MODAL: Overlay requested. Corresponding HTML missing. Please apply id 'dds__full-screen-overlay' and class 'dds__overlay' to an empty div");
            }

            if (!options.static) {
                modalElement.addEventListener("click", function(e) {
                    if (e.target != modalContent && !modalContent.contains(e.target)) {
                        self.hide();
                    }
                }, false);
            }
            DOC.body.style.paddingRight = (scrollbarWidth + origRightPadding) + "px";
            DOC.body.style.overflow = "hidden";
            DOC.documentElement.style.overflow = "hidden";
        },
        removeOverlay = function () {
            if (overlay) {
                overlay.classList.remove(prefix + "show");
                overlay.setAttribute("hidden","");
            }
            
            if (!options.static) {
                modalElement.removeEventListener("click", function(e) {
                    if (e.target == modalElement) {
                        self.hide();
                    }
                }, false);
            }
            if (origRightPadding == 0) {
                DOC.body.style.paddingRight = "";
            } else {
                DOC.body.style.paddingRight = origRightPadding + "px";
            }
            DOC.body.style.overflow = "";
            if (DOC.body.style.length == 0) {
                DOC.body.removeAttribute("style");
            }
            DOC.documentElement.style.overflow = "";
            if (DOC.documentElement.style.length == 0) {
                DOC.documentElement.removeAttribute("style");
            }
            uicoreCustomEvent("Modal", "Hidden", modalElement);
        },
        keydownHandlerToggle = function () {
            if (modalElement.classList.contains(prefix + "show")) {
                DOC.addEventListener("keydown", keyHandler, false);
            } else {
                DOC.removeEventListener("keydown", keyHandler, false);
            }
        },
        triggerShow = function () {
            //resizeHandlerToggle();
            keydownHandlerToggle();
            modalElement.focus ? modalElement.focus() : modalElement.setActive();
            focusFirstDescendant();
            uicoreCustomEvent("Modal", "Shown", modalElement);
        },
        triggerHide = function () {
            setTimeout( function() {
                modalElement.style.display = "";
            }, getTransitionDurationFromElement(modalElement));
            element && element.focus ? element.focus() : element.setActive();
        
            if (modalElement.classList.contains(prefix + "show")) {
                keydownHandlerToggle();
            }
        },
        // handlers
        clickHandler = function (e) {
            if (e["target"] === element && !(modalElement.classList.contains(prefix + "show"))) {
                self.show();
                e.preventDefault();
            }
        },
        keyHandler = function (e) {
            if (self.keyboard && e.which == 27 && modalElement.classList.contains(prefix + "show")) {
                self.hide();
            }
        },
        dismissHandler = function (e) {
            if (modalElement.classList.contains(prefix + "show")) {
                self.hide();
                e.preventDefault();
            }
        };

    // public methods
    this.toggle = function () {
        if (modalElement.classList.contains(prefix + "show")) {
            this.hide();
        } else {
            this.show();
        }
    };
    this.show = function () {
        uicoreCustomEvent("Modal", "ShowEvent", modalElement);

        createOverlay();

        modalElement.style.display = "block";

        setTimeout( function() {
            modalElement.classList.add(prefix + "show");
        }, 5);
        modalElement.setAttribute("aria-hidden", false);
        modalElement.classList.contains(prefix + "fade")
            ? emulateTransitionEnd(modalElement, triggerShow)
            : triggerShow();

        supportTransitions && modalElement.loading ? overlayDelay : 0;
    };
    this.hide = function () {
        uicoreCustomEvent("Modal", "HideEvent", modalElement);

        modalElement.classList.remove(prefix + "show");
        modalElement.setAttribute("aria-hidden", true);
        
        setTimeout( function() {
            removeOverlay();
        }, getTransitionDurationFromElement(modalElement));
        
        modalElement.classList.contains(prefix + "fade")
            ? emulateTransitionEnd(modalElement, triggerHide)
            : triggerHide();
        supportTransitions && overlay ? overlayDelay : 0;
    };

    //init
    if (!(stringModal in element)) {
        if (!modalElement) {
            return false;
        } else {
            origRightPadding = DOC.body.style.paddingRight ? DOC.body.style.paddingRight.split("px")[0] : 0;
            scrollbarWidth = window.innerWidth - DOC.documentElement.clientWidth;
            modalElement.loading = modalElement.classList.contains(prefix + "loading-modal") ? true : false;
        
            if (!modalElement.loading) {
                element.addEventListener("click", clickHandler, false);
                modalElement.addEventListener("keydown", handleKeyDown);
                var dissmissEls = modalElement.querySelectorAll("[data-dismiss='"+prefix+"modal']");
                for(var i =0; i < dissmissEls.length; i++) {
                    dissmissEls[i].addEventListener("click", dismissHandler, false);
                }
            }
        }
    }

    element[stringModal] = self;
}
import { prefix, getSibling, isIE } from "../utilities";

export default function InputMask( element ) { //, opts ) {
    // initialization element
    element = element instanceof HTMLElement ? element : (function() {
        return false;
    })();

    var wrap,
        stringInputMask = "InputMask",
        mNum = "XdDmMyY9",
        mChar = "_",
        spacing = 0,
        createShell = function(t) {
            wrap = document.createElement("span");
            spacing = !t.getAttribute("set-cursor") ? spacing : isNaN(parseInt(t.getAttribute("set-cursor"))) ? spacing : parseInt(t.getAttribute("set-cursor")) !=1 ? spacing : 1;
            var mask = document.createElement("span"),
                emphasis = document.createElement("i"),
                tClass = t.getAttribute("class"),
                pTxt = t.getAttribute("placeholder"),
                placeholder = document.createTextNode(pTxt),
                errorDiv = getSibling(t, "#"+t.getAttribute("id")+"Feedback");
        
            t.setAttribute("maxlength", placeholder.length);
            t.setAttribute("data-placeholder", pTxt);
            t.removeAttribute("placeholder");
        
        
            if ( !tClass || ( tClass && tClass.indexOf("masked") === -1 ) ) {
                t.setAttribute( "class", tClass + " masked");
            }
        
            mask.setAttribute("aria-hidden", "true");
            mask.setAttribute("id", t.getAttribute("id") + "Mask");
            mask.appendChild(emphasis);
            mask.appendChild(placeholder);
        
            wrap.classList.add(prefix + "input-shell");
            wrap.appendChild(mask);
            t.parentNode.insertBefore( wrap, t );
            wrap.appendChild(t);
            if(errorDiv) {
                wrap.appendChild(errorDiv);
            }
        },
        setValueOfMask = function(e) {
            var value = e.target.value,
                placeholder = e.target.getAttribute("data-placeholder");
  
            return "<i>" + value + "</i>" + placeholder.substr(value.length);
        },
        handleFocusEvent = function(e) {
            for (var s=0; s<spacing; s++) {
                var char = e.target.getAttribute("data-placeholder").charAt(s);
                element.value += char;
                var evt;
                if (!isIE) {
                    evt = new KeyboardEvent("keyup", {
                        bubbles : true,
                        cancelable : true,
                        char : char,
                        key : "[",
                        shiftKey : true
                    });
                } else {
                    evt = document.createEvent("KeyboardEvent");
                    evt.initKeyboardEvent("keyup", true, true, null, char, null, "", false, "" );
                }
                element.dispatchEvent(evt);
            }
        },
        handleValueChange = function(e) {
            var id = e.target.getAttribute("id"),
                mask = wrap.querySelector("#" + id + "Mask"),
                italic = wrap.querySelector("#" + id + "Mask i");
  
            if(e.target.value == italic.innerHTML) {
                return; // Continue only if value hasn't changed
            }
  
            e.target.value = handleCurrentValue(e);
            mask.innerHTML = setValueOfMask(e);
  
        },
  
        handleCurrentValue = function(e) {
            var isCharsetPresent = e.target.getAttribute("data-charset"),
                placeholder = isCharsetPresent || e.target.getAttribute("data-placeholder"),
                value = e.target.value, l = placeholder.length, newValue = "",
                i, j, isInt, isLetter, strippedValue;
  
            // strip special characters
            strippedValue = isCharsetPresent ? value.replace(/\W/g, "") : value.replace(/\D/g, "");
  
            for (i = 0, j = 0; i < l; i++) {
                isInt = !isNaN(parseInt(strippedValue[j]));
                isLetter = strippedValue[j] ? strippedValue[j].match(/[A-Z]/i) : false;
                var matchesNumber = mNum.indexOf(placeholder[i]) >= 0;
                var matchesLetter = mChar.indexOf(placeholder[i]) >= 0;
                if ((matchesNumber && isInt) || (isCharsetPresent && matchesLetter && isLetter)) {
                    newValue += strippedValue[j++];
                } else if ((!isCharsetPresent && !isInt && matchesNumber) || (isCharsetPresent && ((matchesLetter && !isLetter) || (matchesNumber && !isInt)))) {
                    return newValue;
                } else {
                    newValue += placeholder[i];
                }
                // break if no characters left and the pattern is non-special character
                if (strippedValue[j] == undefined) {
                    break;
                }
            }
            if (e.target.getAttribute("data-valid-example")) {
                return validateProgress(e, newValue);
            }
            return newValue;
        },
  
        validateProgress = function(e, value) {
            var validExample = e.target.getAttribute("data-valid-example"),
                pattern = new RegExp(e.target.getAttribute("pattern")),
                placeholder = e.target.getAttribute("data-placeholder"),
                l = value.length, testValue = "";
  
            //convert to months
            if (l == 1 && placeholder.toUpperCase().substr(0,2) == "MM") {
                if(value > 1 && value < 10) {
                    value = "0" + value;
                }
                return value;
            }
            // test the value, removing the last character, until what you have is a submatch
            for (var i = l; i >= 0; i--) {
                testValue = value + validExample.substr(value.length);
                if (pattern.test(testValue)) {
                    return value;
                } else {
                    value = value.substr(0, value.length-1);
                }
            }
  
            return value;
        };

    // add event listeners
    // init
    if (!(stringInputMask in element)) {
        var parentNode = element.parentNode;

        if ( !parentNode || !parentNode.classList.contains(prefix + "input-shell") ) {
            createShell(element);
            element.addEventListener("keyup", handleValueChange, false);
            if (spacing > 0) {
                element.addEventListener("focus", handleFocusEvent, false);
            }
        }
    }

    element[stringInputMask] = self;
}
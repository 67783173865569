export default function Progress(element, options) {
    element = element instanceof HTMLElement ? element : (function () {
        return false;
    })();

    // set options
    options = options || {};

    // this defines how chunky you want the increment block to be the smaller the increment, the smoother it is,
    // however, it will impact minimum time to complete depending on client's machine's performance
    var increment = 0.25,
        showText = options.showText || element["getAttribute"]("data-showtext") === "true" || false,
        timeToComplete = options.timeToComplete || element["getAttribute"]("data-timetocomplete") || 10000,
        millisecondPerTick = timeToComplete / (100.0 / increment),
        width = 0,
        id,
        stringProgress = "Progress",
        frame = function() {
            if (width < 100.0) {
                width += increment;
                element.style.width = width + "%";
                element.setAttribute("aria-valuenow", Math.round(width));
            } else {
                element.style.width = "100%";
                clearInterval(id);
            }
            if (showText) {
                element.innerHTML = width < 100 ? Math.round(width) + "%" : "100%";
            }
        };

    // init
    if (!(stringProgress in element)) {
        setInterval(frame, millisecondPerTick);
    }

    element[stringProgress] = self;
}

import Dropdown from "./dropdown.js";
import {prefix, debounce} from "./utilities.js";

export default function Footer(element) {
    // initialization element
    element = element instanceof HTMLElement ? element : (function() {
        return false;
    })();

    // bind, target alert, duration and stuff
    var self = this,
        stringFooter = "Footer",
        countrySelectorBtn,
        smallMedia = window.matchMedia("(max-width: 767.98px)"),
        marginTopInit,
        marginTopNew,
   

        positionCalculation = function() {
            element.style.marginTop = marginTopInit;
            setTimeout(function () {
                if (!smallMedia.matches && document.body.clientHeight < window.innerHeight) {
                    element.style.position = "absolute";
                }
                else {
                    element.style.position = "relative";
                    if (smallMedia.matches) {
                        resizeHandler();
                        window.addEventListener("resize", resizeHandler);
                    } else {
                        window.removeEventListener("resize", resizeHandler);
                    }
                }
            }, 50);
        },
        resizeHandler = debounce(
            function () {
                marginTopInit = parseFloat(getComputedStyle(element).marginTop);
                marginTopNew = window.innerHeight - document.body.clientHeight + marginTopInit - 1;
                if (marginTopNew > 0) {
                    element.style.marginTop = marginTopNew + "px";
                } 
            }
            , 25);     

    // init
    if (!(stringFooter in element)) { 
        positionCalculation();
        resizeHandler();
        countrySelectorBtn = element.querySelector("." + prefix + "footer-icon-ctnr");
        if(countrySelectorBtn) {
            new Dropdown(countrySelectorBtn); 
        }
        smallMedia.addListener(positionCalculation);
    }

    element[stringFooter] = self;
}
import { DOC } from "./utilities";

export default function SkipNav(element) {

    // initialization element
    element = element instanceof HTMLElement ? element : (function() {
        return false;
    })();

    var self = this,
        stringSkipNav = "SkipNav",
        hiddenZindex = "-1000",
        visibleZindex = "1080",
        tabindex,
        borderStyle,
        dataInset,
   
        // handlers
        focusInHandler = function(e) {
            if (element === e.target || element.contains(e.target)) {
                element.style.zIndex = visibleZindex;
                element.style.opacity = "1";
                [].forEach.call(element.querySelectorAll("a.dds__btn"), function(button) {
                    button.addEventListener("click", clickHandler, false);
                });
            }
            element.addEventListener("focusout", focusOutHandler);
        },

        focusOutHandler = function(e) {
            var target = e.relatedTarget ? e.relatedTarget : DOC.activeElement;
            if (!element.contains(target) && !(element === target)) {
                toggleZindex();
            }
        },    
        
        toggleZindex = function() {
            element.style.zIndex = hiddenZindex;
            element.style.opacity = "0";
            [].forEach.call(element.querySelectorAll("a.dds__btn"), function(button) {
                button.removeEventListener("click", clickHandler, false);
            });
            element.removeEventListener("focusout", focusOutHandler);

        },

        clickHandler = function(e) {
            // element.style.zIndex = hiddenZindex;
            toggleZindex();
            var focusElement = document.getElementById(e.target.getAttribute("href").substr(1));
            tabindex = focusElement.getAttribute("tabIndex");
            if (tabindex !== 0) {
                focusElement.setAttribute("tabIndex", "0"); 
            } 
            focusElement.addEventListener("focusout", blurHandler, false);
            setTimeout( function() {
                focusElement.focus();
            }, 100); //firefox needs a little timeout to register tabindex
            if (e.target.getAttribute("data-inset") === "true") { //only add special border if pass in this attr
                dataInset = true;
                borderStyle = focusElement.style.border;
                setTimeout( function() {  
                    focusElement.style.border = "2px inset #000000";
                }, 10);
            } else {
                dataInset = false;
            }
        },

        blurHandler = function(e) { 
            if (dataInset) {
                e.target.style.border = borderStyle;
            }
            if (tabindex !== 0) {
                tabindex == null ? e.target.removeAttribute("tabIndex") : e.target.setAttribute("tabIndex", tabindex);
            }
            e.target.removeEventListener("focusout", blurHandler, false); //once one blur event occurs, we want to remove the event listener
        };

    // init
    if (!(stringSkipNav in element)) {
        // prevent adding event handlers twice
        element.addEventListener("focusin", focusInHandler);
        // window.addEventListener("keyup", keydownZindexHandler);
        // var focusableEls = document.querySelectorAll("a[href], area[href], input:not([disabled]), select:not([disabled]), textarea:not([disabled]), button:not([disabled]), [tabindex='0']");
        // console.log(focusableEls[0]);
        // focusableEls[0].addEventListener("keyup", keydownZindexHandler);
        // if using Mac, keyboard accessbility  will only work if you set up this setting: https://stackoverflow.com/questions/11704828/how-to-allow-keyboard-focus-of-links-in-firefox
        // On Mac: In System Preferences → Keyboard, in the Shortcuts pane, check the “all controls” radio at the bottom.
        // Safari on Mac: additionally, go to safari preferences-> Advanced -> Accessibility "Press Tab to highlight each item on a webpage"
        // Firefox on Mac: additionally, type "about:config" in the URL bar. There is no accessibility.tabfocus preference on the mac, so you'll have to make one. Right click in the window, create a new "integer" pref, and set it to 7.
    }


    element[stringSkipNav] = self;
}
import { DOC, isObject, isArray, isJson, each, createElement, getClosest, prefix, classRemove, classAdd, getSibling, uicoreCustomEvent } from "./utilities.js";
import Pagination from "./pagination.js";
import Columns from "./helpers/columns.js";
import Rows from "./helpers/rows.js";
import Dropdown from "./dropdown.js";

export default function TableComplex(element, params) {
    element = element instanceof HTMLElement ? element : (function() {
        return;
    })();

    function validateRow(row, name) {
        var hasErrors = "";
        var rowCount = row.match(/\{((\w*|\d*):?)+\}/g);
        if (rowCount.length < 1 || rowCount.length > 3) {
            hasErrors += "Custom layout for row "+name+" was delared with an incorrect set of elements!\n";
        }
        var rowLength = 0;
        var localFunctions = ["placeholder","actions","settings","search"];
        each(rowCount, function(temp) {
            var subElements = temp.replace(/\{|\}/g, "").split(":");
            if (subElements.indexOf("placeholder") === -1 && !temp.match(/\{[A-Z,a-z]+:[1,2,3](:(start|center|end))*\}/g) ) {
                hasErrors += "Custom layout for row "+name+" has an element "+ temp +", which has been declared incorrectly!\n";
            }
            if (layoutOptions.indexOf(subElements[0]) === -1 && !window[subElements[0]] && localFunctions.indexOf(subElements[0]) < 0) { // does custom function exist
                hasErrors += "Custom layout for row "+name+" has an element with custom function '"+ subElements[0] +"' that is not accessible or dosen't exist!\n";
            }
            if (!isNaN(subElements[1].substring(0,1))) {
                rowLength += +subElements[1].substring(0,1);
            }
        });
        if (rowLength != 3) {
            hasErrors += "Custom layout for row "+name+" is configured with grid length '"+ rowLength +"', which needs to add up to '3'!\n";
        }
        return hasErrors;
    }

    var jsonParams = element.dataset.tableData;
    if (jsonParams) {
        params = JSON.parse(jsonParams);
    }

    // init options
    var options = {};
    var layoutOptions = ["{actions:1:start}","{search:1:center}","{settings:1:end}"];
    
    // Check to see what Javascript options are set or are missing from table element classes
    options.sort = typeof params.sort === "boolean" ? params.sort : false;
    options.expand = typeof params.expand === "boolean" ? params.expand : false;
    options.condensed = typeof params.condensed === "boolean" ? params.condensed : false;
    options.fixedColumns = typeof params.fixedColumns === "boolean" ? params.fixedColumns : false;
    options.fixedHeight = typeof params.fixedHeight === "boolean" ? params.fixedHeight : false;
    options.header = typeof params.header === "boolean" ? params.header : true;

    // Remove the data from the Table element
    options.showData = jsonParams && typeof params.showData === "boolean" ? params.showData : true;
    // Search is either on by default or can be truned off
    options.search = typeof params.search === "boolean" ? params.search : true;
    // Settings is either on by default or can be turned off
    options.settings = typeof params.settings === "boolean" ? params.settings : true;
    // Import either on by default or can be turned off
    options.import = typeof params.import === "boolean" ? params.import : true;
    // Printing is either on by default or can be turned off
    options.print = typeof params.print === "boolean" ? params.print : true;
    // Column Management is either on by default or can be turned off
    options.column = typeof params.column === "boolean" ? params.column : true;
    // Bulk Actions
    options.actionsSelectFilters = ["Export as csv","Export as json","hr","Delete"];
    if (params.additionalActions && typeof params.additionalActions === "object") {
        each(params.additionalActions, function(additionalAction) {
            if (additionalAction.js) {
                var testJs = additionalAction.js;
                if (additionalAction.js.indexOf("(") > -1) {
                    testJs = additionalAction.js.substring(0, additionalAction.js.indexOf("(")).trim();
                } else {
                    additionalAction.js += "()";
                }
                if (!window[testJs]) {
                    throw new Error("The additional action function "+additionalAction.js+" could not be accessed, please verify and try again!");
                } else {
                    options.actionsSelectFilters.splice(additionalAction.pos, 0, additionalAction);
                }
            } else {
                options.actionsSelectFilters.splice(additionalAction.pos, 0, additionalAction);
            }
        });
    }
    options.select = typeof params.select === "boolean" ? params.select : true;
    if (!options.select) {
        layoutOptions.splice(layoutOptions.indexOf("{actions:1:start}"),1,"{placeholder:1:start}");
    }
    if (!options.search) layoutOptions.splice(layoutOptions.indexOf("{search:1:center}"),1,"{placeholder:1:center}");
    if (!options.settings) layoutOptions.splice(layoutOptions.indexOf("{settings:1:end}"),1,"{placeholder:1:end}");
    // Customise the layout
    if (params.layout) {
        var rowCount = 0;
        var hasErrors = "";
        if (params.layout.row1) {
            hasErrors += validateRow(params.layout.row1, "one");
            rowCount++;
        }
        if (params.layout.row2) {
            hasErrors += validateRow(params.layout.row2, "two");
            rowCount++;
        }
        if (rowCount == 0) {
            hasErrors += "Custom layout was configured to have no Rows, which it needs to have at least 1!\n";
        }
        if (hasErrors.length > 0) {
            throw new Error(hasErrors);
        }
    }
    options.layout = params.layout ? params.layout : {row2:layoutOptions.join("")};

    options.perPage = params.perPage && typeof params.perPage === "number" ? params.perPage : 12;
    options.origPerPage = options.perPage,
    options.items = params.items && typeof params.items === "number" ? params.items : 0;

    // transfer data
    options.data = {};
    if(!params.data) {
        var msg = "Missing data parameters for table!";
        console.error(msg);
        uicoreCustomEvent("Table", "Error", table, {"msg": msg});
        return false;
    }
    if (!params.data.headings) {
        msg = "Missing data.headings parameters for table!";
        console.error(msg);
        uicoreCustomEvent("Table", "Error", table, {"msg": msg});
        return false;
    }
    options.data.headings = params.data.headings;
    if (!params.data.columns) {
        msg = "Missing data.columns parameters for table!";
        console.error(msg);
        uicoreCustomEvent("Table", "Error", table, {"msg": msg});
        return false;
    }
    options.data.columns = params.data.columns;
    if (!params.data.rows) {
        msg = "Missing data.rows parameters for table!";
        console.error(msg);
        uicoreCustomEvent("Table", "Error", table, {"msg": msg});
        return false;
    }
    options.data.rows = params.data.rows;
    
    options.labels = {
        noRows: "No entries found", // Message shown when there are no search results
    };

    if (!options.showData) {
        delete element.dataset.tableData;
    }

    var self = this,
        stringTable = "Table",
        table = element,
        body,
        head,
        header,
        wrapper,
        container,
        bottom,
        columnBox,
        isIE,
        pages,
        currentPage,
        totalPages,
        paginationElement,
        pagination,
        columnRenderers,
        selectedColumns,
        labels,
        rect,
        input,
        searching,
        dragSrcEl,
        dragMediaSize = window.matchMedia("(max-width: 575.98px)"),
        sortedColumnIdx,
        metHeight = true,
        batchId,
        // Handlers
        handleDragEvent = function(e) {
            e.stopPropagation();
            if (e.type === "dragstart") {
                dragSrcEl = e["target"];

                dragSrcEl.dataTransfer = {};
                dragSrcEl.dataTransfer.data = dragSrcEl.outerHTML;
            }
            if (e.type === "dragover") {
                var overEl = e["target"];
                if (overEl.tagName != "LI" ) {
                    overEl = getClosest(overEl, "." + prefix + "table-cmplx-drag-li");
                }
                if (dragSrcEl != overEl) {
                    if (e.preventDefault) {
                        e.preventDefault(); // Necessary. Allows us to drop.
                    }
                    overEl.classList.add("over");
                    if (overEl.previousElementSibling && overEl.previousElementSibling.classList.contains("over")) {
                        overEl.previousElementSibling.classList.remove("over");
                    }
                    if (overEl.nextElementSibling && overEl.nextElementSibling.classList.contains("over")) {
                        overEl.nextElementSibling.classList.remove("over");
                    }
                    return false;
                }
               
            }
              
            if (e.type === "dragleave") {
                
                var leaveEl = e["target"];
                if (leaveEl.tagName != "LI" ) {
                    leaveEl = getClosest(leaveEl, "." + prefix + "table-cmplx-drag-li");
                }
                if (dragSrcEl != leaveEl) {
                    if (e.preventDefault) {
                        e.preventDefault(); // Necessary. Allows us to drop.
                    }
                    
                    leaveEl.classList.remove("over");
                    return false;
                }
                
            }
              
            if (e.type === "drop") {
              
                if (e.stopPropagation) {
                    e.stopPropagation(); // Stops some browsers from redirecting.
                }
              
                var dropTarget = e["target"];    
                if (dropTarget.tagName != "LI") {
                    dropTarget = getClosest(dropTarget, "." + prefix + "table-cmplx-drag-li");
                }
                // Don't do anything if dropping the same column we're dragging.
                if (dragSrcEl != dropTarget) {
                    var checked = dragSrcEl.querySelector("input").checked;
                    var dropHTML = dragSrcEl.dataTransfer.data;
                    dragSrcEl.parentNode.removeChild(dragSrcEl);
                    
                    dropTarget.insertAdjacentHTML("afterend",dropHTML);
                    var dropElem = dropTarget.nextSibling;
                    if (checked) {
                        dropElem.querySelector("input").checked = true;
                    }
                    setupDragEvents(dropElem);
                    dropTarget.classList.remove("over");
                    columnBox.orderChanged = true;
                    var appluButton = columnBox.querySelector("."+prefix+"btn-primary");
                    // eanble Apply and Cancel
                    if (appluButton.disabled) {
                        appluButton.removeAttribute("disabled");
                    }
                }
                return false;
            }
        },
        handleImportActionEvent = function(e) {
            var t = e.target;
            var reader = new FileReader();
            var output = ""; //placeholder for text output
            var type = ""; //placeholder for file type
            if(t.files && t.files[0]) {
                if (!t.files[0].type || 
                    t.files[0].type === "application/json") {
                    type = "json";
                } else if(t.files[0].type && 
                    (t.files[0].type === "application/vnd.ms-excel" ||
                    t.files[0].type === "text/csv")) {
                    type = "csv";
                } else {
                    var msg = "The file type for the file selected was not recognizable!";
                    console.error(msg);
                    uicoreCustomEvent("Table", "Error", table, {"msg": msg});
                }     
                reader.onload = function (e) {
                    output = e.target.result;
                    if(type === "json") {
                        if(!isJson(output)) {
                            msg = "The file type for the supplied file was not recognizable!";
                            console.error(msg);
                            uicoreCustomEvent("Table", "Error", table, {"msg": msg});
                        }
                    }
                    var inputOptions = {type: type,
                        data: output,
                        headings: true};
                    self.import(inputOptions);
                    t.value = "";
                    
                };//end onload()
                reader.readAsText(t.files[0]);
            }//end if html5 filelist support
            e.preventDefault();
        },
        handleExportActionEvent = function(t) {
            var exportFileOptions = { };
            if (options.expand && options.select) {
                exportFileOptions.skipColumn = [0,1];
            } else if (options.expand || options.select) {
                exportFileOptions.skipColumn = [0];
            }
            if (t.innerHTML.match(/csv/)) {
                exportFileOptions.type = "csv";
            } else {
                exportFileOptions.type = "json";
            }
            self.export(exportFileOptions);
        },
        handleDeleteActionEvent = function() {
            self.delete();
        },
        handleSelectAllChange = function(state) {
            if (options.select) {
                var actions = wrapper.querySelector("[data-target='#cmplxTablesActions']");
                if (state === "checked" || state === "indeterminate" && actions.disabled) {
                    actions.removeAttribute("disabled");
                } else if (state === "unchecked" && !actions.disabled) {
                    actions.setAttribute("disabled","");
                }
            }
        },
        handleCheckBoxEvent = function(t) {
            if(options.select && t.classList.contains(prefix + "table-cmplx-select-all")) { // Header Select All
                if (table.hasRows) {
                    var cellIdx = getHeaderCellIndex(prefix + "table-cmplx-select-all");
                    var check = t.checked;
                    if (!check || t.state === "indeterminate") {
                        check = false;
                        t.checked = false;
                        t.state = "unchecked";
                    } else {
                        t.state = "checked";
                        check = true;
                    }
                    var page = pages[currentPage-1];
                    each(page, function(row) {
                        if(row.cells.length != 1) {
                            var cell = row.cells[cellIdx];
                            var input = cell.querySelector("input[type='checkbox']");
                            var dataInput = table.data[row.dataIndex].cells[cellIdx].querySelector("input[type='checkbox']");

                            if (check) {
                                dataInput.checked = input.checked = true;
                                table.selectedRows.push(row.dataIndex);
                                row.classList.add("selected");
                                if (options.expand) {
                                    row.nextElementSibling.classList.add("selected");
                                }
                                t.state = "checked";
                            } else {
                                dataInput.checked = input.checked = false;
                                table.selectedRows.pop(row.dataIndex);
                                row.classList.remove("selected");
                                if (options.expand) {
                                    row.nextElementSibling.classList.remove("selected");
                                }
                            }
                        }
                    });
                    page.selected = t.checked;
                    handleSelectAllChange(t.state);
                } else {
                    t.checked = false;
                }
            } else if(options.select && t.parentNode.classList.contains(prefix + "table-cmplx-row-select")) { // Table Cell Select
                cellIdx = getHeaderCellIndex(prefix + "table-cmplx-select-all");
                var tr = getClosest(t, "tr");
                if (t.checked) {
                    table.data[tr.dataIndex].cells[cellIdx].querySelector("input[type='checkbox']").checked = true;
                    tr.classList.add("selected");
                    if (options.expand) {
                        tr.nextElementSibling.classList.add("selected");
                    }
                    if (table.selectedRows.length === 0) {
                        table.selectedRows.push(tr.dataIndex);
                    } else {
                        var newRows = [];
                        for (var idx = 0; idx < table.selectedRows.length; idx++) {
                            
                            var value = table.selectedRows[idx];
                            var less = table.selectedRows[idx-1];
                            var more = table.selectedRows[idx+1];
                            if (tr.dataIndex < value 
                                && (less === undefined || tr.dataIndex > less)
                                && newRows.indexOf(tr.dataIndex) === -1) {
                                newRows.push(tr.dataIndex);
                                newRows.push(value);
                            } else if (tr.dataIndex > value 
                                && (more === undefined || tr.dataIndex < more)
                                && newRows.indexOf(tr.dataIndex) === -1) {
                                newRows.push(value);
                                newRows.push(tr.dataIndex);
                            } else {
                                newRows.push(value);
                            }
                        }
                        if (newRows.length > 0) {
                            table.selectedRows = newRows;
                        }
                    }
                } else {
                    table.data[tr.dataIndex].cells[cellIdx].querySelector("input[type='checkbox']").checked = false;
                    tr.classList.remove("selected");
                    if (options.expand) {
                        tr.nextElementSibling.classList.remove("selected");
                    }
                    table.selectedRows = table.selectedRows.filter( function(value) {
                        if (value != tr.dataIndex) {
                            return "" + value;
                        }
                    });
                    each(table.activeHeadings[cellIdx].children, function(el) {
                        if (el && el.classList.contains(prefix + "table-cmplx-select-all")) {
                            el.checked = false;
                        }
                    });
                }
                var selectAll = wrapper.querySelector("." + prefix + "table-cmplx-select-all");
                if (table.selectedRows.length === options.perPage || table.selectedRows.length === pages[currentPage-1].length) {
                    selectAll.indeterminate = false;
                    selectAll.state = "checked";
                    selectAll.checked = true;
                } else if (table.selectedRows.length > 0) {
                    selectAll.state = "indeterminate";
                    selectAll.indeterminate = true;
                } else {
                    selectAll.state = "unchecked";
                    selectAll.indeterminate = false;
                }
                handleSelectAllChange(selectAll.state);
            }
        },
        handleButtonEvent =  function(t) {
            if (t.innerHTML === "Cancel") {
                columnBox.classList.remove(prefix + "show");
            }
            else if (t.innerHTML === "Apply") {
                if(columnBox.orderChanged) {
                    var order = [];
                    // Make sure we pick up cells for Expandable, Selectable and Labels
                    for (var h = 0; h < baseCellIdx(); h++) {
                        order.push(h);
                    }
                    each (columnBox.getElementsByTagName("ul"), function(unOderedList) {
                        each(unOderedList.children, function(li) {
                            each(table.headings, function(header) {
                                if(li.querySelectorAll("label")[0].textContent === header.textContent) {
                                    order.push(header.originalCellIndex);
                                }
                            });
                        });
                    });
                    columnBox.orderChanged = false;
                    columns().order(order);
                    self.update();
                }
                
                each (columnBox.getElementsByTagName("ul"), function(unOderedList) {
                    each(unOderedList.children, function(li, idx) {
                        if (li.querySelector("input[type='checkbox']").checked) {
                            columns().show([baseCellIdx()+idx]);
                            self.update();

                        } else {
                            columns().hide([baseCellIdx()+idx]);
                            self.update();
                        }
                    });

                    if (searching) {
                        self.search(input.value);
                    }
                });
                renderHeader();
                columnBox.classList.remove(prefix + "show");
            }
        },
        handleTableHeaderEvent = function(e,t) {
            if (
                options.sort &&
                t.hasAttribute("data-sortable")
            ) {
                columns().sort(table.activeHeadings.indexOf(t));
                sortedColumnIdx = t.originalCellIndex;
                self.update();
                e.preventDefault();
            }
            t.focus();
        },
        handleAccordionEvent = function(t) {
            if(t.classList.contains(prefix + "table-cmplx-accordion-btn")) {
                var htmlRow = t.parentNode.parentNode,
                    currentRow = table.data[htmlRow.dataIndex];
                if (currentRow.details) {
                    var detailsRow = htmlRow.nextElementSibling.children[0];
                    if (detailsRow.classList.contains(prefix + "show")) {
                        detailsRow.classList.remove(prefix + "show");
                        t.classList.add(prefix + "collapsed");
                        t.setAttribute("aria-expanded",false);
                    } else {
                        detailsRow.classList.add(prefix + "show");
                        t.classList.remove(prefix + "collapsed");
                        t.setAttribute("aria-expanded",true);
                    }
                } else {
                    var svg = t.querySelector("svg");
                    each(svg.childNodes, function(use) {
                        if (use.getAttribute("class").indexOf(prefix + "show") > -1) {
                            classRemove(use, prefix + "show");
                            if (use.getAttribute("xlink:href").indexOf(prefix + "loading-sqrs") != -1) {
                                var row = getClosest(use, "tr");
                                table.activeRows[row.dataIndex].event = "ExpandCancelEvent";
                                uicoreCustomEvent("Table", "ExpandCancelEvent", table, {"rowId": row.dataIndex});
                            }
                        } else {
                            classAdd(use, prefix + "show");
                            if (use.getAttribute("xlink:href").indexOf(prefix + "loading-sqrs") != -1) {
                                row = getClosest(use, "tr");
                                table.activeRows[row.dataIndex].event = "ExpandStartEvent";
                                var content = [];
                                each(table.data[row.dataIndex].cells, function(cell, idx) {
                                    if (options.expand && options.select) {
                                        if (idx >= 2) {
                                            var dt = "\""+labels[idx]+"\": \""+ ( (cell.hasAttribute("data-content") ? cell.getAttribute("data-content") : cell.textContent )+"\"" );
                                            content.push(dt);
                                        }
                                    } else if (options.expand ||  options.select) {
                                        if (idx >= 1) {
                                            dt = "\""+labels[idx]+"\": \""+ ( (cell.hasAttribute("data-content") ? cell.getAttribute("data-content") : cell.textContent )+"\"" );
                                            content.push(dt);
                                        }
                                    } else {
                                        dt = "\""+labels[idx]+"\": \""+ ( (cell.hasAttribute("data-content") ? cell.getAttribute("data-content") : cell.textContent )+"\"" );
                                        content.push(dt);
                                    }
                                });
                                uicoreCustomEvent("Table", "ExpandStartEvent", table, {"rowId": row.dataIndex, "content": content});
                            }
                        }
                    });
                }
            }
        },
        updateSelectedRows = function() {
            var newRows = [];
            var page = pages[currentPage-1];
            each(page, function(tr) {
                if (tr.classList.contains("selected")) {
                    newRows.push(tr.dataIndex);
                }
            });
            if (newRows.length > 0) {
                table.selectedRows = [];
                table.selectedRows = newRows;
            } else {
                table.selectedRows = [];
            }
        },
        setupDragEvents = function(el) {
            el.addEventListener("dragstart", handleDragEvent, false);
            el.addEventListener("dragover", handleDragEvent, false);
            el.addEventListener("dragleave", handleDragEvent, false);
            el.addEventListener("drop", handleDragEvent, false);
        },
        extend = function (src, props) {
            for (var prop in props) {
                if (Object.prototype.hasOwnProperty.call(props, prop)) {
                // if (props.hasOwnProperty(prop)) {
                    var val = props[prop];
                    if (val && isObject(val)) {
                        src[prop] = src[prop] || {};
                        extend(src[prop], val);
                    } else {
                        src[prop] = val;
                    }
                }
            }
            return src;
        },
        baseCellIdx = function() {
            var baseIdx = 0;
            if (table.expand) {
                baseIdx++;
            }
            if (table.select) {
                baseIdx++;
            }
            return baseIdx;
        },
        columns = function (columns) {
            return new Columns(table, columns);
        },
        rows = function (rows) {
            return new Rows(table, rows);
        },
        flush = function (el, ie) {
            if (el instanceof NodeList) {
                each(el, function (e) {
                    flush(e, ie);
                });
            } else {
                if (ie) {
                    while (el.hasChildNodes()) {
                        el.removeChild(el.firstChild);
                    }
                } else {
                    el.innerHTML = "";
                }
            }
        },
        // Function used to convert options data to thead and tbody
        dataToTable = function() {
            var thead = false,
                tbody = false;

            table.data = table.data || options.data;

            if (table.data.headings) {
                thead = createElement("thead");
                var tr = createElement("tr");

                // Add the expandable header for row details
                if (options.expand) {
                    var th = createElement("th", {
                        title: "expand details"
                    });
                    tr.appendChild(th);
                    table.expand = options.expand;
                }
                if (options.select) {
                    th = createElement("th");
                    th.appendChild(createElement("input", {
                        type: "checkbox",
                        class: prefix + "table-cmplx-select-all",
                        title: "select all rows"
                    }));
                    tr.appendChild(th);
                    table.select = options.select;
                }
                each(table.data.headings, function (col) {
                    var th = createElement("th", {
                        scope: "col",
                        html: col
                    });
                    tr.appendChild(th);
                });
                thead.appendChild(tr);
            }

            if (table.data.rows && table.data.rows.length) {
                tbody = createElement("tbody");
               
                each(table.data.rows, function (row, idx) {
                    row.dataIndex = idx;
                    if (table.data.headings) {
                        if (options.data.headings.length !== row.data.length) {
                            throw new Error(
                                "Row found at index, [ "+idx+" ] that does not match the number of headings supplied."
                            );
                        }
                    }

                    var tr = createElement("tr");
                    //Add secondary accordion arrow for details
                    if(options.expand) {
                        tr.appendChild(renderExpand());
                    }
                    //Add selectable checkboxes
                    if(options.select) {
                        tr.appendChild(renderSelect());
                    }
                    each(row.data, function (value) {
                        var td = createElement("td", {
                            html: value
                        });
                        td.setAttribute("data-content", value);
                        tr.appendChild(td);
                    });
                    
                    tbody.appendChild(tr);

                    if (row.details) {
                        tr.details = row.details;
                    }

                    // Secondary accordion html for details
                    if (options.expand){
                        tbody.appendChild(renderDetails(tr, table.headings.length));
                    }
                    
                });
            }

            if (thead) {
                if (table.tHead !== null) {
                    table.removeChild(table.tHead);
                }
                table.appendChild(thead);
            }

            if (tbody) {
                if (table.tBodies.length) {
                    table.removeChild(table.tBodies[0]);
                }
                table.appendChild(tbody);
            }
        },
        renderDetails = function(row, length, stripeClass, selected) {

            var clazz = prefix + "table-cmplx-row-details";
            if (stripeClass) {
                clazz = stripeClass + " " + clazz;
            }
            if (selected) {
                clazz += " " + "selected";
            }
           
            var detailsRow = createElement("tr", {
                class: clazz
            });
            var detailsCell = createElement("td", {
                class :  prefix + "table-complx-details",
                colspan: length
            });
            var accordionBody = createElement("div",{
                class : prefix + "table-complx-details-body"
            });
            if (row.details) {
                accordionBody.innerHTML = row.details;
            } else {
                row.details = false;
            }
            detailsCell.appendChild(accordionBody);
            detailsRow.appendChild(detailsCell);
            return detailsRow;
        },
        renderExpand = function() {
            var td = createElement("td");
            var showButton = createElement("button", {
                    class: prefix + "table-cmplx-accordion-btn " + prefix + "collapsed", 
                    "aria-expanded": false,
                    "aria-label": "expand details"
                }),
                svgElem = renderSvg([{name:"arrow-tri-solid-right", show:true},{name:"loading-sqrs", show:false}]);
            showButton.appendChild(svgElem);
            td.appendChild(showButton);
            return td;
        },
        renderSelect = function() {
            var td = createElement("td", {
                class: prefix + "table-cmplx-row-select"
            });
            var inputCheckbox = createElement("input", {
                type: "checkbox",
                title: "row select"
            });
            td.appendChild(inputCheckbox);
            return td;
        },
        renderCustomRow = function(row) {
            var template = "<div class='" + prefix + "table-cmplx-top'>";
            template += row;
            template += "</div>";

            var elements = row.match(/\{((\w*|\d*):?)+\}/g);

            each(elements, function(el) {
                var replace = el;
                var clean = el.replace(/[{,}]/g, "").split(":");
                //if (layoutOptions.indexOf(clean[0]) === -1) {
                // if (layoutOptions.indexOf(replace) === -1) {
                //     var topCtnr = "<div class='" + prefix + "table-cmplx-top-cntr " + prefix + "justify-content-" + clean[2] + " " + prefix + "flex-grow-" + clean[1] + "'>";
                //     topCtnr += window[clean[0]]()+"</div>";
                //     template = template.replace(replace, topCtnr);
                // } else {
                switch(clean[0]) {
                case "actions": template = template.replace(replace, renderActions(clean[1], clean[2])); break;
                case "search": template = template.replace(replace, renderSearch(clean[1], clean[2])); break;
                case "settings": template = template.replace(replace, renderSettings(clean[1], clean[2])); break;
                case "placeholder": template = template.replace(replace, renderPlaceholder(clean[1])); break;
                default : 
                    var topCtnr = "<div class='" + prefix + "table-cmplx-top-cntr " + prefix + "justify-content-" + clean[2] + " " + prefix + "flex-grow-" + clean[1] + "'>";
                    topCtnr += window[clean[0]]()+"</div>";
                    template = template.replace(replace, topCtnr);
                    
                }
            });
            return template;
        },
        createActionButton = function(html, js) {
            var button = createElement("button", {
                class: prefix + "dropdown-item",
                role :"menuitem",
                onclick: js ? js : "javascript: void(0);",
                html: html,
                tabindex: "-1"
            });
            return button;
        },
        createActionLi = function () {
            return createElement("li", {
                class: prefix + "dropdown-list-item",
                role: "none presentation",
                tabindex: "0"
            });
        },
        renderActions = function(flexGrow, justifyContent) {
            var topCtnr;
            if (options.select) {
                topCtnr = "<div class='" + prefix + "table-cmplx-top-cntr " + prefix + "justify-content-" + justifyContent +"'";
                flexGrow ? topCtnr += " style='flex-grow: " + flexGrow + ";'>" :  +">";
                var cmplxAction = createElement("div", {
                    class: prefix + "table-cmplx-action"
                });
                var cmplxActionLabel = createElement("label", {
                    class: prefix + "table-cmplx-action-label",
                    html: "Batch Actions",
                    for: batchId+"BatchActions"
                });
                cmplxAction.appendChild(cmplxActionLabel);

                var cmplxActionDropdown = createElement("div", {
                    class: prefix + "mb-0 " + prefix + "btn-dropdown"
                });
                var anchor = createElement("button", {
                    id: batchId+"BatchActions",
                    class: prefix + "btn " + prefix + "btn-secondary " + prefix + "table-cmplx-action-button" ,
                    tabindex: "0",
                    "data-toggle": prefix + "dropdown",
                    "data-target": "#cmplxTablesActions",
                    "aria-expanded": "false",
                    "aria-controls": "cmplxTablesActions",
                    html: "Choose Action "
                });
                
                var svgElem = renderSvg([{name:"arrow-tri-solid-right", show:true}]);
                var svgClass= svgElem.getAttribute("class");
                svgElem.setAttribute("class", svgClass + " " + prefix + "arrow-tri-solid-right");
                anchor.appendChild(svgElem);
                cmplxActionDropdown.appendChild(anchor);

                var unordered = createElement("ul", {
                    id: "cmplxTablesActions",
                    class: prefix + "button-dropdown-container " + prefix + "collapse",
                    role: "menu"
                });

                each(options.actionsSelectFilters, function(action) {
                    if ("hr" === action) {
                        unordered.appendChild(createElement(action,{class: prefix+"dropdown-divider"}));
                    } else {
                        var exportCsvLI = createActionLi();
                        var exportCsvLabel;
                        if (typeof action === "string") {
                            exportCsvLabel = createActionButton(action);
                        } else {
                            exportCsvLabel = createActionButton(action.html, action.js);
                        }
                        exportCsvLI.appendChild(exportCsvLabel);
                        unordered.appendChild(exportCsvLI);
                    }
                });
                
                anchor.setAttribute("disabled","");

                cmplxActionDropdown.appendChild(unordered);
                cmplxAction.appendChild(cmplxActionDropdown);

                topCtnr += cmplxAction.outerHTML + "</div>";
            } else {
                topCtnr = "<div class='" + prefix + "table-cmplx-top-cntr'></div>";
            }

            return topCtnr;
        },
        renderSearch = function(flexGrow, justifyContent) {
            var topCtnr;
            if (options.search) {
                topCtnr = "<div class='" + prefix + "table-cmplx-top-cntr " + prefix + "justify-content-" + justifyContent +"'";
                flexGrow ? topCtnr += " style='flex-grow: " + flexGrow + ";'>" :  +">";
                var searchCtnr = createElement("div", {
                    class: prefix + "table-cmplx-search"
                });
                var searchIcon = renderSvg([{name:"search", show:true}]);
                var searchInput = createElement("input", {
                    type: "search",
                    class: prefix + "form-control ",
                    "aria-label": "search"
                });
                searchCtnr.appendChild(searchInput);
                searchCtnr.appendChild(searchIcon);
                topCtnr += searchCtnr.outerHTML + "</div>";
            } else {
                topCtnr = "<div class='" + prefix + "table-cmplx-top-cntr'></div>";
            }

            return topCtnr;
        },
        renderSvg = function(svg) {
        
            var svgWrapper = DOC.createElementNS("http://www.w3.org/2000/svg", "svg");
            classAdd(svgWrapper, prefix + "icon-svg");
            svgWrapper.setAttribute("focusable","false");
            svgWrapper.setAttribute("aria-hidden","true");
            if (!isArray(svg)) {
                svg = [svg];
            }
            each(svg, function(elm) {
                var svgElem = DOC.createElementNS("http://www.w3.org/2000/svg", "use");
        
                svgElem.setAttributeNS("http://www.w3.org/1999/xlink", "xlink:href", "#" + prefix + elm.name);
                classAdd(svgElem, [prefix + "icon-svg-item"]);
                if (elm.show) {
                    classAdd(svgElem, [ prefix + "show"]);
                }

                svgWrapper.appendChild(svgElem);
            });
            return svgWrapper;
        },
        renderSettings = function(flexGrow, justifyContent) {
            var topCtnr;
            if (options.settings) {
                topCtnr = "<div class='" + prefix + "table-cmplx-top-cntr " + prefix + "justify-content-" + justifyContent +"'";
                flexGrow ? topCtnr += " style='flex-grow: " + flexGrow + ";'>" :  +">";
                var optGear = "<div class='" + prefix + "table-cmplx-settings'>";
                
                if (options.import) {
                    optGear += "<button class='"+prefix+"table-cmplx-settings-button' aria-label='table settings import'>";
                    var svgElem = renderSvg([{name:"inbox-arrow-down", show:true}]);
                    optGear += svgElem.outerHTML || new XMLSerializer().serializeToString(svgElem);
                    optGear += "</button>";
                    optGear += "<input class='dds__table-cmplx-file-import' type='file' hidden/>";
                }
                if (options.print) {
                    optGear += "<button class='"+prefix+"table-cmplx-settings-button' aria-label='table settings print'>";
                    svgElem = renderSvg([{name:"printer", show:true}]);
                    optGear += svgElem.outerHTML || new XMLSerializer().serializeToString(svgElem);
                    optGear += "</button>";
                }
                if (options.column) {
                    optGear += "<button class='"+prefix+"table-cmplx-settings-button' aria-label='table settings columns'>";
                    svgElem = renderSvg([{name:"gear", show:true}]);
                    optGear += svgElem.outerHTML || new XMLSerializer().serializeToString(svgElem);
                    optGear += "</button>";
                }
                
                optGear += columnBox = "<div class=\"" + prefix + "column-box";
                optGear += "\" data-toggle=\"data-column-box\"></div>";
                optGear += "</div>";
                topCtnr += optGear + "</div>";
            } else {
                topCtnr = "<div class='" + prefix + "table-cmplx-top-cntr'></div>";
            }

            return topCtnr;
        },
        renderPlaceholder = function (flexGrow) {
            return "<div class='" + prefix + "table-cmplx-top-cntr " + prefix + "table-cmplx-placeholder' style='flex-grow: " + flexGrow + ";'></div>";
        },
        render = function() {
 
            var template = "";

            // Convert data to HTML
            if (options.data) {
                dataToTable(options.data);
            }

            body = table.tBodies[0];
            head = table.tHead;
    
            // Should move this to datatoTable method
            if (!body) {
                body = createElement("tbody");
                table.appendChild(body);
            }
    

            table.hasRows = body.rows.length > 0;
    
            // Make a tHead if there isn't one (fixes #8)
            // Should move this to dataToTable method
            if (!head) {
                var h = createElement("thead");
                var t = createElement("tr");
    
                if (table.hasRows) {
                    each(body.rows[0].cells, function () {
                        t.appendChild(createElement("th"));
                    });
    
                    h.appendChild(t);
                }
    
                head = h;
    
                table.insertBefore(head, body);
            }
    
            table.hasHeadings = head.rows.length > 0;
    
            if (table.hasHeadings) {
                header = head.rows[0];
                table.headings = [].slice.call(header.cells);
            }
    
            // Header
            if (!options.header) {
                if (head) {
                    table.removeChild(table.tHead);
                }
            }
    
            // Build
            wrapper = createElement("div", {
                class: prefix + "table-cmplx-wrapper"
            });
    
            //////////////////////////////////////////////////////
            // Top template - Begin
            //////////////////////////////////////////////////////
   
            if (options.layout.row1) {
                template += renderCustomRow(options.layout.row1);
            }

            if (options.layout.row2) {
                template += renderCustomRow(options.layout.row2);
            }

            //////////////////////////////////////////////////////
            // Top template - End
            //////////////////////////////////////////////////////

            template += "<div class='" + prefix + "table-cmplx-container'></div>";
            
            //////////////////////////////////////////////////////
            // Bottom template - Begin
            //////////////////////////////////////////////////////

            template += "<div class='" + prefix + "table-cmplx-bottom'>";
            template += "</div>";
    
            //////////////////////////////////////////////////////
            // Bottom template - End
            //////////////////////////////////////////////////////

            if (table.hasHeadings) {
                // Sortable
                renderHeader();
            }
    
            // Add table class
            table.classList.add(prefix + "table-cmplx");
            
            wrapper.innerHTML = template;
    
            container = wrapper.querySelector("." + prefix + "table-cmplx-container");

            bottom = wrapper.querySelector("." + prefix + "table-cmplx-bottom");
    
            // Pagination
            options.pagination = getSibling(element, ".dds__pagination") ? true : false;
            if (options.pagination) {
                paginationElement = getSibling(element, ".dds__pagination");
                bottom.appendChild(paginationElement);
            }

            columnBox = wrapper.querySelector("[data-toggle='data-column-box']");
    
            // Insert in to DOM tree
            table.parentNode.replaceChild(wrapper, table);
            container.appendChild(table);
    
            // Store the table dimensions
            rect = table.getBoundingClientRect();
    
            // Convert rows to array for processing
            table.data = [];
            table.activeRows = [];

            var dataIdx = 0;
            each(body.rows, function(row, idx) {
                if (options.expand) {
                    if (idx % 2 == 0) {
                        row.dataIndex = dataIdx++;
                        table.activeRows.push(row);
                        table.data.push(row);
                    }
                } else {
                    row.dataIndex = idx;
                    table.activeRows.push(row);
                    table.data.push(row);
                }
            }, true);

            table.activeHeadings = table.headings.slice();

            // Update
            self.update();
    
            // Set Columns
            setColumns();

            if (options.fixedHeight) {
                if (options.origPerPage > table.activeRows.length) {
                    metHeight = false;
                } else {
                    // Fix height
                    fixHeight();
                }
            }
    
            // Fix columns
            fixColumns();

            // Options condensed
            if (options.condensed) {
                wrapper.querySelector("." + prefix + "table-cmplx").classList.add(prefix + "condensed");
            }
    
            bindEvents();
        },
        renderPage = function () {

            if (table.hasRows && totalPages) {
    
                // Use a fragment to limit touching the DOM
                var index = currentPage - 1,
                    frag = DOC.createDocumentFragment();
    
                var page = pages[index];
                var selectHeader;
                var selectCellIdx;
                
                if (table.hasHeadings) {
                    flush(header, isIE);
                    
                    each(table.activeHeadings, function (th) {
                        if (options.select) {
                            each(th.children, function(el) {
                                if (el && el.classList.contains(prefix + "table-cmplx-select-all")) {
                                    selectHeader = el;
                                    selectCellIdx = th.originalCellIndex;
                                    if(page.selected) {
                                        selectHeader.checked = true;
                                        selectHeader.state = "checked"; // set the state
                                    } else {
                                        selectHeader.checked = false;
                                        selectHeader.state = "unchecked"; // set the state
                                    }
                                } 
                            });
                        }
                        // reest sortable columns
                        if (th.hasAttribute("data-sortable") && th.originalCellIndex != sortedColumnIdx) {
                            th.setAttribute("aria-sort", "none");
                        }
                        header.appendChild(th);
                    }, this);
                }
                
                var selectedRows = 0;
                each(page, function (row, idx) {
                    row.className = "";
                    var selected = false;
                    // redo if previously selected
                    if (options.select) {
                        if(table.data[row.dataIndex].cells[selectCellIdx].querySelector("input[type='checkbox']").checked) {
                            row.classList.add("selected");
                            selected = true;
                            selectedRows++;
                            if (!selectHeader.checked) {
                                selectHeader.indeterminate = true;
                                selectHeader.state = "indeterminate"; // set the state
                            }
                        }
                    }
                    // redo if stripped table
                    var stripeColor = idx % 2 > 0 ? prefix + "table-cmplx-row-odd" : prefix + "table-cmplx-row-even";
                    row.classList.add(stripeColor);
                    frag.appendChild(rows().render(row));
                    if(options.expand) {
                        row.querySelector("." + prefix + "table-cmplx-accordion-btn").classList.add(prefix + "collapsed");
                        var detailsRow = renderDetails(row, table.headings.length, stripeColor, selected);
                        detailsRow.children[0].classList.remove(prefix + "show");
                        frag.appendChild(detailsRow);
                    }
                }, this);

                if (options.select) {
                    if (selectedRows === page.length) {
                        selectHeader.indeterminate = false;
                        selectHeader.checked = true;
                        selectHeader.state = "checked";
                    } else if (selectedRows === 0 && options.select) {
                        selectHeader.indeterminate = false;
                        selectHeader.checked = false;
                        selectHeader.state = "unchecked";
                    }
                    handleSelectAllChange(selectHeader.state);
                }
                self.clear(frag);
    
            } else if (searching && totalPages == 0) {
                if (table.hasHeadings) {
                    flush(header, isIE);
    
                    each(table.activeHeadings, function (th) {
                        header.appendChild(th);
                    }, this);
                }
            } else {
                self.clear();
            }
            if(!metHeight) {
                fixHeight();
            }
        },
        renderHeader = function () {
    
            labels = [];
    
            if (table.headings && table.headings.length) {

                each(table.headings, function (th, i) {
    
                    labels[i] = th.textContent;
    
                    th.sortable = th.hasAttribute("data-sortable");
    
                    th.originalCellIndex = i;
                    if (options.sort && th.sortable) {
                        var sortLabel = createElement("label", {
                            class: prefix + "table-cmplx-sorter",
                            html: th.textContent
                        });
                        th.textContent = "";
                        th.setAttribute("tabindex","0");
                        th.appendChild(sortLabel);
                    }
                });
            }
    
            fixColumns();
        },
        getHeaderCellIndex = function(selector) {
            var cellIdx = 0;
            each(table.activeHeadings, function(header) {
                each(header.children, function(el) {
                    if (el.classList.contains(selector)) {
                        cellIdx = header.originalCellIndex;
                    }
                });
            });
            return cellIdx;
        },
        bindEvents = function () {

            // Batch Actions
            if (options.select) {
                var actionSelect = wrapper.querySelector("#cmplxTablesActions");
                actionSelect.addEventListener("keydown", function(e) {
                    if (e.keyCode == 13) {
                        e.preventDefault();
                        var actionButton = e.target.querySelector("button");
                        if (actionButton.innerHTML === "Delete") {
                            handleDeleteActionEvent();
                        } else if (actionButton.innerHTML === "Export as csv" || actionButton.innerHTML === "Export as json") {
                            handleExportActionEvent(e.target);
                        }
                    }
                });
                actionSelect.addEventListener("mousedown", function(e) {
                    e.preventDefault();
                    var actionButton = e.target;
                    if (actionButton.innerHTML === "Delete") {
                        handleDeleteActionEvent();
                    } else if (actionButton.innerHTML === "Export as csv" || actionButton.innerHTML === "Export as json") {
                        handleExportActionEvent(e.target);
                    }
                });
            } 

            // Search
            if (options.search) {
                input = wrapper.querySelector("." + prefix + "table-cmplx-search").querySelector("input[type=search]");
                if (input) {
                    input.addEventListener("keyup",function (e) {
                        self.search(input.value);
                        e.preventDefault();
                    }, false);
                    input.addEventListener("mouseup", function (e) {
                        setTimeout ( function() {
                            self.search(input.value);
                        }, 100);
                        e.preventDefault();
                    }, false);
                }
            }
    
            // Settings
            if (options.settings) {
                var settingsCtnr = wrapper.querySelector("." + prefix + "table-cmplx-settings");
                if (options.column) {
                    var button = settingsCtnr.querySelector("button[aria-label='table settings columns']");
                    button.addEventListener("click", function(e) {
                        if(columnBox.classList.contains(prefix + "show")) {
                            columnBox.classList.remove(prefix + "show");
                        } else {
                            renderSettingsDropDown();
                            columnBox.classList.add(prefix + "show");
                            columnBox.addEventListener("click", function(e) {
                                var t = e.target;
                                if (t.nodeName.toLowerCase() === "button") {
                                    handleButtonEvent(t);
                                    settingsCtnr.querySelector("button[aria-label='table settings columns']").focus();
                                } else if(t.nodeName.toLowerCase() === "input") {
                                    var applyButton = columnBox.querySelector("."+prefix+"btn-primary");
                                    if (applyButton.disabled) {
                                        applyButton.removeAttribute("disabled");
                                    }
                                }
                            });
                        }
                        e.preventDefault();
                    }, false);
                }
                
                if (options.print) {
                    button = settingsCtnr.querySelector("button[aria-label='table settings print']");
                    button.addEventListener("click", function(e) {
                        self.print();
                        e.preventDefault();
                    }, false);
                }

                if (options.import) {
                    var fileImport = settingsCtnr.querySelector("."+prefix+"table-cmplx-file-import");
                    fileImport.addEventListener("change", handleImportActionEvent, false);
                    
                    button = settingsCtnr.querySelector("button[aria-label='table settings import']");
                    button.addEventListener("click", function(e) {
                        fileImport.click();
                        e.preventDefault();
                    }, false);
                }
            }        
    
            // All listeners with in the table are should use wrapper.
            // Pager(s) / sorting
            wrapper.addEventListener("keydown" ,function (e) {
                var t = e.target;
                // Checks for headers
                if (t.nodeName.toLowerCase() === "th" && e.keyCode === 13) { 
                    handleTableHeaderEvent(e,t);
                }
                // Checks for buttons
                if (t.nodeName.toLowerCase() === "button" && e.keyCode === 13) {
                    handleAccordionEvent(t);
                    e.preventDefault();
                }
            }, false);

            wrapper.addEventListener("click" ,function (e) {
                var t = e.target;
                // Checks for headers
                if (t.classList.contains(prefix+"table-cmplx-sorter")) { 
                    handleTableHeaderEvent(e,t.parentNode); 
                }
                // Checks for buttons
                if (t.nodeName.toLowerCase() === "button") {
                    handleAccordionEvent(t);
                    e.preventDefault();
                }
                // Checks for inputs
                if (t.nodeName.toLowerCase() === "input") {
                    if(t.type === "checkbox") {
                        handleCheckBoxEvent(t);
                    }
                }
            }, false);
        },
        setColumns = function () {
            each(table.data, function (row) {
                each(row.cells, function (cell) {
                    cell.data = cell.innerHTML;
                });
            });
            
           
            // Check for the columns option
            if (options.data.columns && table.headings.length) {
    
                each(options.data.columns, function (data) {
                   
                    // convert single column selection to array
                    if (!isArray(data.select)) {
                        data.select = [data.select];
                    }
    
                    if (Object.prototype.hasOwnProperty.call(data, "render") && typeof data.render === "function") {
                    // if (data.hasOwnProperty("render") && typeof data.render === "function") {
                        selectedColumns = selectedColumns.concat(data.select);
    
                        columnRenderers.push({
                            columns: data.select,
                            renderer: data.render
                        });
                    }
    
                   
                    // Add the data attributes to the th elements
                    each(data.select, function (column) {
                        var th = table.headings[baseCellIdx()+column];
                        if (data.type) {
                            th.setAttribute("data-type", data.type);
                        }
                        if (data.format) {
                            th.setAttribute("data-format", data.format);
                        }
                        if (options.sort) {
                            if (Object.prototype.hasOwnProperty.call(data, "sortable") && data.sortable) {
                            // if (data.hasOwnProperty("sortable") && data.sortable) {
                                th.setAttribute("data-sortable", "");
                                th.setAttribute("aria-sort","none");
                            }
                            if (Object.prototype.hasOwnProperty.call(data, "sort")) {
                            // if (data.hasOwnProperty("sort")) {
                                th.setAttribute("data-sortable", "");
                                th.setAttribute("aria-sort","none");
                            }
                        }
                        if (Object.prototype.hasOwnProperty.call(data, "fixed")) {
                        // if (data.hasOwnProperty("fixed")) {
                            th.setAttribute("data-fixed", data.fixed);
                        }
                        if (Object.prototype.hasOwnProperty.call(data, "hidden")) {
                        // if (data.hasOwnProperty("hidden")) {
                            if (data.hidden) {
                                columns().hide([baseCellIdx()+column]);
                                self.update();
                            }
                        }
                        if (Object.prototype.hasOwnProperty.call(data, "sort") && data.select.length ==1 ) {
                        // if (data.hasOwnProperty("sort") && data.select.length === 1) {
                            sortedColumnIdx = baseCellIdx()+data.select[0];
                            columns().sort(sortedColumnIdx, data.sort);
                            self.update();
                        }
                    });
                });
            }
    
            if (table.hasRows) {
                each(table.data, function (row, idx) {
                    row.dataIndex = idx;
                    each(row.cells, function (cell) {
                        cell.data = cell.innerHTML;
                    });
                });
    
                if (selectedColumns.length) {
                    each(table.data, function (row) {
                        each(row.cells, function (cell, i) {
                            if (selectedColumns.indexOf(i) > -1) {
                                each(columnRenderers, function () {
                                    if (options.data.columns.indexOf(i) > -1) {
                                        cell.innerHTML = options.renderer.call(self, cell.data, cell, row);
                                    }
                                });
                            }
                        });
                    });
                }
                
                columns().rebuild();
                self.update();
            }

            renderHeader();
            
        },
        renderSettingsDropDown = function() {

            flush(columnBox, isIE);
            var columnContainer = createElement("div", {
                class: prefix + "table-cmplx-column-cntr"
            });

            var unOderedList = createElement("ul");
        
            each(table.headings, function(header, idx) {

                if (idx >= baseCellIdx()) {
                    var listItem;
                    var dragSvg;
                    if (!header.dataset["fixed"] && !dragMediaSize.matches) {
                        listItem = createElement("li", {
                            class: prefix + "table-cmplx-drag-li",
                            draggable: true
                        });
                        dragSvg = renderSvg([{name:"handle", show:true}]);
                        setupDragEvents(listItem);
                    } else {
                        listItem = createElement("li", {
                            class: prefix + "table-cmplx-li"
                        });
                    }

                    var columnInput = createElement("input",{   id: idx,
                        type: "checkbox"
                    });
                    columnInput.checked = columns().visible(idx);
                    var columnLabel = createElement("label",{ for: idx });
                    var boxLabel = createElement("span");
                    boxLabel.innerHTML = header.textContent;
                    columnLabel.appendChild(columnInput);
                    columnLabel.appendChild(boxLabel);
                    if(dragSvg) {
                        listItem.appendChild(dragSvg);
                    }
                    listItem.appendChild(columnLabel);
                    unOderedList.appendChild(listItem);
                    columnContainer.appendChild(unOderedList);
                }
            });
            var buttonContainer = createElement("div", {
                class: prefix + "row " + prefix + "no-gutters"
            });
            var cancelButton = createElement("button", {
                class: prefix + "btn " + prefix + "btn-secondary",
                html: "Cancel"
            });
            var applyButton = createElement("button", {
                class: prefix + "btn " + prefix + "btn-primary",
                html: "Apply",
                disabled: ""
            });
            buttonContainer.appendChild(cancelButton);
            buttonContainer.appendChild(applyButton);
            columnContainer.appendChild(buttonContainer);
            columnBox.appendChild(columnContainer);
        },
        paginate = function() {

            var perPage = options.perPage,
                rows = table.activeRows;
                
            if (searching) {
                rows = [];
    
                each(table.searchData, function (index) {
                    rows.push(table.activeRows[index]);
                }, this);
            }
    
            // Check for hidden columns
            pages = rows
                .map(function (tr, i) {
                    return i % perPage === 0 ? rows.slice(i, i + perPage) : null;
                })
                .filter(function (page) {
                    return page;
                });
    
            totalPages = pages.length;
        },
        fixColumns = function () {

            if (options.fixedColumns && table.activeHeadings && table.activeHeadings.length) {
                var cells,
                    hd = false,
                    reducePx = 0;
    
                // If we have headings we need only set the widths on them
                // otherwise we need a temp header and the widths need applying to all cells
                if (table.tHead) {
                    each(table.activeHeadings, function (cell) {
                        cell.style.maxWidth = "";
                        cell.style.width = "";
                    });
                    var expandDone, selectDone = false;
                    each(table.activeHeadings, function (cell, i) {
                        if ((i==0) && options.expand && !expandDone){
                            expandDone = true;
                            cell.style.maxWidth = "20px";
                            cell.style.width = "20px";
                            reducePx += 20;
                        }
                        else if ((i==0||i==1) && options.select && !selectDone){
                            selectDone = true;
                            cell.style.maxWidth = "20px";
                            cell.style.width = "20px";
                            reducePx += 20;
                        }
                        else {
                            var ow = cell.offsetWidth;
                            var w = ow / (rect.width - reducePx) * 100;
                            cell.style.width = w + "%";
                        }
                    });
                } else {
                    cells = [];
    
                    // Make temperary headings
                    hd = createElement("thead");
                    var r = createElement("tr");
                    var c = table.tBodies[0].rows[0].cells;
                    each(c, function () {
                        var th = createElement("th");
                        r.appendChild(th);
                        cells.push(th);
                    });
    
                    hd.appendChild(r);
                    table.insertBefore(hd, body);
    
                    var widths = [];
                    each(cells, function (cell, i) {
                        if ((i==0) && options.expand){
                            reducePx += 20;
                        }
                        else if((i==0||i==1) && options.select){
                            reducePx += 20;
                        } 
                        else {
                            var ow = cell.offsetWidth;
                            var w = ow / (rect.width - reducePx) * 100;
                            widths.push(w);
                        }
                    }, this);
    
                    each(table.data, function (row) {
                        each(row.cells, function (cell, i) {
                            if((i==0) && options.expand){
                                cell.style.width = "20px";
                            }
                            else if ((i==0||i==1) && options.select){
                                cell.style.width = "20px";
                            }
                            else if (columns().visible(baseCellIdx()+cell.cellIndex))
                                cell.style.width = widths[i] + "%";
                        }, this);
                    }, this);
    
                    // Discard the temp header
                    table.removeChild(hd);
                }
            }
        },
        fixHeight = function () { 
            if (options.fixedHeight) {
                container.style.height = null;
                rect = container.getBoundingClientRect();
                container.style.height = rect.height + "px";
            }
            metHeight = options.origPerPage <= table.activeRows.length && options.perPage == options.origPerPage;
        },
        resetCollapse = function() {
            each(table.activeRows, function(row) {
                var button = row.querySelector("."+ prefix + "table-cmplx-accordion-btn");
                if(button && !button.classList.contains(prefix + "collapsed")) {
                    button.classList.add(prefix + "collapsed");
                }
            });
        },
        insert = function (data) {
            var newrows = [];
            if (isObject(data)) {
                if (data.headings) {
                    if (!table.hasHeadings && !table.hasRows) {
                        var tr = createElement("tr"),
                            th;
                        each(data.headings, function (heading) {
                            th = createElement("th", {
                                html: heading
                            });

                            tr.appendChild(th);
                        });
                        head.appendChild(tr);

                        header = tr;
                        table.headings = [].slice.call(header.cells);
                        table.hasHeadings = true;

                        // Re-enable sorting if it was disabled due
                        // to missing header
                        // options.sort = initialSortable;
                    }
                }

                if (data.rows && isArray(data.rows)) {
                    each(data.rows, function (row, rowIdx) {
                        var headerCount = [];
                        for (var l = 0; l < labels.length; l++) {
                            headerCount.push(l);
                        }
                        var padR = 0,
                            r = [];
                        if (options.expand) {
                            r[0] = renderExpand().outerHTML;
                            padR++;
                            delete headerCount[0];
                        }
                        if (options.select) {
                            var selectData = renderSelect().outerHTML;
                            if (options.expand) {
                                r[1] = selectData;
                                padR++;
                                delete headerCount[1];
                            } else {
                                r[0] = selectData;
                                padR++;
                            }
                        }
                        each(row, function (cell, idx) {
                            var index;
                            if(data.headings) {
                                index = labels.indexOf(data.headings[idx]);
                                delete headerCount[index];
                            } else {
                                index = idx;
                            }
                            
                            if (index > -1) {
                                r[index] = cell;
                            }
                        });
                        for (var z = 0; z < headerCount.length; z++) {
                            if (headerCount[z]) {
                                r[headerCount[z]] = "";
                            }
                        }
                        if (r.length == (data.headings ? labels.length : (padR + labels.length))) {
                            newrows.push(r);
                        } else {
                            var msg = "Row found at index, [ "+rowIdx+" ] that did not match the current headers.";
                            console.error(msg);
                            uicoreCustomEvent("Table", "Error", table, {"msg": msg});
                        }
                    });
                }
            }

            if (newrows.length) {
                if(data.details) {
                    rows().add(newrows, data.details);
                } else {
                    rows().add(newrows);
                }
                columns().rebuild();
                if(!searching) {
                    pagination.addItems(newrows.length);
                } else {
                    var query = wrapper.querySelector("input[type='search']").value;
                    self.search(query);
                }
                table.hasRows = true;
            }

            fixColumns();
            
            self.update();

            if (!metHeight) {
                fixHeight();
            }
        };

    if (!options.header) {
        options.sort = false;
    }

    if (table.tHead === null) {
        if (!options.data ||
            (options.data && !options.data.headings)
        ) {
            options.sort = false;
        }
    }

    if (table.tBodies.length && !table.tBodies[0].rows.length) {
        if (options.data) {
            if (!options.data.rows) {
                throw new Error(
                    "You seem to be using the data option, but you've not defined any rows."
                );
            }
        }
    }

    /**
     * Update the instance
     * @return {Void}
     */
    this.update = function() {
        // Remove class that was set from setMessage if applicable
        wrapper.classList.remove(prefix + "table-cmplx-empty");

        paginate();
        
        renderPage();

        table.sorting = false;

        rows().update();

        if (table.hasRows) {
            updateSelectedRows();
            table.setAttribute("aria-rowcount", table.activeRows.length);
        } else {
            table.setAttribute("aria-rowcount", 0);
        }

        table.setAttribute("aria-colcount", table.activeHeadings.length);

        uicoreCustomEvent("Table", "UpdateEvent", table);
    };

    /**
     * Perform a search of the data set
     * @param  {string} query
     * @return {void}
     */
    this.search = function(query) {
        if (!table.hasRows) return false;
        
        resetCollapse();
        
        query = query.toLowerCase();

        currentPage = 1;
        searching = true;
        table.searchData = [];

        if (!query.length) {
            searching = false;
            this.update();
            pagination.setItems(table.activeRows.length);
            uicoreCustomEvent("Table", "SearchEvent", table, { "query": query, "searchData": table.searchData});
            wrapper.classList.remove("search-results");

            return false;
        }

        this.clear();

        each(table.data, function (row, idx) {
            var inArray = table.searchData.indexOf(row) > -1;

            var doesQueryMatch = query.split(" ").reduce(function (bool, word) {
                
                var includes = false,
                    cell = null,
                    content = null;

                for (var x = baseCellIdx(); x < row.cells.length; x++) {
                    cell = row.cells[x];
                    content = cell.hasAttribute("data-content") ? cell.getAttribute("data-content") : cell.textContent;
                    if (
                        content.toLowerCase().indexOf(word) > -1 &&
                        columns().visible(cell.cellIndex)
                    ) {
                        includes = true;
                        break;
                    }
                }

                return bool && includes;
            }, true);

            if (doesQueryMatch && !inArray) {
                row.searchIndex = idx;
                table.searchData.push(idx);
            } else {
                row.searchIndex = null;
            }
        }, this);

        wrapper.classList.add("search-results");
        if (!table.searchData.length) {
            wrapper.classList.remove("search-results");
            this.setMessage(options.labels.noRows);
        } else {
            this.update();
        }
        pagination.setItems(table.searchData.length);
        uicoreCustomEvent("Table", "SearchEvent", table, {"query": query, "searchData": table.searchData});
    };

    /**
     * Change page
     * @param  {int} page
     * @return {void}
     */
    this.page = function(page) {
        
        var newPage;

        if (!isNaN(page)) {
            newPage = parseInt(page, 10);    
        } else {
            newPage = page;
        }

        if (newPage == currentPage || newPage > pages.length || newPage < 0) {
            return false;
        }

        currentPage = newPage;

        renderPage();

        updateSelectedRows();

        uicoreCustomEvent("Table", "PageChangedEvent", table, { "currentPage": currentPage});
    };

    this.perPage = function(detail) {
        
        var newPerPage, newPage, newPages;
        if (!isNaN(detail.perPage)) {
            newPerPage = parseInt(detail.perPage, 10);
        } else {
            newPerPage = detail.perPage;
        }
        if (!isNaN(detail.page)) {
            newPage = parseInt(detail.page, 10);
        } else {
            newPage = detail.page;
        }
        if (!isNaN(detail.pages)) {
            newPages = parseInt(detail.pages, 10);
        } else {
            newPages = detail.perPage;
        }

        if (options.perPage != newPerPage) {
            options.perPage = newPerPage;
            totalPages = newPages;
            currentPage = newPage;

            this.update();
            
            if(!metHeight) {
                fixHeight();
            }
        }
    };

    /**
     * Refresh the instance
     * @return {void}
     */
    this.refresh = function () {

        var that = this;
        if (options.search) {
            input.value = "";
            searching = false;
        }
        currentPage = 1;
        that.update();

        uicoreCustomEvent("Table", "RefreshEvent", table);
    };

    /**
     * Truncate the table
     * @param  {mixes} html - HTML string or HTMLElement
     * @return {void}
     */
    this.clear = function (html) {
        
        if (body) {
            flush(body, isIE);
        }

        var parent = body;
        if (!body) {
            parent = table;
        }

        if (html) {
            if (typeof html === "string") {
                var frag = DOC.createDocumentFragment();
                frag.innerHTML = html;
            }

            parent.appendChild(html);
        }
    };

    /**
     * Export table to various formats (csv, txt or sql)
     * @param  {Object} options User options
     * @return {Boolean}
     */
    this.export = function (options) {
        if (!table.hasHeadings && !table.hasRows) return false;

        var headers = table.activeHeadings,
            rows = [],
            arr = [],
            i,
            x,
            str,
            link;

        var defaults = {
            download: true,
            skipColumn: [],

            // csv
            lineDelimiter: "\n",
            columnDelimiter: ",",

            // sql
            tableName: "myTable",

            // json
            replacer: null,
            space: 4
        };

        // Check for the options object
        if (!isObject(options)) {
            return false;
        }

        var o = extend(defaults, options);

        if (o.type) {

            each(table.selectedRows, function(rowIdx) {
                rows = rows.concat(table.activeRows[rowIdx]);
            });

            // Only proceed if we have data
            if (rows.length) {
                if (o.type === "txt" || o.type === "csv") {
                    rows.unshift(header);
                    str = "";

                    for (i = 0; i < rows.length; i++) {
                        for (x = 0; x < rows[i].cells.length; x++) {
                            // Check for column skip and visibility
                            if (
                                o.skipColumn.indexOf(headers[x].originalCellIndex) < 0 &&
                                columns(headers[x].originalCellIndex).visible()
                            ) {
                                var text = rows[i].cells[x].getAttribute("data-content") ? rows[i].cells[x].getAttribute("data-content") : rows[i].cells[x].textContent;
                                text = text.trim();
                                text = text.replace(/\s{2,}/g, " ");
                                text = text.replace(/\n/g, "  ");
                                text = text.replace(/"/g, "\"\"");
                                if (text.indexOf(",") > -1)
                                    text = "\"" + text + "\"";


                                str += text + o.columnDelimiter;
                            }
                        }
                        // Remove trailing column delimiter
                        str = str.trim().substring(0, str.length - 1);

                        // Apply line delimiter
                        str += o.lineDelimiter;
                    }

                    // Remove trailing line delimiter
                    str = str.trim().substring(0, str.length - 1);

                    if (o.download) {
                        str = "data:text/csv;charset=utf-8," + str;
                    }
                } else if (o.type === "json") {
                    // Iterate rows
                    for (x = 0; x < rows.length; x++) {
                        arr[x] = arr[x] || {};
                        // Iterate columns
                        for (i = 0; i < headers.length; i++) {
                            // Check for column skip and column visibility
                            if (
                                o.skipColumn.indexOf(headers[i].originalCellIndex) < 0 &&
                                columns(headers[i].originalCellIndex).visible()
                            ) {
                                arr[x][headers[i].textContent] = rows[x].cells[i].getAttribute("data-content") ? rows[x].cells[i].getAttribute("data-content") : rows[x].cells[i].textContent;
                            }
                        }
                    }

                    // Convert the array of objects to JSON string
                    str = JSON.stringify(arr, o.replacer, o.space);

                    if (o.download) {
                        str = "data:application/json;charset=utf-8," + str;
                    }
                }

                // Download
                if (o.download) {
                    // Filename
                    o.filename = o.filename || "datatable_export";
                    o.filename += "." + o.type;

                    str = encodeURI(str);

                    // Create a link to trigger the download
                    link = DOC.createElement("a");
                    link.href = str;
                    link.download = o.filename;

                    // Append the link
                    body.appendChild(link);

                    // Trigger the download
                    link.click();

                    // Remove the link
                    body.removeChild(link);
                }

                return str;
            }
        }

        return false;
    };

    /**
     * Import data to the table
     * @param  {Object} options User options
     * @return {Boolean}
     */
    this.import = function (options) {
        var obj = false;
        var defaults = {
            // csv
            lineDelimiter: "\n",
            columnDelimiter: ","
        };

        // Check for the options object
        if (!isObject(options)) {
            return false;
        }
        options = extend(defaults, options);

        if (options.data.length || isObject(options.data)) {
            // Import CSV
            if (options.type === "csv") {
                obj = {
                    rows: [],
                    details: [],
                    headings: []
                };

                // Split the string into rows
                var rows = options.data.split(options.lineDelimiter);

                if (rows.length) {
                    var importHeaders = rows[0].replace(/\r?\n|\r/g, "").split(options.columnDelimiter);
                    options.skipColumn = [];
                    options.details = -1;
                    each(importHeaders, function(importHeader, idx) {
                        if (labels.indexOf(importHeader) < 0) {
                            if (importHeader != "Details") {
                                options.skipColumn.push(idx);
                            } else {
                                options.details = idx;
                            }
                        } else {
                            obj.headings.push(importHeader);
                        }
                    });

                    if(obj.headings) { //.length + baseCellIdx() >= labels.length) {
                        rows.shift();

                        each(rows, function (row, i) {
                            row = row.replace(/\r?\n|\r/g, "");
                            obj.rows[i] = [];

                            // Split the rows into values
                            var values = row.split(options.columnDelimiter);

                            if (values.length) {
                                each(values, function (value, v) {
                                    if (options.skipColumn.indexOf(v) == -1 && options.details != v) {
                                        obj.rows[i].push(value);
                                    } else if (options.details == v) {
                                        obj.details[i] = value;
                                    }
                                });
                            }
                        });
                    } else {
                        var msg = "Supplied text/csv file does not contain the correct Header row!";
                        console.error(msg);
                        uicoreCustomEvent("Table", "Error", table, {"msg": msg});
                    }
                }
            } else if (options.type === "json") {
                var json = isJson(options.data);

                // Valid JSON string
                if (json) {
                    obj = {
                        rows: [],
                        details: [],
                        headings: []
                    };

                    if(isArray(json)) {
                        var idx = 0;
                        each(json, function (data) {
                            obj.rows[idx] = [];
                            obj.details[idx] = "";
                            each(data, function(value, importHeader) {
                                if (labels.indexOf(importHeader) < 0) {
                                    if (importHeader === "Details") {
                                        obj.details[idx] = value;
                                    }
                                } else {
                                    if (obj.headings.indexOf(importHeader) < 0) {
                                        obj.headings.push(importHeader);
                                    }
                                    obj.rows[idx].push(value);
                                }
                            });
                            idx++;
                        });
                    } else {
                        msg = "That's not valid JSON array data!";
                        console.error(msg);
                        uicoreCustomEvent("Table", "Error", table, {"msg": msg});
                    }
                } else {
                    msg = "That's not valid JSON!";
                    console.error(msg);
                    uicoreCustomEvent("Table", "Error", table, {"msg": msg});
                }
            } else if (isObject(options.data)) {
                obj = options.data;
            } else {
                return new Error("");
            }
            if (obj) {
                // Add the rows
                insert(obj);
            }
        }

        return false;
    };

    /**
     * Print the table
     * @return {void}
     */
    this.print = function () {
        var headings = table.activeHeadings;
        var rows = table.activeRows;

        // Open new window
        var w = window.open();
        var d = w.document;

        var pTable = d.createElement("table");
        var thead = d.createElement("thead");
        var tbody = d.createElement("tbody");

        var tr = d.createElement("tr");
        each(headings, function (th) {
            var newTh = d.createElement("th");
            newTh.appendChild(d.createTextNode(th.textContent));
            tr.appendChild(newTh);
        });

        thead.appendChild(tr);

        each(rows, function (row) {
            var tr = d.createElement("tr");
            each(row.cells, function (cell) {
                var newCell = d.createElement("td");
                newCell.appendChild(d.createTextNode(cell.textContent));
                tr.appendChild(newCell);
            });
            tbody.appendChild(tr);
        });

        pTable.appendChild(thead);
        pTable.appendChild(tbody);

        d.body.appendChild(pTable);

        // Print
        w.print();
    };

    /**
     * Show a message in the table
     * @param {string} message
     */
    this.setMessage = function (message) {

        var colspan = labels.length;

        wrapper.classList.add(prefix + "table-cmplx-empty");

        this.clear(
            createElement("tr", {
                html: "<td class=\"dataTables-empty\" colspan=\"" +
                    colspan +
                    "\">" +
                    message +
                    "</td>"
            })
        );

        if(!metHeight) {
            fixHeight();
        }
    };

    this.insertDetails = function(rowId, details) {
        var row = table.activeRows[rowId];
        if (row.event != "ExpandCancelEvent") {
            var stripeColor = rowId % 2 > 0 ? prefix + "table-cmplx-row-odd" : prefix + "table-cmplx-row-even";
            table.data[rowId].details = row.details = details;
            renderDetails(row, table.headings.length, stripeColor);

            var button = row.cells[0].querySelector("." + prefix + "table-cmplx-accordion-btn");
            var uses = row.cells[0].querySelectorAll("use");
            each(uses, function(use) {
                if (use.getAttribute("xlink:href").indexOf(prefix + "loading-sqrs") != -1) {
                    classRemove(use, prefix +"show");
                } else {
                    classAdd(use, prefix + "show");
                    button.classList.remove(prefix + "collapsed");
                    button.setAttribute("aria-expanded", true);
                }
            });
            this.update();
            uicoreCustomEvent("Table", "ExpandEndEvent", table, {"rowId": rowId});
        } else {
            return false;
        }
        return true;
    };

    this.delete = function() {
        var that = this;
        if (table.selectedRows.length == 0) {
            return false;
        } else {
            rows().remove(table.selectedRows);
            columns().rebuild();
            pagination.removeItems(table.selectedRows.length);
        }

        if (table.activeRows.length == 0) {
            table.hasRows = false;
            var cell = header.cells[getHeaderCellIndex(prefix + "table-cmplx-select-all")];
            var input = cell.querySelector("input[type='checkbox']");
            if (input.checked) {
                input.checked = false;
                input.state = "unchecked";
            }
            handleSelectAllChange(input.state);
        }

        if (searching) {
            var query = wrapper.querySelector("input[type='search']").value;
            var searchResults = table.searchData.length;
            table.searchData = [];
            searching = false;
            if (searchResults == table.selectedRows.length) {
                wrapper.querySelector("input[type='search']").value = "";
                that.update();
            } else {
                that.search(query);
            }
        } else {
            that.update();
        }

        table.selectedRows = [];
        if (!table.hasRows) {
            this.setMessage(options.labels.noRows);
        }
        uicoreCustomEvent("Table","DeleteEvent", table, { "rowIds": table.selectedRows});
        
    };

    //init
    if (!(stringTable in element)) {
        // Set the tables Id
        var crypto = window.crypto || window.msCrypto;
        batchId = crypto.getRandomValues(new Uint32Array(10))[0];

        table.data = null; // populated in dataToTable
        table.hasRows = false; // set in render
        table.headings = []; // duplicate commented out of code in render
        table.hasHeadings = false;
        
        // IE detection
        isIE = !!/(msie|trident)/i.test(navigator.userAgent);

        currentPage = 1;

        columnRenderers = [];
        selectedColumns = [];

        
        table.activeHeadings = [];
        
        table.sorting = false;
        
        table.searchData = [];
        table.hiddenColumns = [];
        if (options.select)
            table.selectedRows = [];

        render();

        // Options pagination
        if (options.pagination) {
            var itemCount = options.items > 0 ? options.items : table.activeRows.length;
            pagination = new Pagination(paginationElement, {
                perPage: options.perPage,
                items: itemCount
            });
            // Option to override the item count was padded in
            if (options.items == 0) {
                paginationElement.parentNode.addEventListener("uicPaginationPageUpdateEvent", function(e) {
                    self.page(e.detail.page);
                }, false);
                paginationElement.parentNode.addEventListener("uicPaginationPerPageUpdateEvent", function(e) {
                    self.perPage(e.detail);
                }, false);
            }
        }

        // Options condensed
        if (options.condensed) {
            wrapper.querySelector("." + prefix + "table-cmplx").classList.add(prefix + "condensed");
        }

        each(wrapper.querySelectorAll("[data-toggle=\"dds__dropdown\"]"), function(drop) {
            if (drop.classList.contains(prefix+"table-cmplx-action-button")) {
                new Dropdown(drop);
            }
        });
    }

    element[stringTable] = self;
}
import { uicoreCustomEvent, prefix, DOC } from "./utilities";
import noUiSlider from "../../node_modules/nouislider/distribute/nouislider";

export default function Slider(element, optionsInput) {
    element = element instanceof HTMLElement ? element : (function() {
        return false;
    })();

    var options = optionsInput || {};
    options.cssPrefix = prefix + "slider-";
    options.connect = true;
    options.tooltips = true;
    options.padding = 0;
    options.limit = null;
    options.orientation = "horizontal";
    options.direction = "ltr";
    options.animate = true;
    options.keyboardSupport = true;
    options.documentElement = DOC;

    var stringSlider = "Slider",
        nouislider = null,
        handles = null,
        slider = element.querySelectorAll("[data-toggle='dds__slider-div']")[0],
        minContainers = element.parentNode.querySelectorAll("span[data-slidermin]"),
        maxContainers = element.parentNode.querySelectorAll("span[data-slidermax]"),
        // Event Handlers
        endEventHandler = function(e) {
            var data = [];
            if (e["currentTarget"]) {
                data = nouislider.get();
            } else {
                data = e;
            }
            for (var min = 0; minContainers.length > min; min++) {
                minContainers[min].innerHTML = data[0];
            }
            for (var max = 0; maxContainers.length > max; max++) {
                maxContainers[max].innerHTML = data[1];
            }
            uicoreCustomEvent("Slider", "SlideEnd", element, {"min": unformatString(data[0]), "max": unformatString(data[1])});
        },
        focusEventHandler = function(e) {
            e["currentTarget"].classList.add(prefix + "slider-active");
        },
        blurEventHandler = function(e) {
            e["currentTarget"].classList.remove(prefix + "slider-active");
        },
        unformatString = function(string) {
            return options.format.from(string);
        },
        validateOptions = function(options) {
            if (options.range == null
                || options.range.min == null
                || options.range.max == null
                || typeof options.range.min != "number"
                || typeof options.range.max != "number"
                || options.range.min >= options.range.max) {
                throw new RangeError("There was a problem found with the range option values, please correct and try again!");
            }
            if (options.start == null
                || typeof options.start != "object"
                || options.start.length != 2
                || typeof options.start[0] != "number"
                || typeof options.start[1] != "number"
                || options.start[0] >= options.start[1]
                || options.start[0] < options.range.min
                || options.start[0] > options.range.max
                || options.start[1] < options.range.min
                || options.start[1] > options.range.max) {
                throw new RangeError("The was a problem found with the start option values, please correct and try again");
            }
            if (options.step == null
                || typeof options.step != "number") {
                throw new Error("There was a problem found with the step option value, please correct and try again");
            }
            if (options.margin == null
                || typeof options.margin != "number") {
                throw new Error("There was a problem found with the margin option value, please correct and try again");
            }
            if (options.format == null
                || options.format.to == null
                || options.format.from == null
                || typeof options.format.to != "function"
                || typeof options.format.from != "function") {
                throw new Error("There was a problem found with the format option value(s), please correct and try again");
            }
        };

    // init
    if (!(stringSlider in element)) {
        validateOptions(options);
        // prevent adding event handlers twice
        nouislider = noUiSlider.create(slider, options);
        nouislider.on("end", endEventHandler);
        for (var min = 0; minContainers.length > min; min++) {
            minContainers[min].innerHTML = options.format.to(options.range.min);
        }
        for (var max = 0; maxContainers.length > max; max++) {
            maxContainers[max].innerHTML = options.format.to(options.range.max);
        }
        handles = element.querySelectorAll(".dds__slider-handle");
        for (var i = 0; i < handles.length; i++) {
            handles[i].addEventListener("focus", focusEventHandler, false);
            handles[i].addEventListener("blur", blurEventHandler, false);
            handles[i].addEventListener("keyup", endEventHandler, false);
        }
        element.addEventListener("click", endEventHandler, false);
    }

    element[stringSlider] = self;

}

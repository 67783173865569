import {getClosest, prefix, DOC} from "./utilities.js";

export default function Tab(element) {

    element = element instanceof HTMLElement ? element : (function() {
        return false;
    })();

    var stringTab = "Tab",
        tablist = getParentTablist(element),
        modalElement = element.dataset["target"] && DOC.getElementById(element.dataset["target"].substr(1)),
        tabs,
        backButton,
        panels,
        delay = determineDelay();

    // Key mappings for easy reference
    var keys = {
        end: 35,
        home: 36,
        left: 37,
        up: 38,
        right: 39,
        down: 40,
        delete: 46
    };

    // Add or substract depending on key pressed
    var direction = {
        37: -1,
        38: -1,
        39: 1,
        40: 1
    };


    // When a tab is clicked, activateTab is fired to activate it
    function clickEventListener(e) {
        var target = e.target;
        if (target === element && modalElement && !(modalElement.classList.contains(prefix + "show"))) {
            e.preventDefault();
        }
        show();
        var tab = getClosest(target, "." + prefix + "tab-link");
        activateTab(tab, false);
    }

    // Handle keydown on tabs
    function keydownEventListener(e) {
        var key = e.keyCode;

        switch (key) {
        case keys.end:
            e.preventDefault();
            // Activate last tab
            activateTab(tabs[tabs.length - 1]);
            break;
        case keys.home:
            e.preventDefault();
            // Activate first tab
            activateTab(tabs[0]);
            break;

        // Up, down, left, and right keys are in keydown
        // because we need to prevent page scroll
        case keys.up:
        case keys.down:
            determineOrientation(e);
            break;
        case keys.left:
        case keys.right:
            determineOrientation(e);
            break;
        }
    }

    // Handle keyup on tabs
    function keyupEventListener (e) {
        var key = e.keyCode;

        switch (key) {
        case keys.delete:
            determineDeletable(e);
            break;
        }
    }

    // When a tablist's aria-orientation is set to vertical,
    // only up and down arrow should function.
    // In all other cases only left and right arrow function.
    function determineOrientation(e) {
        var key = e.keyCode;
        var vertical = tablist.getAttribute("aria-orientation") == "vertical";
        var proceed = false;

        if (vertical) {
            if (key === keys.up || key === keys.down) {
                e.preventDefault();
                proceed = true;
            }
        }
        else {
            if (key === keys.left || key === keys.right) {
                e.preventDefault();
                proceed = true;
            }
        }

        if (proceed) {
            switchTabOnArrowPress(e);
        }
    }

    // Find first parent of el with role="tablist"
    function getParentTablist(el) {
        var role = "tablist";
        while (el && el.parentNode) {
            el = el.parentNode;
            if (el.getAttribute("role") && el.getAttribute("role") == role) {
                return el;
            }
        }
        return null;
    }

    // Get all siblings of el with class="dds__tab-link"
    function getSiblingTabs() {
        var siblingTabs = tablist.querySelectorAll("." + prefix + "tab-link");
        for (var i = 0; i < siblingTabs.length; i++) {
            siblingTabs[i].index = i;
        }
        return siblingTabs;
    }

    function getTablistPanels(tab) {
        var controls = tab.getAttribute("aria-controls");
        var panelGroup = document.getElementById(controls).parentElement;
        var siblingPanels = panelGroup.querySelectorAll("." + prefix + "tab-pane");

        return siblingPanels;

    }

    // Either focus the next, previous, first, or last tab
    // depening on key pressed
    function switchTabOnArrowPress(e) {
        var pressed = e.keyCode;

        for (var x = 0; x < tabs.length; x++) {
            tabs[x].addEventListener("focus", focusEventHandler);
        }

        if (direction[pressed]) {
            var target = e.target;
            if (target.index !== undefined) {
                if (tabs[target.index + direction[pressed]]) {
                    tabs[target.index + direction[pressed]].focus();
                }
                else if (pressed === keys.left || pressed === keys.up) {
                    focusLastTab();
                }
                else if (pressed === keys.right || pressed == keys.down) {
                    focusFirstTab();
                }
            }
        }
    }

    // Activates any given tab panel
    function activateTab(tab, setFocus) {
        setFocus = setFocus || true;

        // Deactivate all other tabs
        deactivateTabs(tab);

        // Remove tabindex attribute
        tab.removeAttribute("tabindex");

        // Set the tab as selected
        tab.setAttribute("aria-selected", "true");

        // Get the value of aria-controls (which is an ID)
        var controls = tab.getAttribute("aria-controls");

        tab.classList.add(prefix + "active");

        // Remove hidden attribute from tab panel to make it visible
        // document.getElementById(controls).removeAttribute('hidden');
        document.getElementById(controls).classList.add(prefix + "active");
        document.getElementById(controls).classList.add(prefix + "show");

        // Set focus when required
        if (setFocus) {
            tab.focus();
        }
    }

    // Deactivate all tabs and tab panels
    function deactivateTabs(tab) {
        panels = getTablistPanels (tab);

        for (var t = 0; t < tabs.length; t++) {
            tabs[t].setAttribute("tabindex", "-1");
            tabs[t].setAttribute("aria-selected", "false");
            tabs[t].removeEventListener("focus", focusEventHandler);
            tabs[t].classList.remove(prefix + "active");
            tabs[t].classList.remove(prefix + "show");
        }

        for (var p = 0; p < panels.length; p++) {
        // panels[p].setAttribute('hidden', 'hidden');
            panels[p].classList.remove(prefix + "active");
            panels[p].classList.remove(prefix + "show");
        }
    }

    // Make a guess
    function focusFirstTab() {
        tabs[0].focus();
    }

    // Make a guess
    function focusLastTab() {
        tabs[tabs.length - 1].focus();
    }

    // Detect if a tab is deletable
    function determineDeletable(e) {
        var target = e.target;

        if (target.getAttribute("data-deletable") !== null) {
            // Delete target tab
            deleteTab(e);

            // Update arrays related to tabs widget
            // generateArrays();

            // Activate the closest tab to the one that was just deleted
            if (target.index - 1 < 0) {
                activateTab(tabs[0]);
            }
            else {
                activateTab(tabs[target.index - 1]);
            }
        }
    }

    // Deletes a tab and its panel
    function deleteTab(e) {
        var target = e.target;
        var panel = document.getElementById(target.getAttribute("aria-controls"));

        target.parentElement.removeChild(target);
        panel.parentElement.removeChild(panel);
    }

    // Determine whether there should be a delay
    // when user navigates with the arrow keys
    function determineDelay() {
        var hasDelay = tablist.hasAttribute("data-delay");
        var delay = 0;

        if (hasDelay) {
            var delayValue = tablist.getAttribute("data-delay");
            if (delayValue) {
                delay = delayValue;
            }
            else {
                // If no value is specified, default to 300ms
                delay = 300;
            }
        }

        return delay;
    }

    //
    function focusEventHandler(e) {
        var target = e.target;

        setTimeout(checkTabFocus, delay, target);
    }

    // Only activate tab on focus if it still has focus after the delay
    function checkTabFocus (target) {
        var focused = document.activeElement;

        if (target === focused) {
            activateTab(target, false);
        }
    }
    // triggers
    function triggerShow() {
        // resizeHandlerToggle();
        modalElement.focus ? modalElement.focus() : modalElement.setActive();
    }
    function triggerHide() {
        modalElement.style.display = "";
        // element && element.focus ? element.focus() : element.setActive();
    }
    function createModal() {
        var tab = getClosest(event.target, "." + prefix+ "tab-link");
        var controls = tab.getAttribute("aria-controls");
        var tabContent = document.getElementById(controls).innerHTML;

        var modalBody = modalElement.querySelector("." + prefix+ "modal-body");
        modalBody.innerHTML = tabContent;
    }
    function dismissHandler(e) {
        if (modalElement.classList.contains(prefix + "show")) {
            hide();
            e.preventDefault();
        }
    }
    function resizeHandler(){
        if (modalElement.classList.contains(prefix + "show") && window.matchMedia("(min-width: 767.98px)").matches) {
            hide();
            window.removeEventListener("resize", resizeHandler, false);
        }
    }
    function show() {
        if (window.innerWidth > 767.98) {
            return;
        }
        createModal();
        modalElement.style.display = "block";
        //modalElement.classList.remove(prefix + "slide-right");
        modalElement.setAttribute("aria-hidden", false);
        triggerShow();
        modalElement.classList.add(prefix + "show");

        DOC.body.classList.add(prefix + "modal-open");

        backButton = modalElement && modalElement.querySelector("[data-dismiss='"+prefix+"modal']");
        backButton.addEventListener("click", dismissHandler, false);
        window.addEventListener("resize", resizeHandler, false);
    }
    function hide() {
        modalElement.classList.add(prefix + "slide-right");
        modalElement.classList.remove(prefix + "show");
        modalElement.setAttribute("aria-hidden", true);
        setTimeout(function() {
            triggerHide();
            
            DOC.body.classList.remove(prefix + "modal-open");
            backButton.removeEventListener("click", dismissHandler, false);
        }, 200);
    }

    // init
    if (!(stringTab in element)) {
        tabs = getSiblingTabs();
        // prevent adding event handlers twice
        element.addEventListener("keydown", keydownEventListener);
        element.addEventListener("keyup", keyupEventListener);
        element.addEventListener("click", clickEventListener);
    }
    
    element[stringTab] = self;
}

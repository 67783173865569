import {prefix, uicoreCustomEvent} from "./utilities.js";

export default function ContactDrawer(element, options) {
    // initialization element
    element = element instanceof HTMLElement ? element : (function() {
        return false;
    })();

    // set options
    options = options || {};
    options.openTime =  options.openTime ? options.openTime : 5000; // milliseconds
    options.closedTime = options.closedTime ? options.closedTime : 7000;  // milliseconds
    options.transitionTime = options.transitionTime ? options.transitionTime : 500;  // milliseconds

    // bind, elements
    var self = this,
        stringContactDrawer = "ContactDrawer",
        tab = element.querySelector("." + prefix + "drawer-tab"),
        closeButton = element.querySelector("." + prefix + "close-x"),
        content = element.querySelector("." + prefix + "drawer-content"),
        visible = true,
        cancelAutomation = false;

    // handlers
    var clickHandler = function (e) {
        e.preventDefault();
        if (!cancelAutomation) {
            cancelAutomation = true;
        }
        toggleAction();
    };

    // private methods
    var openAction = function() {
            visible = true;
            element.classList.add(prefix + "drawer-open");
            uicoreCustomEvent("ContactDrawer", "OpenEvent", element);
            content.hidden = false;
            content.setAttribute("aria-hidden", false);
        },
        closeAction = function() {
            visible = false;
            element.classList.remove(prefix + "drawer-open");
            uicoreCustomEvent("ContactDrawer", "CloseEvent", element);
            setTimeout(function() {
                content.hidden = true;
            }, options.transitionTime);
            content.setAttribute("aria-hidden", true);
        },
        toggleAction = function() {
            if (visible) {
                closeAction();
            } else {
                openAction();
            }
        },
        setDrawerToggleOptions =  function() {
            var toggleOptionsStr = content.dataset.contactDrawer;
            if (toggleOptionsStr) {
                var toggleOptions = JSON.parse(toggleOptionsStr);

                if (toggleOptions) {
                    if (toggleOptions.OpenTimeInSeconds)
                        options.openTime = toggleOptions.OpenTimeInSeconds * 1000;
                    if (toggleOptions.CloseTimeInSeconds)
                        options.closedTime = toggleOptions.CloseTimeInSeconds * 1000;
                    if (toggleOptions.TransitionTimeInMilliseconds)
                        options.transitionTime = toggleOptions.TransitionTimeInMilliseconds;
                }
            }
        },
        setTransitionTime = function() {
            if (
                options.transitionTime > options.openTime ||
                options.transitionTime > options.closedTime
            ) {
                //console.warn("contact drawer transition time is too large");
                return;
            }

            if (element) {
                element.style.transition = "all " + options.transitionTime + "ms ease";
            }
        };

    // public methods
    this.timerAction = function() {
        if (!cancelAutomation) {
            if (visible) {
                closeAction();
                if (options.closedTime) {
                    setTimeout(function() {
                        return self.timerAction();
                    }, options.closedTime);
                }
            } else {
                openAction();
                if (options.openTime) {
                    setTimeout(function() {
                        return self.timerAction();
                    }, options.openTime);
                }
            }
        }
    };

    // init
    if (!(stringContactDrawer in element)) {

        tab.addEventListener("click", clickHandler, false);
        closeButton.addEventListener("click", clickHandler, false);

        setDrawerToggleOptions();
        setTransitionTime();
    }

    if(!cancelAutomation) {
        self.timerAction();
    }
    element[stringContactDrawer] = self;
}

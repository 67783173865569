import { DOC, prefix, createElement, classAdd, isArray, uicoreCustomEvent, isIE, each } from "./utilities.js";

export default function Pagination(element, options) {

    element = element instanceof HTMLElement ? element : (function () {
        return false;
    })();

    var jsonParams = element.dataset.page;
    if (jsonParams) {
        options = JSON.parse(jsonParams);
    }

    // set options
    options = options || {};

    options.items = options.items && typeof options.items === "number" ? options.items : 1;
    options.filter = element.classList.contains(prefix + "pagination-justified-filter") ? true : false;
    options.perPageSelect = [12, 24, 48, 96];
    options.perPage = options.perPage && typeof options.perPage === "number" && options.perPageSelect.indexOf(options.perPage) > -1 ? options.perPage : 12;

    var self = this,
        stringPagination = "Pagination",
        template = "{pager}{select}",
        pagination,
        prevText = "Previous",
        nextText = "Next",
        currentPage,
        origItemsPerPage,
        newPage,
        totalPages,
        newTotalPages,
        lastPage,
        onFirstPage,
        onLastPage,
        links,
        // handlers
        handlePaginationEvent = function(e) {
            var target;
            if (e.target.dataset["page"]) {
                target = e.target;
            } else {
                target = e.target.parentNode;
            }
            var name = target.getAttribute("aria-label");

            if (target.dataset["page"]) {
                self.page(target.dataset["page"]);
            }

            var focusBtn =  pagination.querySelector("[aria-label="+name+"]");
            focusBtn.focus();

        },
        handleKeydownEvent = function(e) {
            if(e.keyCode == 13) {
                if(e.target.value <= totalPages && e.target.value > 0) {
                    self.page(e.target.value);
                } else {
                    e.target.value = currentPage;
                }
                var focusInput = pagination.querySelector("#pagination-current-page");
                focusInput.focus();
            }
        },
        handleFocusoutEvent = function(e) {
            if(e.target.value != currentPage) {
                e.target.value = currentPage;
            } else {
                e.preventDefault();
            }
        },
        handleChangeEvent = function(e) {
            if (e.target.value != options.perPage) {
                origItemsPerPage = options.perPage;
                options.perPage = e.target.value;
                update();
                var focusSelect = element.querySelector("#pagination-per-page");
                focusSelect.focus();
            }
        },
        // dynamic ui
        render = function() {
            
            var paginator = createElement("ul", {
                class: prefix + "pagination-list",
                role: "presentation"
            });
    
            // Pager placement
            template = template.replace("{pager}", paginator.outerHTML);

            // Per Page Select placement
            if (options.filter) {
                var wrap = "<div class='" + prefix + "perpage'>{select}</div>";
    
                // Selector placement
                template = template.replace("{select}", wrap);
            } else {
                template = template.replace("{select}", "");
            }

            element.innerHTML = template;

            pagination = element.querySelector("." + prefix + "pagination-list");

            if (options.items < options.perPage) {
                pagination.classList.add(prefix + "pagination-hidden");
            }

            update();
        },
        renderPage = function () {
            var origCurrentPage = currentPage;

            if (newPage) {
                currentPage = newPage;
                newPage = null;
            } else if (origItemsPerPage) {
                var firstItemOnPage = ( (origCurrentPage - 1) * origItemsPerPage) + 1;
                currentPage = Math.ceil(firstItemOnPage / options.perPage);
                origItemsPerPage = null;
            } else if (newTotalPages && currentPage > newTotalPages) {
                currentPage = newTotalPages;
            }

            onFirstPage = currentPage === 1;
            onLastPage = currentPage === lastPage;

            if (origCurrentPage != currentPage) {
                uicoreCustomEvent("Pagination", "PageUpdateEvent", element, { "page": currentPage, "perPage" : options.perPage, "pages" : totalPages });
            }
        },
        renderSvg = function(svg) {
            var svgWrapper = DOC.createElementNS("http://www.w3.org/2000/svg", "svg");
            svgWrapper.setAttribute("focusable", false);
            classAdd(svgWrapper, prefix + "svg-icons");
            if (!isArray(svg)) {
                svg = [svg];
            }
            each(svg, function(elm) {
                var svgElem = DOC.createElementNS("http://www.w3.org/2000/svg", "use");
        
                svgElem.setAttributeNS("http://www.w3.org/1999/xlink", "xlink:href", "#" + prefix + elm.name);
                classAdd(svgElem, [prefix + "svg-icons-item"]);
                if (elm.show) {
                    classAdd(svgElem, [ prefix + "show"]);
                }

                svgWrapper.appendChild(svgElem);
            });
            return svgWrapper;
        },
        renderPageInput = function() {
            var pageInputWrapper = createElement("li", {
                class: prefix + "pagination-input-ctnr"
            });
            var pageInputTextBefore = createElement("label", {
                html: "Page"
            });
            var pageInputField = createElement("input", {
                id: "pagination-current-page",
                class: prefix + "form-control " + prefix + "text-center" ,
                type: "text",
                value: currentPage,
                "aria-label": "Page " + currentPage + " of " +  (newTotalPages ? newTotalPages : totalPages)
            });
            var pageInputTextAfter = createElement("label", {
                html: "of " +  (newTotalPages ? newTotalPages : totalPages)
            });
            
            pageInputWrapper.appendChild(pageInputTextBefore);
            pageInputWrapper.appendChild(pageInputField);
            pageInputWrapper.appendChild(pageInputTextAfter);
            return pageInputWrapper;
        },
        renderPager = function () {
            var origTotalPages = totalPages,
                pages = newTotalPages ? newTotalPages : totalPages;

            flush(pagination, isIE);
    
            if (pages > 1) {
                var frag = DOC.createDocumentFragment(),
                    prev = onFirstPage ? 1 : currentPage - 1,
                    next = onLastPage ? pages : currentPage + 1;
    
                frag.appendChild(button("dds__btn dds__btn-secondary dds__page-item", prev, prevText));
    
                var pager = links;
                
                each(pager, function (p) {
                    frag.appendChild(p);
                });
    
                frag.appendChild(button("dds__btn dds__btn-secondary dds__page-item", next, nextText));
            
                pagination.appendChild(frag.cloneNode(true));
                // add in click and keydown listeners
                each(pagination.querySelectorAll("li"), function(el) {
                    var hasInput = el.querySelector("input");
                    var hasButton = el.querySelector("button");
                    if(hasButton) {
                        el.addEventListener("click", handlePaginationEvent, true);
                    }
                    if(hasInput) {
                        hasInput.addEventListener("keydown", handleKeydownEvent, false);
                        hasInput.addEventListener("focusout", handleFocusoutEvent, false);
                    }
                });
            }

            if (pages != origTotalPages || newTotalPages) {
                totalPages = pages;
                newTotalPages = null;
                uicoreCustomEvent("Pagination", "PerPageUpdateEvent", element, { "page": currentPage, "perPage" : options.perPage, "pages" : totalPages });
            }
        },
        renderSelect = function() {
            var el = element.querySelector("." + prefix + "perpage");

            // Create the select
            var select = createElement("select",{
                id: "pagination-per-page",
                class: prefix + "form-control"
            });

            // Create the options
            each(options.perPageSelect, function(val) {
                var option;
                if (val == options.perPage) {
                    option = new Option(val, val, true, true);
                } else {
                    option = new Option(val, val, false, false);
                }
                select.add(option);
            });

            var label = createElement("label", {
                html: "Items per page",
                for: "pagination-per-page"
            });

            // Custom label
            el.innerHTML = "";
            el.appendChild(select);
            el.appendChild(label);

            select.addEventListener("change", handleChangeEvent, false);
        },
        button = function (c, p, t) {
            var el = createElement("li");

            var bEl = createElement("button", {
                class: c + " " + prefix + "pager-link",

                "aria-label": t,
                "data-page": p
            });
            
            var span = createElement("span", {
                html: t
            });
            bEl.appendChild(span);
            var svgElem;
            if (t === prevText) {
                svgElem = renderSvg([{name:"chevron-left", show:true}]);
                svgElem.setAttribute("name","chevron-left");
                bEl.insertBefore(svgElem, span);
            } else if (t === nextText) {
                svgElem = renderSvg([{name:"chevron-right", show:true}]);
                svgElem.setAttribute("name","chevron-right");
                bEl.appendChild(svgElem);
            }
            el.appendChild(bEl);

            return el;
        },
        flush = function (el, ie) {
            if (el instanceof NodeList) {
                each(el, function (e) {
                    flush(e, ie);
                });
            } else {
                if (ie) {
                    while (el.hasChildNodes()) {
                        el.removeChild(el.firstChild);
                    }
                } else {
                    el.innerHTML = "";
                }
            }
        },
        paginate = function () {
            var pages;

            if (options.items < options.perPage) {
                pages = 1;
            } else {
                pages = Math.ceil( (options.items / options.perPage) );
            }
            if (!totalPages) {
                totalPages = lastPage = pages;
            } else {
                newTotalPages = lastPage = pages;
            }
        },
        update = function() {

            if (options.items < options.perPage) {
                pagination.classList.add(prefix + "pagination-hidden");
            } else if (pagination.classList.contains(prefix + "pagination-hidden")) {
                pagination.classList.remove(prefix + "pagination-hidden");
            }

            paginate(); 

            renderPage();
    
            links = [renderPageInput()];
    
            renderPager();

            if(options.filter) {
                renderSelect();
            }
        },
        validate = function(num) {
            // check to see if page is a not a number but can be a string
            if ( isNaN(num) && typeof num != "string") {
                return false;
            // if it is a string can it be converted to a number
            } else if (typeof num === "string") {
                if(isNaN(parseInt(num, 10))) {
                    return false;
                }
                num = parseInt(num, 10);
            }
            return num;
        };


    /**
     * Change page
     * @param  {int} page
     * @return {void}
     */
    this.page = function(num) {
        
        newPage = validate(num);

        if (!newPage
            || newPage == currentPage 
            || newPage > totalPages 
            || newPage < 0
        ) {
            return false;
        }

        update();
    };

    /**
     * Change number of pages
     * @param  {int} pages
     * @return {void}
     */
    this.addItems = function(num) {
        
        var addItems = validate(num);

        if (!addItems) {
            return false;
        } else {
            options.items += addItems;
        }

        update();
    };

    /**
     * Change number of pages
     * @param  {int} pages
     * @return {void}
     */
    this.removeItems = function(num) {
        
        var remItems = validate(num);

        if (!remItems) {
            return false;
        } else {
            options.items -= remItems;
        }

        update();
    };

    /**
     * Change number of pages
     * @param  {int} pages
     * @return {void}
     */
    this.setItems = function(num) {
        
        var newItems = validate(num);

        if (newItems == undefined || newItems == null) {
            return false;
        } else {
            options.items = newItems;
        }

        update();
    };

    /**
     * Return the calculated pages
     * @return {int} totalPages
     */
    this.pages = function() {
        return totalPages;
    };

    //init
    if (!(stringPagination in element)) {
        currentPage = 1;
        render();
    }

    element[stringPagination] = self;
}
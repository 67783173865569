import {prefix, DOC, getClosest, each, getFullScreenOverlay, isSafari, isIPhone} from "./utilities.js";
import Collapse from "./collapse.js";

export default function Masthead(element) {
    // initialization element
    element = element instanceof HTMLElement ? element : (function() {
        return false;
    })();

    var self = this,
        stringMasthead = "Masthead",
        bottomMenu = element.querySelector("." + prefix + "msthd-bottom"),
        mouseHover = ["mouseenter", "mouseleave"],
        activeContainerId,
        offCanvasMenuBtn,
        fullScreenOverlay,
        overlay,
        mediaSize = window.matchMedia("(max-width: 991.98px)"),
        // validate with Search Story
        // searchClass = "." + prefix + "msthd-search",
        // searchCtnrClass = "." + prefix + "msthd-search-ctnr",
        // search = element.querySelector(searchClass),
        // searchCtnr = element.querySelector(searchCtnrClass),
        // searchIconCtnr = element.querySelector("div[data-toggle='dds__msthd-search']"),
        ////////////////////////////
        // handlers
        handleMenuItemEvent = function(e) {
            e.preventDefault();
            var target = e.target.tagName === "LI" ? e.target : getClosest(e.target, "LI");
            // openOverLay();
            updateMenuHeights(target);
            e.stopPropagation();
        },
        handleMenuCntrEvent = function(e) {
            e.preventDefault();
            var currentMenu =  e.target.tagName === "UL" ? e.target : getClosest(e.target, "UL");
            updateMenuHeights(currentMenu);
            e.stopPropagation();
        },
        handleMenuCtnrBlurEvent = function(e) {
            var target = e.currentTarget;
            var relatedTarget = e.relatedTarget ? e.relatedTarget : DOC.activeElement;
            if (!e.currentTarget.contains(relatedTarget)) {
                closeMenu(target);
                closeOverLay();
            }
        },
        handleMenuHoverInEvent = function(e) {
            e.preventDefault();
            openOverLay();
            e.stopPropagation();
        },
        handleMenuHoverOutEvent = function(e) {
            e.preventDefault();
            closeOverLay();
            e.stopPropagation();
        },
        handleMenuItemKeyEvent = function(e) {
            var anchor = e.target ? e.target : DOC.activeElement,
                item = anchor.parentNode,
                menu = item.querySelector("ul") ? item.querySelector("ul") : undefined,
                isTop = item.classList.contains(prefix + "msthd-menu-top-item"),
                nextItem;
            switch (e.keyCode) {
            case 37: // left key
                e.preventDefault();
                if (isTop) {
                    if (item.previousElementSibling && (nextItem = item.previousElementSibling.querySelector("a"))) {
                        nextItem.focus();
                    }
                }
                else if (item.classList.contains(prefix + "msthd-menu-item-img") && item.previousElementSibling) {
                    item.previousElementSibling.querySelector("a").focus();
                } else {
                    previousItem = closeMenu(item);
                    
                    if (previousItem.classList.contains(prefix + "msthd-menu-top-item")) {
                        if(previousItem.previousElementSibling) {
                            previousItem.previousElementSibling.querySelector("a").focus();
                        }
                        else{
                            previousItem.querySelector("a").focus();
                        } 
                    }
                    else{
                        previousItem.querySelector("a").focus();
                        updateMenuHeights(previousItem);
                    }
                }
                break;
            case 38: // up key
                e.preventDefault();
                if (!isTop) {
                    if (item.previousElementSibling && (nextItem = item.previousElementSibling.querySelector("a"))) {
                        nextItem.focus();
                    } else {
                        item.parentNode.lastElementChild.querySelector("a").focus();
                    }
                } 
                break;
            case 39: // right key
                e.preventDefault();
                if (isTop) {
                    if (item.nextElementSibling && (nextItem = item.nextElementSibling.querySelector("a"))) {
                        nextItem.focus();
                    }
                } else {
                    if (anchor.querySelector("svg")) {
                        openMenu([item, menu, anchor]);
                        if (!isTop) {
                            updateMenuHeights(item);
                        }
                        menu.querySelector("a").focus();
                    } else if (item.classList.contains(prefix + "msthd-menu-item-img") && item.nextElementSibling) {
                        item.nextElementSibling.querySelector("a").focus();
                    } else {
                        do {
                            previousItem = closeMenu(item);
                            item = previousItem;
                        } while(!item.classList.contains(prefix + "msthd-menu-top-item"));

                        if(item.nextElementSibling) {
                            item.nextElementSibling.querySelector("a").focus();
                        } else {
                            item.querySelector("a").focus();
                        }
                    }
                }
                break;
            case 40: // down key
                e.preventDefault();
                if (isTop) {
                    if(!menu){
                        item.focus();
                    } else{
                        openMenu([item, menu, anchor]);
                        menu.querySelector("a").focus();
                        openOverLay();
                    }
                } else {
                    if (item.nextElementSibling && (nextItem = item.nextElementSibling.querySelector("a"))) {
                        nextItem.focus();
                    } else {
                        item.parentNode.firstElementChild.querySelector("a").focus();
                    }
                }
                break;
            case 27: // escape key
                do {
                    var previousItem = closeMenu(item);
                    item = previousItem;
                } while(!item.classList.contains(prefix + "msthd-menu-top-item"));
                item.querySelector("a").focus();
                break;
            case 13:
            case 32: // enter and spacebar keys
                if (menu) {
                    if (item.hasAttribute("active")) {
                        closeMenu(item);
                        updateMenuHeights(menu);
                    } else {
                        openMenu([item, menu, anchor]);
                        openOverLay();
                        if (!isTop) {
                            updateMenuHeights(item);
                        }
                    }
                    menu.querySelector("a").focus();
                }
                break;
            }
        },
        handleIconClickEvent = function(e) {
            var button = e.target.tagName === "BUTTON" || e.target.tagName === "A"? e.target : e.target.parentNode,
                target = button.dataset.target,
                container = DOC.getElementById(target),
                origContainerId;

            if (activeContainerId) {
                origContainerId = activeContainerId;
                var activeContainer = DOC.getElementById(activeContainerId);
                var activeButton = getClosest(activeContainer, "BUTTON");
                closeContainer(activeButton, activeContainer);
            }
            if (origContainerId != target) {
                openContainer(target, button, container);
            }
        },
        handleWindowClickEvent = function(e) {
            if (activeContainerId) {
                var container = DOC.getElementById(activeContainerId),
                    button = getClosest(container, "button");
                if (!container.contains(e.target) && !button.contains(e.target)) {
                    closeContainer(button, container);
                }
            }
        },
        handleFocusOutEvent = function(e) {
            var target = e.relatedTarget ? e.relatedTarget : e.target;
            if (activeContainerId) {
                var container = DOC.getElementById(activeContainerId),
                    button = getClosest(container, "button");
                if (target === button) {
                    return;
                } else if (!container.contains(target)) { 
                    closeContainer(button, container);
                } else {
                    window.addEventListener("click", handleWindowClickEvent, false);
                }
            }
        },
        handleKeyDownEvent = function(e) {
            if(activeContainerId) {
                switch (e.keyCode) {
                case 37:
                case 38:
                case 39:
                case 40:
                    e.preventDefault();
                    break;
                
                default:
                    break;
                }
                
                var container = DOC.getElementById(activeContainerId);
                var button = getClosest(container, "button");
                if (e.keyCode == 27) {
                    closeContainer(button, container);
                    button.focus();
                } else if (e.shiftKey && e.keyCode == 9) {
                    if (e.target === container.querySelectorAll("a[href], area[href], input:not([disabled]), select:not([disabled]), textarea:not([disabled]), button:not([disabled]), [tabindex='0']")[0]) {
                        closeContainer(button, container);
                    }
                }
            }
        },
        openOverLay = function() {
            if (overlay) {
                if (!overlay.classList.contains(prefix + "show")) {
                    overlay.classList.add(prefix + "show");
                }
            } else {
                console.error("Please put the overlay in place!");
            }
        },
        closeOverLay = function() {
            if (overlay) {
                if (overlay.classList.contains(prefix + "show")) {
                    overlay.classList.remove(prefix + "show");
                }
            } else {
                console.error("Please put the overlay in place!");
            }
        },
        closeMenu = function(item){
            var previousMenu = item.tagName === "LI" ? item.parentNode : item,
                previousItem = previousMenu.parentNode,
                previousLink = previousItem.querySelector("a");

            if (previousMenu.hasAttribute("style")) {
                previousMenu.removeAttribute("style");
            }
            
            previousItem.removeAttribute("active");
            previousMenu.removeAttribute("active");
            previousLink.removeAttribute("active");
            previousLink.setAttribute("aria-expanded","false");

            return previousItem;
        },
        openMenu = function(items) {
            each(items, function(item) {
                item.setAttribute("active","");
                if(item.tagName === "A") item.setAttribute("aria-expanded","true");
            });
            
        },
        updateMenuHeights = function(target) {
            var currentMenu, 
                nextMenu, 
                previousMenu, 
                prePreviousMenu,
                heights = [],
                max;

            // do not do logic on a list item image
            if (!target.classList.contains(prefix + "msthd-menu-item-img")) {
                // if a list item is passed
                if (target.tagName === "LI") {
                    currentMenu = target.parentNode;
                    nextMenu = target.querySelector("ul") ? target.querySelector("ul") : undefined;
                // if a unordered list is passed
                } else {
                    currentMenu = target;
                }         

                previousMenu = currentMenu.parentNode.parentNode.classList.contains(prefix + "msthd-menu-top") ? undefined : currentMenu.parentNode.parentNode;
                prePreviousMenu = previousMenu ? previousMenu.parentNode.parentNode.classList.contains(prefix + "msthd-menu-top") ? undefined : previousMenu.parentNode.parentNode : undefined;

                if (!nextMenu || !nextMenu.classList.contains(prefix + "msthd-menu-tier-img")) {
                    // resst any height style
                    if (currentMenu.hasAttribute("style")) {
                        currentMenu.removeAttribute("style");
                    }
                    if (nextMenu && nextMenu.hasAttribute("style")) {
                        nextMenu.removeAttribute("style");
                    }
                    if (previousMenu && previousMenu.hasAttribute("style")) {
                        previousMenu.removeAttribute("style");
                    }
                    if (prePreviousMenu && prePreviousMenu.hasAttribute("style")) {
                        prePreviousMenu.removeAttribute("style");
                    }

                    // find max height
                    if (currentMenu) heights.push(currentMenu.offsetHeight);
                    if (nextMenu) heights.push(nextMenu.offsetHeight);
                    if (previousMenu) heights.push(previousMenu.offsetHeight);
                    if (prePreviousMenu) heights.push(prePreviousMenu.offsetHeight);
                    max = Math.max.apply(this, heights);

                    // update height style
                    if (max > 0) {
                        currentMenu.setAttribute("style","height: "+max+"px");
                        if (nextMenu) {
                            nextMenu.setAttribute("style","height: "+max+"px");
                        }
                        if (previousMenu) {
                            previousMenu.setAttribute("style","height: "+max+"px");
                        }
                        if (prePreviousMenu) {
                            prePreviousMenu.setAttribute("style","height: "+max+"px");
                        }
                    }
                } else {
                    var cBottom = currentMenu.getBoundingClientRect().bottom,
                        cHeight =  currentMenu.offsetHeight,
                        nBottom = nextMenu.getBoundingClientRect().bottom,
                        nHeight = nextMenu.offsetHeight;
                        
                    if (cHeight < nHeight) {   
                        if (currentMenu.hasAttribute("style")) {
                            currentMenu.removeAttribute("style");
                        }
                        currentMenu.setAttribute("style","height: "+nHeight+"px");
                    }
                    if (cBottom < nBottom) {
                        if (nextMenu.hasAttribute("style")) {
                            nextMenu.removeAttribute("style");
                        }
                        nextMenu.setAttribute("style","bottom: -4px");
                    }
                }
            } 
        },
        // Top navigation methods
        openContainer = function(target, button, container) {
            activeContainerId = target;
            button.classList.add(prefix + "active");
            button.setAttribute("aria-expanded","true");
            container.classList.add(prefix + "active");  
            if (container.classList.contains(prefix + "msthd-offcanvas-menu")) {
                toggleFullScreen(container, true);
            } else {
                container.querySelectorAll("a[href], area[href], input:not([disabled]), select:not([disabled]), textarea:not([disabled]), button:not([disabled]), [tabindex='0']")[0].focus();
                container.addEventListener("focusout", handleFocusOutEvent, true);
                container.addEventListener("keydown", handleKeyDownEvent, false);          
            }
            
        },
        closeContainer = function(button, container) {
            activeContainerId = null;
            window.removeEventListener("click", handleWindowClickEvent, false);
            button.classList.remove(prefix + "active");
            button.setAttribute("aria-expanded","false");
            if (container.classList.contains(prefix + "msthd-offcanvas-menu")) {
                toggleFullScreen(container, false);
            }
            else{
                container.removeEventListener("focusout", handleFocusOutEvent, true);
                container.removeEventListener("keydown", handleKeyDownEvent, false);
            }
            container.classList.remove(prefix + "active");
        },
        toggleFullScreen = function(container, show)     {
            if (show) {
                if (container) {
                    container.hidden = false;
                }
                DOC.body.classList.add(prefix + "active");
             
                if(fullScreenOverlay && !(fullScreenOverlay.classList["contains"](prefix + "show"))) {
                    fullScreenOverlay.classList.add(prefix + "show");
                    fullScreenOverlay.hidden = false;
                }
                window.addEventListener("click", handleWindowClickEvent, false);
                window.addEventListener("touchstart", handleWindowClickEvent, false);
            } else {
                if (container) {
                    container.hidden = true;
                }
                DOC.body.classList.remove(prefix + "active");
                if (fullScreenOverlay && fullScreenOverlay.classList["contains"](prefix + "show")) {
                    fullScreenOverlay.classList.remove(prefix + "show");
                    fullScreenOverlay.hidden = true;
                }
                window.removeEventListener("touchstart", handleWindowClickEvent, false);
            }
        };
        
    this.logIn = function(displayname) {
        var signInCtnr = element.querySelector("." + prefix + "msthd-icon-ctnr"+"[data-target='msthd-signin-ctnr']"),
            signInBadge = signInCtnr.querySelector("." + prefix + "badge"),
            label = signInCtnr.querySelector("." + prefix + "msthd-label");

        if (signInBadge.hidden) {
            signInBadge.hidden = false;
        }
        
        if (label) {
            label.innerHTML = displayname;
        }
        signInCtnr.setAttribute("data-target","msthd-signout-ctnr");
    };
    this.logOut = function() {
        var signInCtnr = element.querySelector("." + prefix + "msthd-icon-ctnr"+"[data-target='msthd-signout-ctnr']"),
            signInBadge = signInCtnr.querySelector("." + prefix + "badge"),
            label = signInCtnr.querySelector("." + prefix + "msthd-label");

        if (!signInBadge.hidden) {
            signInBadge.hidden = true;
        }
        
        if (label) {
            label.innerHTML = "Sign In";
        }
        signInCtnr.setAttribute("data-target","msthd-signin-ctnr");
    };
    this.cartCount = function(count) {
        var cartCtnr = element.querySelector("." + prefix + "msthd-icon-ctnr"+"[data-target='msthd-cart-ctnr']"),
            cartBadge = cartCtnr.querySelector("." + prefix + "badge");
        if (count && count > 0) {
            if (cartBadge.hidden) {
                cartBadge.hidden = false;
            }
            cartBadge.innerHTML = count;
        } else {
            cartBadge.hidden = true;
            cartBadge.innerHTML = 0;
        }
    };

    // init
    if (!(stringMasthead in element)) {
        each(element.querySelectorAll("button."+ prefix +"msthd-icon-ctnr", "a."+ prefix +"msthd-icon-ctnr"),function(button) {
            if (button.dataset.toggle && button.dataset.toggle === (prefix + "msthd-offcanvas")) {
                offCanvasMenuBtn = button;
            }
            button.addEventListener("click", handleIconClickEvent, false);
        });
        if (offCanvasMenuBtn) {
            fullScreenOverlay = getFullScreenOverlay();
            if (!DOC.body.classList.contains(prefix + "body-off-canvas")) {
                DOC.body.classList.add(prefix + "body-off-canvas");
            }
            if (isIPhone && isSafari) {
                element.querySelector("." + prefix + "msthd-offcanvas-menu").classList.add(prefix + "safari-fix");
            }
            each(element.querySelectorAll("[data-toggle='dds__collapse']"), function(collapse) {
                new Collapse(collapse);
            });
            mediaSize.addListener(function() {
                if (!mediaSize.matches) {
                    if (activeContainerId) {
                        var container = DOC.getElementById(activeContainerId),
                            button = getClosest(container, "BUTTON");
                        closeContainer(button, container);
                    } else {
                        toggleFullScreen(null, false);
                    }
                }
            });
        }
        if (bottomMenu) {
            each(bottomMenu.querySelectorAll("." + prefix + "msthd-menu-ctnr"), function(menuCtnr) {
                if (!menuCtnr.classList.contains(prefix+"msthd-menu-tier-img")) {
                    menuCtnr.addEventListener(mouseHover[0], handleMenuCntrEvent, false);
                    menuCtnr.addEventListener(mouseHover[1], handleMenuCntrEvent, false);
                }
                menuCtnr.addEventListener("blur", handleMenuCtnrBlurEvent, true);
            });
            each(bottomMenu.querySelectorAll("." + prefix + "msthd-menu-top-item > a"), function(topMenuItem){
                topMenuItem.addEventListener("keydown", handleMenuItemKeyEvent, false);
            });
            each(bottomMenu.querySelectorAll("." + prefix + "msthd-menu-item , ." + prefix + "msthd-menu-item-img"), function(menuItem) {
                
                menuItem.querySelector("a").addEventListener("keydown", handleMenuItemKeyEvent, false);
                menuItem.addEventListener(mouseHover[0], handleMenuItemEvent, false);
                menuItem.addEventListener(mouseHover[1], handleMenuItemEvent, false);
                
            });

            bottomMenu = element.querySelector("." + prefix + "msthd-menu-top");
            bottomMenu.addEventListener(mouseHover[0], handleMenuHoverInEvent, false);
            bottomMenu.addEventListener(mouseHover[1], handleMenuHoverOutEvent, false);

            overlay = DOC.getElementById(prefix + "msthd-overlay");
        }
    }

    element[stringMasthead] = self;
}
